import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Container from './pages/Container';
import { DynamicStylingContextProvider } from './contexts/DynamicStylingContext';
import './App.css';
import { ProvideAuth } from './contexts/AuthContext';
import { Auth } from 'aws-amplify';
import { AWS_CONFIG_AUTH } from './constants';
import { REACT_APP_GRAPHQL_ENDPOINT } from './constants';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import RenderRoutes from './RenderRoutes';
import { ExportCsvContextProvider } from './contexts/ExportCsvContext';

Auth.configure(AWS_CONFIG_AUTH);

const App = () => {
  const client = new ApolloClient({
    uri: REACT_APP_GRAPHQL_ENDPOINT,
    cache: new InMemoryCache(),
  });

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ProvideAuth>
          <DynamicStylingContextProvider>
            <ExportCsvContextProvider>
              <Container>
                <RenderRoutes />
                <ToastContainer />
              </Container>
            </ExportCsvContextProvider>
          </DynamicStylingContextProvider>
        </ProvideAuth>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
