import { FC, useEffect, useState, useMemo } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { capitalizeFirstLetter } from '../../helpers/utils';
import { getRoles } from '../../services/api/roles';

interface IUserRoles {
  onChangeHandler: (selectedList: { label: string; value: string }[]) => void;
  selectedRoles?: { label: string; value: string }[];
  disabled?: boolean;
}

const UserRoles: FC<IUserRoles> = ({ onChangeHandler, selectedRoles, disabled = false }) => {
  const [roleOptions, setRoleOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const res = await getRoles();
        const formattedRoles =
          res?.data?.map((role: any) => ({
            label: capitalizeFirstLetter(role.name),
            value: role.name,
          })) || [];
        formattedRoles.sort((a: { label: string }, b: { label: any }) =>
          a.label.localeCompare(b.label),
        );
        setRoleOptions(formattedRoles);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRoles();
  }, []);

  const handleSelect = (selectedList: any) => {
    onChangeHandler(selectedList);
  };

  const multiselectStyles = {
    multiselectContainer: {
      width: '100%',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
      border: '1px solid var(--neutral-200,#9ca3af)',
    },
    searchBox: {
      border: 'none',
      borderRadius: 0,
    },
    chips: {
      background: '#3182CE',
      color: '#fff',
    },
    optionContainer: {
      height: '120px',
      overflowY: 'scroll',
    },
    option: {
      color: '#000',
    },
    groupHeading: {
      background: '#E2E8F0',
    },
  };

  const selectedValues = useMemo(
    () =>
      selectedRoles?.map((role) => ({
        label: capitalizeFirstLetter(role.label || role.value),
        value: role.value || role.label,
      })) || [],
    [selectedRoles],
  );

  return (
    <div className="w-full mt-5">
      <Multiselect
        options={roleOptions}
        onSelect={handleSelect}
        onRemove={handleSelect}
        displayValue="label"
        disable={disabled}
        placeholder={selectedRoles?.length ? undefined : 'Select role(s)'}
        closeIcon="cancel"
        selectedValues={selectedValues}
        style={multiselectStyles}
      />
    </div>
  );
};

export default UserRoles;
