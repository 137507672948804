import SvgIcon from '../../../../components/elements/SvgIcon';
import { DeleteConfirmationDialogue } from '../../../../components/modals/DeleteConfirmationDialogue';
import useModal from '../../../../hooks/useModal';
import { useUser } from '../../../../services/api/user/useUser';
import EditUsers from '../EditUsers/EditUsers';
import { SettingsTabs } from '../../types';
import { useMemo, useState } from 'react';
import { EditUser } from '../EditUsers/EditUsers.types';
import { RolesSlideOver } from '../../../createUser/components/RolesSlideOver';
import { useQuery } from '@tanstack/react-query';
import fetchRoles from '../../../createUser/components/utils/userRolesAPI';
import { capitalizeFirstLetter } from '../../../../helpers/utils';
import { DBUser } from '../../../../types';

type UserActionsProps = {
  userId: string;
  activeTab: SettingsTabs;
  row: DBUser;
};

const UserActions: React.FC<UserActionsProps> = ({ userId, activeTab, row }) => {
  const [selectedRoles, setSelectedRoles] = useState<number[]>(
    row.roles?.map((role) => role.id as number) || [],
  );

  const { deleteUser } = useUser();
  const [defaultFormValues, setDefaultFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    id: -1,
  });
  const userEditSlideOver = useModal();

  const {
    show: showDeleteModal,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const editRolesSlideOver = useModal();

  const { data: rolesHash } = useQuery({
    queryKey: ['Roles'],
    queryFn: fetchRoles,
    placeholderData: [],
  });

  const selectedRolesOptions = useMemo(
    () =>
      rolesHash
        ? selectedRoles.reduce(
            (acc, id) => {
              const role = rolesHash[id];
              if (role) {
                acc.push({ value: role.name, label: capitalizeFirstLetter(role.name) });
              }
              return acc;
            },
            [] as { value: string; label: string }[],
          )
        : [],
    [selectedRoles, rolesHash],
  );

  const handleDelete = async () => {
    await deleteUser.mutateAsync(+userId);
    closeDeleteModal();
  };

  const handleEditUsers = (row: DBUser) => {
    setDefaultFormValues({
      firstName: row.firstName || '',
      lastName: row.lastName || '',
      id: Number(row.id),
      email: row.email || '',
    });
    userEditSlideOver.openModal();
  };

  return (
    <>
      <div className="flex items-center px-2">
        <button
          data-testid={`edit-user-${userId}`}
          className="rounded-md"
          onClick={() => handleEditUsers(row)}>
          <SvgIcon iconType="edit-light" />
        </button>
        <button
          className="pl-2 rounded-md"
          onClick={() => {
            openDeleteModal();
          }}
          data-testid={`delete-user-${userId}`}>
          <SvgIcon iconType="delete-light-border" />
        </button>
      </div>
      <DeleteConfirmationDialogue
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        handleDelete={handleDelete}
        item={'this User'}
      />
      {editRolesSlideOver.show && (
        <RolesSlideOver
          isOpen={editRolesSlideOver.show}
          onClose={editRolesSlideOver.closeModal}
          handleSave={(selectedRoles) => {
            setSelectedRoles(selectedRoles);
            editRolesSlideOver.closeModal();
          }}
          onCloseSlideOver={editRolesSlideOver.closeModal}
          selectedRoles={selectedRoles}
          allRoles={Object.values(rolesHash ?? {})}
          backButtonOnClick={editRolesSlideOver.closeModal}
          heading="Edit Roles"
          isBackButton
        />
      )}
      {userEditSlideOver.show && (
        <EditUsers
          isOpen={userEditSlideOver.show}
          onClose={userEditSlideOver.closeModal}
          activeTab={activeTab}
          defaultValues={defaultFormValues as EditUser}
          userRoles={selectedRolesOptions}
          rolesOnClickHandler={editRolesSlideOver.openModal}
        />
      )}
    </>
  );
};

export default UserActions;
