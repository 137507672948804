import { MappingTypeEnum } from '../types';

export const mappingByType = {
  ai: 'ai',
  author: 'author',
  verifier: 'verifier',
  automapping: 'automapping',
};

export const suggestionStatus = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
};

export const bgColorMap = {
  ai: { normal: 'bg-blue-100 border-blue-200', selected: 'border-blue-500' },
  author: {
    normal: 'bg-pink-100 border-pink-200',
    selected: 'border-pink-500',
  },
  verifier: {
    normal: 'bg-green-100 border-green-200',
    selected: 'border-green-500',
  },
};

export const sessionMapperType: Record<MappingTypeEnum, string> = {
  [MappingTypeEnum.AUTO_MAPPING]: mappingByType.automapping,
  [MappingTypeEnum.AI_AUTHORING]: mappingByType.ai,
  [MappingTypeEnum.AUTHORING]: mappingByType.author,
};

export const SearchThreshold = 0.3;
