import { formatCellData } from './../pages/catalog/components/CatalogTable/utils/helper';
import { CellContext } from '@tanstack/react-table';
import { ColumnType } from '../pages/upload';
import { convertNumbToDecimal } from './utils';

export const tableValueFormatter = (info: CellContext<ColumnType, any>) => {
  switch (info.column.id) {
    case 'version':
      return convertNumbToDecimal(info.getValue(), 1);
    case 'group':
      return formatCellData(info.getValue());
    default:
      return info.getValue();
  }
};
