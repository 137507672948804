import { getCatalogItems, getSubgroupsCatalogItems } from '../../../../services/api';
import { ItemType } from '../../../../services/api/types';

export const getAllCatalogItems = async (type: keyof typeof ItemType) => {
  try {
    const { data } = await getCatalogItems({ filters: { type: type } });
    return data.data;
  } catch (error) {
    console.error('Error fetching groups:', error);
    return [];
  }
};

export const getSubgroupsByGroupIds = async (ids: number[]) => {
  if (ids.length === 0) {
    return [];
  }
  try {
    const { data } = await getSubgroupsCatalogItems(ids);
    return data?.groupedSubgroups || [];
  } catch (error) {
    console.error('Error fetching subgroups:', error);
    return [];
  }
};
