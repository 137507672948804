import { FC, ReactNode, createContext, useContext, useMemo } from 'react';
import { getClientName } from '../helpers/clientHelpers';
import { useQuery } from '@apollo/client';
import { GET_CLIENT_BY_NAME } from '../GraphQL/graphQueries/clientQueries';
import Loading from '../components/Loading';
import { IGetClientByNameResponse } from '../GraphQL/responseTypes';
import StyledWrapper from '../components/StyledWrapper';
import { IClientStyling } from '../types';
import { DEFAULT_COLORS } from '../constants';
import _ from 'lodash';

export interface DynamicStylingContext {
  clientStyling: IClientStyling;
  clientName: string;
}

const FALLBACK_STYLING: IClientStyling = {
  colors: DEFAULT_COLORS,
  thumbnail: '',
  logo: 'https://customer-specific-assets.s3.us-west-2.amazonaws.com/regWriterLarge.png',
  landingTitle: 'RegWriter',
};

export const DynamicStylingContext = createContext<DynamicStylingContext | undefined>(undefined);

export const DynamicStylingContextProvider: FC = ({ children }: { children?: ReactNode }) => {
  const clientName = useMemo(() => getClientName(), [window.location.hostname]);

  const { data, loading, error } = useQuery<IGetClientByNameResponse>(GET_CLIENT_BY_NAME, {
    variables: { name: clientName },
  });

  if (loading && !error) return <Loading type="spin" color="#000" height={100} width={100} />;

  // Fallback styling, just in case the client doesn't have any styling set up
  const clientStyling = data?.getClientByName
    ? {
        thumbnail: data.getClientByName.thumbnail || FALLBACK_STYLING.thumbnail,
        logo: data.getClientByName.logo || FALLBACK_STYLING.logo,
        landingTitle: data.getClientByName.landingTitle || FALLBACK_STYLING.landingTitle,
        colors: _.merge(FALLBACK_STYLING.colors, data.getClientByName.styling.colors),
      }
    : FALLBACK_STYLING;

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    (link as HTMLLinkElement).rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  (link as HTMLLinkElement).href = clientStyling?.thumbnail;
  document.title = clientStyling?.landingTitle;

  return (
    <DynamicStylingContext.Provider value={{ clientStyling, clientName }}>
      <StyledWrapper id="styled-wrapper" clientStyling={clientStyling}>
        {children}
      </StyledWrapper>
    </DynamicStylingContext.Provider>
  );
};

export const useDynamicStylingContext = (): DynamicStylingContext => {
  const context = useContext(DynamicStylingContext);

  if (context === undefined) {
    throw new Error('useDynamicStylingContext must be used within a DynamicStylingContextProvider');
  }

  return context;
};
