import { FC, useState } from 'react';
import {
  Button,
  CheckboxInput,
  SlideOver,
} from '@gloabal-regulatory-writing-consulting/gxt-components';
import { CatalogItemSlideOverMenuProps } from '../pages/CatalogTemplateForm/types';
import { SourceFileGroup } from '../pages/upload';
const CatalogItemSlideOver: FC<CatalogItemSlideOverMenuProps> = ({
  isOpen,
  onClose,
  heading,
  catalogItems,
  selectedItems,
  handleAdd,
  handleResetSelection,
}) => {
  const [tempSelectedItems, setTempSelectedItems] = useState<SourceFileGroup[]>(
    selectedItems || [],
  );

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    id: number,
  ) => {
    if (event.target.checked) {
      setTempSelectedItems((prev) => [...prev, { name, id }]);
    } else {
      setTempSelectedItems((prev) => prev.filter((item) => item.id !== id));
    }
  };

  const handleAddClick = () => {
    handleAdd(tempSelectedItems);
    onClose();
  };

  const handleClearSelection = () => {
    setTempSelectedItems([]);
    handleResetSelection();
  };

  const handleBackClick = () => {
    setTempSelectedItems([]);
    onClose();
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        {heading}
      </SlideOver.Header>
      <div>
        {catalogItems.map((item: SourceFileGroup) => (
          <div key={item.name} className="flex mb-4 gap-2">
            <CheckboxInput
              type="checkbox"
              id={`section-${item.name}`}
              value={item.name}
              $inputSize="xs"
              checked={tempSelectedItems.some((itemObj) => itemObj.id === item.id)}
              onChange={(e) => handleCheckboxChange(e, item.name, item.id)}
            />
            <p className="text-[#414141]">{item.name}</p>
          </div>
        ))}
      </div>
      <SlideOver.Footer className="flex items-center self-stretch">
        <div className="w-full">
          <Button variant="secondary" onClick={handleClearSelection}>
            Clear Selection
          </Button>
        </div>
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" onClick={handleAddClick}>
            Add
          </Button>
          <Button variant="secondary" onClick={handleBackClick}>
            Back
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default CatalogItemSlideOver;
