export const LOADER_COLOR = '#0081C9';
export const PRIMARY_BG_COLOR = 'blue';
export const SECONDARY_BG_COLOR = 'gray';
export const LIGHT_RED_COLOR = '#ffcccb';
export const LIGHT_GREEN_COLOR = '#ccffcc';
export const LIGHT_BLUE_COLOR = '#4ea1d3';
export const THEME_COLOR = '#1C99CE';
export const LIGHT_GRAY_COLOR = '#D3D3D3';
export const BLUE_PIN_SHADE = ['#0074D9', '#3498DB', '#5DADE2', '#85C1E9'];
export const RED_PIN_SHADE = ['#C0392B', '#BDC3C7', '#7F8C8D', '#E74C3C'];
export const DOCUMENT_STYLES = `
body {
  font-family: "Times New Roman", Times, serif;
  font-size: 12pt;
  line-height: 1.4286;
  text-align: left;
  min-width: fit-content;
  padding: 0;
  line-height: 14pt;
  margin-top: 6pt;
  margin-bottom: 6pt;
  widows: 2;
  orphans: 2;
}

p.MsoTitle {
  font-size: 14pt;
  font-weight: bold;
  text-align: center;
}

h1 {
  font-size: 14pt !important;
  font-weight: bold;
  text-transform: uppercase;
  page-break-after: avoid;
}

h2 {
  font-size: 14pt !important;
  font-weight: bold;
  text-transform: capitalize;
  page-break-after: avoid;
}

h3, h4, h5, h6 {
  font-size: 12pt;
  font-weight: bold;
  text-transform: capitalize;
  page-break-after: avoid;
}

table td, table th {
  border: 1px solid black;
}

.font-weight-bold {
    font-weight: bold;
}

table, th, td {
  border: 1px solid black;
}

table {
  font-family: "Times New Roman", Times, serif;
  font-size: 11pt;
  text-align: left;
  margin: 0 auto;
  border-collapse: collapse;
  border: 0.075em solid black;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  page-break-inside: avoid;
}

th {
  font-size: 11pt !important;
  font-weight: bold !important;
}

td, th {
  font-size: 11pt !important;
  padding: 3pt !important;
  vertical-align: top;
  border-top: 0.075em solid black;
}

table {
  font-size: 8pt;
}

th {
  font-weight: bold;
}

tr:not(:last-child) {
  border-bottom: 0.075em solid black;
}


thead th {
  vertical-align: bottom;
  border-bottom: 2px solid black;
}

tbody + tbody {
  border-top: 2px solid black;
}

.table-bordered {
  border: 1px solid black;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid black;
}

dt {
    font-weight: bold;
}

img {
  max-width: 100%;
}

img:hover {
  cursor: crosshair
}

p.MsoToc1,
li.MsoToc1,
div.MsoToc1 {
  mso-style-update: auto;
  mso-style-priority: 39;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 9.0pt;
  margin-left: 57.6pt;
  text-indent: -57.6pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc2,
li.MsoToc2,
div.MsoToc2 {
  mso-style-update: auto;
  mso-style-priority: 39;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 6.0pt;
  margin-left: 70.2pt;
  text-indent: -70.2pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc3,
li.MsoToc3,
div.MsoToc3 {
  mso-style-update: auto;
  mso-style-priority: 39;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 3.0pt;
  margin-left: 82.1pt;
  text-indent: -82.1pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc4,
li.MsoToc4,
div.MsoToc4 {
  mso-style-update: auto;
  mso-style-priority: 39;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 3.0pt;
  margin-left: 93.25pt;
  text-indent: -93.25pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc5,
li.MsoToc5,
div.MsoToc5 {
  mso-style-update: auto;
  mso-style-noshow: yes;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 3.0pt;
  margin-left: 103.7pt;
  text-indent: -103.7pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc6,
li.MsoToc6,
div.MsoToc6 {
  mso-style-update: auto;
  mso-style-noshow: yes;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 3.0pt;
  margin-left: 4.0cm;
  text-indent: -4.0cm;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc7,
li.MsoToc7,
div.MsoToc7 {
  mso-style-update: auto;
  mso-style-noshow: yes;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 3.0pt;
  margin-left: 122.4pt;
  mso-add-space: auto;
  text-indent: -122.4pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc7CxSpFirst,
li.MsoToc7CxSpFirst,
div.MsoToc7CxSpFirst {
  mso-style-update: auto;
  mso-style-noshow: yes;
  mso-style-unhide: no;
  mso-style-next: Normal;
  mso-style-type: export-only;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 122.4pt;
  mso-add-space: auto;
  text-indent: -122.4pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc7CxSpMiddle,
li.MsoToc7CxSpMiddle,
div.MsoToc7CxSpMiddle {
  mso-style-update: auto;
  mso-style-noshow: yes;
  mso-style-unhide: no;
  mso-style-next: Normal;
  mso-style-type: export-only;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 122.4pt;
  mso-add-space: auto;
  text-indent: -122.4pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc7CxSpLast,
li.MsoToc7CxSpLast,
div.MsoToc7CxSpLast {
  mso-style-update: auto;
  mso-style-noshow: yes;
  mso-style-unhide: no;
  mso-style-next: Normal;
  mso-style-type: export-only;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 3.0pt;
  margin-left: 122.4pt;
  mso-add-space: auto;
  text-indent: -122.4pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc8,
li.MsoToc8,
div.MsoToc8 {
  mso-style-update: auto;
  mso-style-noshow: yes;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 3.0pt;
  margin-left: 130.7pt;
  text-indent: -130.7pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoToc9,
li.MsoToc9,
div.MsoToc9 {
  mso-style-update: auto;
  mso-style-noshow: yes;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 3.0pt;
  margin-left: 138.25pt;
  text-indent: -138.25pt;
  mso-pagination: widow-orphan;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoTof,
li.MsoTof,
div.MsoTof {
  mso-style-priority: 99;
  mso-style-unhide: no;
  mso-style-next: Normal;
  margin-top: 6.0pt;
  margin-right: .55in;
  margin-bottom: 0in;
  margin-left: .8in;
  text-indent: -.8in;
  line-height: 14.0pt;
  mso-pagination: widow-orphan;
  tab-stops: .8in right dotted 6.5in;
  font-size: 12.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
}

p.MsoCaption,
li.MsoCaption,
div.MsoCaption {
  mso-style-name: "Caption,Caption 1,Caption-Table,Caption Table,IB Caption,Medical Caption,MP-Caption";
  mso-style-unhide: no;
  mso-style-qformat: yes;
  mso-style-link: "Caption Char,Caption 1 Char,Caption-Table Char,Caption Table Char,IB Caption Char,Medical Caption Char,MP-Caption Char";
  mso-style-next: Normal;
  margin-top: 12.0pt;
  margin-right: 0cm;
  margin-bottom: 6.0pt;
  margin-left: 108.0pt;
  text-indent: -108.0pt;
  mso-pagination: widow-orphan;
  page-break-after: avoid;
  tab-stops: 36.0pt 72.0pt;
  font-size: 12.0pt;
  mso-bidi-font-size: 10.0pt;
  font-family: "Times New Roman", serif;
  mso-fareast-font-family: "Times New Roman";
  mso-font-kerning: 12.0pt;
  mso-ansi-language: EN-US;
  mso-fareast-language: JA;
  font-weight: bold;
}

`;
export const DOCUMENT_MARGINS = `
body {
  padding: 2.5cm 2cm 2cm 2cm;
}
`;

export const MSO_DEFINITIONS = `
 <!--
@list l15 {
  mso-list-id: 468471900;
  mso-list-template-ids: 1092126454;
}

@list l15:level1 {
  mso-level-style-link: "Heading 1";
  mso-level-text: "%1.0";
  mso-level-tab-stop: 36.0pt;
  mso-level-number-position: left;
  text-indent: -36.0pt;
  font-variant: normal !important;
  mso-text-animation: none;
  mso-hide: none;
  text-transform: none;
  position: relative;
  top: 0pt;
  mso-text-raise: 0pt;
  letter-spacing: 0pt;
  mso-font-kerning: 0pt;
  mso-ligatures: none;
  mso-number-form: default;
  mso-number-spacing: default;
  mso-stylistic-set: 0;
  mso-contextual-alternates: no;
  text-effect: none;
  text-shadow: none;
  text-effect: none;
  text-effect: none;
  mso-style-textoutline-type: none;
  mso-style-textoutline-outlinestyle-dpiwidth: 0pt;
  mso-style-textoutline-outlinestyle-linecap: round;
  mso-style-textoutline-outlinestyle-join: bevel;
  mso-style-textoutline-outlinestyle-pctmiterlimit: 0%;
  mso-style-textoutline-outlinestyle-dash: solid;
  mso-style-textoutline-outlinestyle-align: center;
  mso-style-textoutline-outlinestyle-compound: simple;
  mso-style-style3d-lightrigtype: 13;
  mso-style-style3d-lightrigdirection: 2;
  mso-style-style3d-lightrigrotation-anglatitude: 0;
  mso-style-style3d-lightrigrotation-anglongitude: 0;
  mso-style-style3d-lightrigrotation-angrevolution: 0;
  mso-effects-glow-color: black;
  mso-effects-glow-alpha: 100.0%;
  mso-effects-glow-rad: 0pt;
  mso-effects-shadow-color: black;
  mso-effects-shadow-alpha: 100.0%;
  mso-effects-shadow-dpiradius: 0pt;
  mso-effects-shadow-dpidistance: 0pt;
  mso-effects-shadow-angledirection: 0;
  mso-effects-shadow-align: none;
  mso-effects-shadow-pctsx: 0%;
  mso-effects-shadow-pctsy: 0%;
  mso-effects-shadow-anglekx: 0;
  mso-effects-shadow-angleky: 0;
  mso-effects-reflection-dpiradius: 0pt;
  mso-effects-reflection-dpidistance: 0pt;
  mso-effects-reflection-angdirection: 0;
  mso-effects-reflection-pctsx: 0%;
  mso-effects-reflection-pctsy: 0%;
  mso-effects-reflection-anglekx: 0;
  mso-effects-reflection-angleky: 0;
  mso-effects-reflection-pctalphastart: 0%;
  mso-effects-reflection-pctstartpos: 0%;
  mso-effects-reflection-pctalphaend: 0%;
  mso-effects-reflection-pctendpos: 0%;
  mso-effects-reflection-angfadedirection: 0;
  mso-effects-reflection-align: none;
  mso-bevel-captop-bevelstyle: 0;
  mso-bevel-captop-dpiwidth: 0pt;
  mso-bevel-captop-dpiheight: 0pt;
  mso-bevel-capbot-bevelstyle: 0;
  mso-bevel-capbot-dpiwidth: 0pt;
  mso-bevel-capbot-dpiheight: 0pt;
  mso-bevel-material: 0;
  mso-bevel-dpiextrusion: 0pt;
  mso-bevel-dpicontour: 0pt;
  font-emphasize: none;
  mso-ansi-font-weight: bold;
  mso-bidi-font-weight: normal;
  mso-ansi-font-style: normal;
  mso-bidi-font-style: normal;
  mso-no-proof: no;
  text-decoration: none;
  text-underline: none;
  text-decoration: none;
  text-line-through: none;
  vertical-align: baseline;
}

@list l15:level2 {
  mso-level-style-link: "Heading 2";
  mso-level-text: "%1.%2";
  mso-level-tab-stop: 36.0pt;
  mso-level-number-position: left;
  margin-left: 36.0pt;
  text-indent: -36.0pt;
  mso-ansi-font-size: 12.0pt;
  font-family: "Times New Roman Bold", serif;
  color: windowtext;
  mso-ansi-font-weight: bold;
  mso-ansi-font-style: normal;
}

@list l15:level3 {
  mso-level-style-link: "Heading 3";
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: 54.0pt;
  mso-level-number-position: left;
  margin-left: 54.0pt;
  text-indent: -54.0pt;
  mso-ansi-font-size: 12.0pt;
  font-family: "Times New Roman Bold", serif;
  color: windowtext;
  mso-ansi-font-weight: bold;
  mso-ansi-font-style: normal;
}

@list l15:level4 {
  mso-level-style-link: "Heading 4";
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: 72.0pt;
  mso-level-number-position: left;
  margin-left: 72.0pt;
  text-indent: -72.0pt;
  mso-ansi-font-size: 12.0pt;
  font-family: "Times New Roman Bold", serif;
  color: windowtext;
  mso-ansi-font-weight: bold;
  mso-ansi-font-style: normal;
}

@list l15:level5 {
  mso-level-style-link: "Heading 5";
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: 90.0pt;
  mso-level-number-position: left;
  margin-left: 90.0pt;
  text-indent: -90.0pt;
  mso-ansi-font-size: 12.0pt;
  font-family: "Times New Roman Bold", serif;
  color: windowtext;
  mso-ansi-font-weight: bold;
  mso-ansi-font-style: normal;
}

@list l15:level6 {
  mso-level-style-link: "Heading 6";
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: 108.0pt;
  mso-level-number-position: left;
  margin-left: 108.0pt;
  text-indent: -108.0pt;
  mso-ansi-font-size: 12.0pt;
  font-family: "Times New Roman Bold", serif;
  color: windowtext;
  mso-ansi-font-weight: bold;
  mso-ansi-font-style: normal;
}

@list l15:level7 {
  mso-level-style-link: "Heading 7";
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: 126.0pt;
  mso-level-number-position: left;
  margin-left: 126.0pt;
  text-indent: -126.0pt;
  mso-ansi-font-size: 12.0pt;
  font-family: "Times New Roman Bold", serif;
  color: windowtext;
  mso-ansi-font-weight: bold;
  mso-ansi-font-style: normal;
}

@list l15:level8 {
  mso-level-style-link: "Heading 8";
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: 144.0pt;
  mso-level-number-position: left;
  margin-left: 144.0pt;
  text-indent: -144.0pt;
  mso-ansi-font-size: 12.0pt;
  font-family: "Times New Roman Bold", serif;
  color: windowtext;
  mso-ansi-font-weight: bold;
  mso-ansi-font-style: normal;
}

@list l15:level9 {
  mso-level-style-link: "Heading 9";
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: 162.0pt;
  mso-level-number-position: left;
  margin-left: 162.0pt;
  text-indent: -162.0pt;
  mso-ansi-font-size: 12.0pt;
  font-family: "Times New Roman Bold", serif;
  color: windowtext;
  mso-ansi-font-weight: bold;
  mso-ansi-font-style: normal;
}
--->
`;

export const userModalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    minHeight: '45rem',
  },
  overlay: {
    background: 'rgba(71,84,93,0.8)',
    zIndex: 11,
  },
};

export const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    minWidth: '25%',
    height: 'fit-content',
    maxHeight: '50rem',
  },
  overlay: {
    background: 'rgba(71,84,93,0.8)',
    zIndex: 999,
  },
};

export const getBackgroundColorClass = (value: boolean) => {
  return value
    ? 'bg-primary_bg_color hover:bg-secondary_bg_color'
    : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed';
};
export const buttonStyle = (isButtonDisabled: boolean) =>
  isButtonDisabled ? 'pointer-events-none bg-[#c9c9c9]' : 'cursor-pointer bg-[#438FFE]';

export const QuickFillModalCustomStyles = {
  content: {
    top: '50%',
    left: '5%',
    transform: 'translateY(-50%)',
    width: '46%',
    height: '70%',
    padding: '0px',
  },
  overlay: {},
};

export const AUTHORING_COLORS = {
  SELECTED: '#4ea1d3',
  AI_SUGGESTION: '#eee0ff',
  AI_ACCEPTED: '#d3b0ff',
  MANUAL_ACCEPTED: '#ffffe0',
  NARRATIVE_SELECTION: '#ffc36a',
} as const;

export const TEMPLATE_TRAINING_COLORS = {
  APPROVED: '#d9ffc7',
} as const;

export const VERIFICATION_COLORS = {
  SELECTED: '#4ea1d3',
  ACCEPTED: '#d9ffc7',
  REJECTED: '#ffc7c7',
} as const;
