import { AxiosResponse } from 'axios';
import axios from '../../../helpers/AxiosConfig';
import {
  GetMappingSessionResponse,
  GetMappingSessionParams,
  GetMappingSessionsFilterOptionsResponse,
  UpdateMappingSessionStatusParams,
  UpdateMappingSessionStatusResponse,
  AddSourceFileResponse,
  AddSourceFileParams,
  RemoveSourceFilesFromSessionParams,
  RemoveSourceFilesFromSessionResponse,
  AutowriteParams,
  AutowriteResponse,
  ManualAuthorParams,
  ManualAuthorResponse,
  UpdateMappingSessionParams,
  UpdateMappingSessionResponse,
  GetMappingSessionSourceFilesParams,
  DynamicMappingParams,
  DynamicMappingResponse,
  AutoAcceptSuggestionParam,
  ArchiveMappingSessionResponse,
} from './mappingSession.types';
import { AutoAcceptSuggestionResponse, GetCatalogsResponse } from '../types';

export const getMappingSessions = async <T>(
  params: GetMappingSessionParams,
): Promise<AxiosResponse<GetMappingSessionResponse<T>>> => {
  return axios.get<GetMappingSessionResponse<T>>('/mapping-sessions', { params });
};

export const getMappingSessionsFilterOptions = async () => {
  return await axios.get<GetMappingSessionsFilterOptionsResponse>(
    'mapping-sessions/filter-options',
  );
};

export const updateSessionStatus = async (
  data: UpdateMappingSessionStatusParams,
): Promise<AxiosResponse<UpdateMappingSessionStatusResponse>> => {
  const { status, id } = data;
  return axios.put<UpdateMappingSessionStatusResponse>(`mapping-sessions/${id}/update-status`, {
    status,
  });
};

export const addSourceFileInSession = async (
  data: AddSourceFileParams,
): Promise<AxiosResponse<AddSourceFileResponse>> => {
  return axios.post('/mappingSession/addSourceFiles', data);
};

export const removeSourceFilesFromSession = async (data: RemoveSourceFilesFromSessionParams) =>
  axios.post<RemoveSourceFilesFromSessionResponse>(
    `mappingSession/${data.sessionId}/removeSourceFiles`,
    { catalogIds: data.catalogIds },
  );

export const autowrite = async (
  data: AutowriteParams,
): Promise<AxiosResponse<AutowriteResponse>> => {
  return axios.post('autowrite/mappingSession', data);
};

export const manualAuthor = async (
  data: ManualAuthorParams,
): Promise<AxiosResponse<ManualAuthorResponse>> => {
  return axios.post('autowrite/manual-mappingSession', data);
};

export const editMappingSession = async (params: UpdateMappingSessionParams) => {
  return await axios.put<UpdateMappingSessionResponse>('/mappingSession/edit', params);
};

export const getMappingSessionSourceFiles = async (
  params: GetMappingSessionSourceFilesParams,
  mappingSessionId: number,
) => {
  return await axios.get<GetCatalogsResponse>(`mapping-sessions/${mappingSessionId}/catalogs`, {
    params,
  });
};

export const requestDynamicMapping = async (
  data: DynamicMappingParams,
): Promise<AxiosResponse<DynamicMappingResponse>> => {
  return axios.post('autowrite/dynamic-mapping', data);
};

export const autoAcceptSuggestion = async (
  data: AutoAcceptSuggestionParam,
): Promise<AxiosResponse<AutoAcceptSuggestionResponse>> => {
  return axios.post(`/mappingSession/${data.id}/autoAcceptSuggestions`);
};

export const archiveMappingSession = async (
  id: number,
): Promise<AxiosResponse<ArchiveMappingSessionResponse>> => {
  return axios.delete(`/mappingSession/delete/${id}`);
};
