import { IMapComment } from '../types';
import Comment from './Comment';

interface ICommentSideBar {
  hidden: boolean;
  comments: IMapComment[];
  toggleDisplay: () => void;
  onCommentClick?: any;
}

const CommentSideBar = ({ hidden, toggleDisplay, comments, onCommentClick }: ICommentSideBar) => {
  return (
    <div
      className={`absolute right-0 top-0 h-screen w-96 bg-white p-2 z-50 shadow-lg overflow-auto ${
        hidden ? 'hidden' : ''
      }`}
      data-testid={'comment-sidebar'}>
      <div className="relative">
        <button className="fixed top-0 right-0 mt-2 mr-2 text-gray-600" onClick={toggleDisplay}>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <h2 className="m-2 font-bold text-lg">Comments</h2>
      <div className="mt-10">
        {comments.map((comment, i) => (
          <Comment
            name={`${comment.firstName} ${comment.lastName}`}
            message={comment.comment}
            onClick={() => onCommentClick(comment.targetNodeId)}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

export default CommentSideBar;
