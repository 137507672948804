import { ITargetDocument, SourceDocumentType } from '../../types';

interface ICatalogSelectField {
  handleChange: (doc: ITargetDocument, type?: SourceDocumentType) => void;
  record: any;
  checked: boolean;
  type: 'radio' | 'checkbox';
}

export const CatalogSelectField = ({
  handleChange,
  record,
  checked,
  type,
}: ICatalogSelectField) => {
  return (
    <div className="px-1">
      <input
        {...{
          type,
          checked,
          disabled: !record.getCanSelect(),
          onChange: () =>
            handleChange(
              record.original as ITargetDocument,
              record.original.documentType as SourceDocumentType,
            ),
        }}
      />
    </div>
  );
};
