import axios, { setInterceptors } from '../helpers/AxiosConfig';

setInterceptors();
export const loginUser = async (data) => {
  try {
    const response = await axios.post('auth/signin', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAllUserFromDB = async (data) => {
  try {
    const response = await axios.get('users', { params: data });
    return response;
  } catch (error) {
    return error;
  }
};

export const removeUserFromDB = async (id) => {
  try {
    const response = await axios({ url: `users/${id}`, method: 'DELETE' });
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadUserAvatar = async (file, fileName) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    const response = await axios.post('/users/uploadAvatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.error('Error during image upload:', error);
    throw error;
  }
};

export const uploadFiles = (files, authToken) => {
  const formData = new FormData();
  for (const key of Object.keys(files)) {
    formData.append('docxFiles', files[key]);
  }
  return axios({
    url: 'convertFile/docx-to-html',
    headers: { Authorization: `Bearer ${authToken}` },
    data: formData,
    method: 'POST',
  });
};

export const uploadFilesCatalog = (sourceFiles, authToken, folderName, templateFile) => {
  const data = {
    sourceFiles: sourceFiles,
    templateFile: templateFile,
    folderName: folderName,
  };

  return axios({
    url: 't1Catalog/catalogUploads',
    headers: {
      folderName: folderName,
    },
    data: data,
    method: 'POST',
  });
};

export const uploadHtml = (data) => {
  return axios.post('convertFile/html-to-json', data);
};

export const confirmEmail = async (token) => {
  return axios.post('auth/confirm-email', { token });
};

export const sendConfirmationEmail = async (email) => {
  try {
    const response = await axios.post('auth/send-confirm-email', { email });
    return response;
  } catch (error) {
    return error;
  }
};

export const sendResetEmail = async (email) => {
  try {
    const response = await axios.post('auth/send-reset-email', { email });
    return response;
  } catch (error) {
    return error;
  }
};

// DEPRECATED
export const getSourceFiles = async (id) => {
  try {
    const response = await axios.get(`mapping/getTargetFiles/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (password, token) => {
  try {
    const response = await axios.post('auth/reset-password', {
      password,
      token,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAutowrittenDocuments = async (mode = 'autowritten', query = {}) => {
  return axios.get('document/t1Sessions', { params: { mode: mode, ...query } });
};

export const renameFile = async (data) => {
  return axios.put('document/renameFile', data);
};

export const updateMappingStatus = async (mappingId, status, multiple = false) => {
  try {
    const response = await axios({
      method: 'put',
      url: 'mapping/updateMappingStatus',
      data: {
        id: mappingId,
        status: status,
        multiple: multiple,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getRejectionNotes = async (id) => {
  return await axios.get(`mapping/rejectionNotes/${id}`);
};

export const addCatalog = (formData) => {
  return axios({
    method: 'POST',
    url: 'catalog/addToCatalog',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const getCatalogs = (data) => {
  return axios({
    method: 'GET',
    url: 'catalog/getCatalogs',
    params: data,
  });
};

export const getDistinctValues = (documentType) => {
  return axios({
    method: 'GET',
    url: 'catalog/getDistinctValues',
    params: { documentType },
  });
};

export const verifyToken = () => {
  return axios({ method: 'POST', url: 'auth/verifyToken' });
};

export const getUserRoles = (id) => {
  return axios({ method: 'GET', url: `role/getUserRoles/${id}` });
};

export const getPermissions = () => {
  return axios({ method: 'GET', url: `permission/getPermissions` });
};

export const getUserPermissions = async () => {
  return axios({
    method: 'GET',
    url: `permission/getUserPermissions`,
  });
};

export const getRolePermissions = (name) => {
  return axios({ method: 'GET', url: `permission/getRolePermissions/${name}` });
};

export const addRolePermissions = (data) => {
  return axios({ method: 'POST', url: `/permission/addRolePermission`, data });
};

export const editUserData = async (formData) => {
  return axios({
    method: 'PUT',
    url: 'auth/editUser',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const fetchSourceFile = async (key) => {
  return axios.get(`document/fetchT1/${key}`);
};

// DEPRECATED
export const getSingleSourceFile = async (mappingId) => {
  return axios.get(`mapping/getSingleTargetFile/${mappingId}`);
};

export const getSessionMappings = async (id) => {
  return axios.get(`mapping/getSessionMappings/${id}`);
};

export const fetchSourceFiles = async (ids, getOriginal) => {
  return axios.get(`/catalog/getSourceFiles`, {
    params: { ids: JSON.stringify(ids), getOriginal: getOriginal },
  });
};

export const addSourceFileInSession = async (sourceFileIds, sessionId) => {
  return axios({
    method: 'POST',
    url: `/mappingSession/addSourceFiles`,
    data: { sourceFileIds, sessionId },
  });
};

export const autowrite = async (data) => {
  return axios.post('autowrite', data);
};

export const getTargetFile = async (mappingId) => {
  return axios({ method: 'GET', url: `mapping/getTemplateFile/${mappingId}` });
};

export const addComment = async (data) => {
  return axios.post('comments/', data);
};

export const fetchComments = async (id, params = {}) => {
  return axios.get(`comments/${id}`, { params });
};

export const fetchMappingSessionComments = async (id, params = {}) => {
  return axios.get(`/comments/mappingSession/${id}`, { params });
};

export const autowriteMappingSession = async (data) => {
  return axios.post('autowrite/mappingSession', data);
};

export const getCatalogFileData = async (data) => {
  return axios.post('/catalog/getCatalogFileData', data);
};

export const getMappingSessions = async (data) =>
  axios.get('mappingSession/getMappingSessions', { params: data });

export const getMappingSession = (id) => {
  return axios(`/mappingSession/${id}`);
};

export const fetchMappingSessionSuggestionsAuditTrail = async (id, params = {}) => {
  return axios.get(`/mappingSession/${id}/suggestionsAuditTrail`, { params });
};

export const saveSuggestion = async (data) => {
  return axios.post('/mappingSession/saveSuggestion', data);
};

export const saveSuggestionsWithNodes = async (data) => {
  return axios.post('/mappingSession/saveSuggestionsWithNodes', data);
};

export const undoSuggestion = async (id) => {
  return axios.post(`/suggestions/${id}/undo`);
};

export const saveSuggestionStatus = async (data) => {
  return axios.put('/mappingSession/updateSuggestionStatus', data);
};

export const autoAcceptSuggestions = async (id) => {
  return axios.post(`/mappingSession/${id}/autoAcceptSuggestions`);
};

export const getSuggestionRejectionNotes = async (id) => {
  return await axios.get(`mappingSession/rejectionNotes/${id}`);
};

export const editMappingSession = async (data) => {
  return axios.put(`/mappingSession/edit`, data);
};

export const archiveTargetFile = async (id) => {
  return axios.delete(`/mappingSession/delete/${id}`);
};

export const archiveMappingSession = async (id) => {
  return axios.delete(`/mappingSession/delete/${id}`);
};

export const getMappingsMetadata = async (id) => {
  return axios.get(`/mapping/getMappingMetadata/${id}`);
};

export const editMappingFiles = async (data) => {
  return axios.put('/mapping/editMappingFiles', data);
};

export const editAutomatedTableData = async (data) => {
  return axios.put('/t1Catalog/editFilesRecord', data);
};

export const autoMapping = async (data) => {
  return axios.post('/mapping/autoMapping', data);
};

export const autoMap = async (data) => {
  return axios.post('/mapping/autoMap', data);
};

export const manualMappingSession = async (data) => {
  return axios.post('autowrite/manual-mappingSession', data);
};

export const getAutoMapping = async () => {
  return axios.get('/mapping/getAutoMapping/');
};

export const getAutoMappedDocs = async () => {
  return axios.get('/mapping/getAutoMappedDocs/');
};

export const getSessionSuggestions = async (id) => {
  return axios.get(`/mapping/getSessionSuggestions/${id}`);
};

export const acceptAutoMappingSuggestion = async (data) => {
  return axios.put('/mapping/acceptAutoMappingSuggestion', data);
};

export const getSingleCatalog = async (id) => {
  return axios.get(`/catalog/getSingleCatalog/${id}`);
};

export const search = (data) => {
  return axios({
    method: 'POST',
    url: 'catalogNodes/search',
    data: data,
  });
};

export const getCatalogJson = async (data) => {
  return axios.post('/catalogNodes/getCatalogJson', data);
};

export const finishAutoMapping = async (data, mappingSessionId) => {
  return axios.post(`/mapping/finishAutoMapping/${mappingSessionId}`, data, {
    params: { sessionId: mappingSessionId },
  });
};

export const fetchAutomattedDocument = async (data) => {
  return await axios.get('/mappingSession/getMappingSessions', { params: data });
};

export const deleteAutomattedDocument = async (id) => {
  return await axios.delete(`/mapping/deleteAutomapping/${id}`);
};

export const updateNewCognitoUsersPassword = async (data) => {
  return axios({ method: 'POST', url: '/auth/setNewPassword', data });
};

export const convertToAutomapping = async (id, data) => {
  return axios.post(`/mappingSession/${id}/convertToAutomated`, data);
};

export const updateLoginStatus = async (loggingIn) => {
  try {
    return axios.post('/auth/updateLoginStatus', {
      loggingIn,
    });
  } catch (error) {
    return error;
  }
};
