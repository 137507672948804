import {
  CatalogFilterParams,
  CatalogSortParams,
  MappingSessionFilterParams,
  MappingSessionSortParams,
  MappingType,
  PaginationFilteredParams,
} from '../../../services/api/types';
import { DocumentTabs } from '../../../types';
import { Option } from '../../../components/types';

const defaultFilters = (tab: DocumentTabs, templateType: Option['value'] = 'Automated') => {
  if (tab === DocumentTabs.SOURCE || (tab === DocumentTabs.TARGET && templateType === 'Target')) {
    const filters: PaginationFilteredParams<CatalogFilterParams, CatalogSortParams>['filters'] = {
      documentType: tab,
    };
    return filters;
  } else {
    let filters: PaginationFilteredParams<
      MappingSessionFilterParams,
      MappingSessionSortParams
    >['filters'];
    if (tab === DocumentTabs.TARGET && templateType === 'Automated') {
      filters = {
        mappingType: MappingType.AUTO_MAPPING,
        status: 'finished',
      };
    } else if (tab === DocumentTabs.AUTOMATED) {
      filters = {
        mappingType: MappingType.AUTO_MAPPING,
        status: 'finished',
      };
    } else if (tab === DocumentTabs.IN_PROGRESS) {
      filters = {
        mappingType: [MappingType.AI_AUTHORING, MappingType.AUTHORING],
        status: ['partial', 'pending'],
      };
    } else if (tab === DocumentTabs.COMPLETED) {
      filters = {
        mappingType: [MappingType.AI_AUTHORING, MappingType.AUTHORING],
        status: ['complete', 'finished'],
      };
    }
    return filters;
  }
};

export default defaultFilters;
