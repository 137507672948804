import { useContext, useState } from 'react';
import { RejectionFormValues } from '../types';
import { initialRejectionFormValues } from '../components/RejectionSlideOver/utils/constants';
import { getCatalogId, getCellElement, getElement } from '../../../helpers/generalizationHelpers';
import { MappingContext } from '../../../contexts/MappingContext';
import { findTableNumberByTdNodeId } from '../../../helpers/dvHelpers';
import { GeneralizationContext } from '../../../contexts/GeneralizationContext';
import useModal from '../../../hooks/useModal';

export const useRejectionNote = ({
  targetNodeId,
  sourceNodeId,
  editMapping,
}: {
  targetNodeId: string;
  sourceNodeId: string;
  editMapping: (rejectionFormValues: RejectionFormValues) => void;
}) => {
  const [mappingData, setMappingData] = useState<RejectionFormValues>(initialRejectionFormValues);
  const { editor1Ref, editor2Ref, sourceFileDoc } = useContext(MappingContext);
  const { selectedSourceFileName } = useContext(GeneralizationContext);

  const {
    show: showRejectionSlideOver,
    closeModal: closeRejectionSlideOver,
    openModal: openRejectionSlideOver,
  } = useModal();

  const onRejectionModalOpen = () => {
    const targetElement = getElement(editor1Ref, targetNodeId);
    let targetCell: HTMLTableCellElement | null = null;
    if (targetElement) targetCell = getCellElement(targetElement, editor1Ref.current.getBody());
    const tableNumber = findTableNumberByTdNodeId(targetNodeId, editor1Ref.current.getDoc());

    const tableRow: HTMLElement | null = targetCell ? targetCell.parentElement : null;
    setMappingData((prevData) => ({
      ...prevData,
      suggestedChange: getElement(editor2Ref, sourceNodeId)?.innerText ?? '',
      documentTitle: sourceFileDoc?.filename || selectedSourceFileName,
      documentNumber: Number(getCatalogId(editor2Ref, sourceNodeId)) || '',
      targetTableColumn: targetCell ? targetCell.cellIndex + 1 : 0,
      targetTableRow: tableRow ? (tableRow as HTMLTableRowElement).rowIndex + 1 : 0,
      targetValue: targetCell ? targetCell.innerText : '',
      targetTableNumber: tableNumber,
    }));
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMappingData({ ...mappingData, [name]: value });
  };

  const onRejectionFormSubmit = (rejectionFormValues: RejectionFormValues) => {
    const newMappingData = { ...mappingData, ...rejectionFormValues };
    setMappingData(newMappingData);
    editMapping(newMappingData);
    closeRejectionSlideOver();
  };

  const resetMappingData = () => setMappingData(initialRejectionFormValues);

  return {
    onRejectionModalOpen,
    mappingData,
    onChangeHandler,
    onRejectionFormSubmit,
    resetMappingData,
    showRejectionSlideOver,
    openRejectionSlideOver,
    closeRejectionSlideOver,
  };
};
