import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadCrumbData, IBreadCrumb } from '../../types';

const BreadCrumbs: FC<IBreadCrumb> = ({ className, data }) => {
  const navigate = useNavigate();

  const getButtonColor = (el: BreadCrumbData): string => {
    if (el?.color) {
      return el.color;
    } else if (el?.clickable === false) {
      return '#000000';
    } else {
      return '';
    }
  };

  return (
    <h1 className={className}>
      {data?.map((el: any, idx) => (
        <span key={el?.text || idx}>
          <button
            style={{ color: getButtonColor(el) }}
            onClick={() => {
              navigate(`${el?.link ? el?.link : ''}`, {
                state: el?.state,
              });
            }}>
            {`${el?.text} /`}
          </button>{' '}
        </span>
      ))}
    </h1>
  );
};

export default BreadCrumbs;
