// React-related imports
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// Contexts and Hooks
import { MappingContext } from '../../contexts/MappingContext';
import { GeneralizationContext } from '../../contexts/GeneralizationContext';
import { useSourceFiles } from '../../hooks/useSourceFiles';
import { useDataVerificationCSVExport } from './hooks/useDataVerificationCSVExport';

// Components
import LoadingOverlay from '../../components/elements/LoadingOverlay';
import SuggestionNavigateButtons from '../../components/SuggestionNavigateButtons';
import TinyEditor from '../../components/TinyEditor';
import Layout from '../../components/layout';
import DataVerificationHeader from './components/DataVerificationHeader/DataVerificationHeader';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import DataVerificationActions from './components/DataVerificationActions/DataVerificationActions';
import SourcesActions from '../../components/SourceActions/SourceActions';
import { DeleteConfirmationDialogue } from '../../components/modals/DeleteConfirmationDialogue';
import { RejectionSlideOver } from './components/RejectionSlideOver';

// Helpers
import { handleEnterKeyPress, getElementsByQuery } from '../../helpers/generalizationHelpers';
import { search } from '../../services/apiCalls';
import { AddSourcesPreviousAction } from '../catalog/util/constants';

// Types
import { Node as customNode, SearchOptions, SearchResult, EditorTypeEnum } from '../../types';

// Other dependencies
import { Button } from '@gloabal-regulatory-writing-consulting/gxt-components';
import useFullscreen from '../../hooks/useFullscreen';
import { handleDownload } from '../../helpers/convertFileType';
import { useNavigate } from 'react-router-dom';
import { useDataVerification } from './hooks/index';
import CommentSideBar from '../../components/CommentSideBar';
import { TargetSaveState } from '../authoring/hooks/types';
import { SearchThreshold } from '../../constants';

const DataVerification = () => {
  const {
    isLoading,
    highlightAllTargetSuggestions,
    highlightAllSourceDocumentSuggestions,
    receiveSuggestions,
    disableApprove,
    displayedCommentButton,
    targetOnClickHandler,
    updatedAcceptedSuggestions,
    currentTargetNodeIds,
    setSearchResult,
    setIsSearched,
    validatedCells,
    navigateToDVCell,
    isPreviousDVCellAvailable,
    isNextDVCellAvailable,
    setCurrentTargetNodeIds,
    setPreviousTargetNodeIds,
    updateDVJumpIndex,
    goToTargetCell,
    goToSourceCell,
    searchResult,
    mappingData,
    onRejectionFormSubmit,
    onRejectionModalOpen,
    disableReject,
    closeRejectionSlideOver,
    showRejectionSlideOver,
    openRejectionSlideOver,
    commentModal,
    openCommentModalWithThread,
    currentSuggestionId,
  } = useDataVerification();

  const { editor1Ref, editor2Ref, targetId, toggleComments, mappingComments, isCommentDisplayed } =
    useContext(MappingContext);

  const {
    selectedCatalogId,
    sourceDocuments,
    generatedDocument,
    lastSaved,
    authors,
    validators,
    loadingSourceDocument,
    sessionId,
    setSearchTerm,
    isFullScreen,
  } = useContext(GeneralizationContext);

  const { exportDetailedRecords, exportFinalizedRecords, exportDVFormRecords, exportNotes } =
    useDataVerificationCSVExport({ updatedAcceptedSuggestions });

  const {
    handleRemoveSourceFile,
    handleFileRemoveModalClose,
    showSourceDeleteModal,
    sourceDocsArray,
    handleSourceToggle,
    lockedFileSwitch,
    sourceFilesDropdown,
    currentFileVersion,
  } = useSourceFiles({ sessionType: 'verification' });

  const navigate = useNavigate();

  const [searchOptions, setSearchOptions] = useState<SearchOptions[]>([]);

  const acceptButtonRef = useRef<HTMLButtonElement>(null);
  const generatedDocumentRef = useRef<typeof generatedDocument | null>(generatedDocument);

  const handleRejectionModalOpen = () => {
    openRejectionSlideOver();
    onRejectionModalOpen();
  };

  useEffect(() => {
    document.addEventListener('keypress', (e) =>
      handleEnterKeyPress(e, acceptButtonRef, editor1Ref),
    );

    return () => {
      document.removeEventListener('keypress', (e) =>
        handleEnterKeyPress(e, acceptButtonRef, editor1Ref),
      );
    };
  }, []);

  const validatedCellCount = useMemo(() => {
    if (!updatedAcceptedSuggestions) {
      return 'Validated Data 0/0';
    }

    return `Validated Data ${validatedCells.length}/${updatedAcceptedSuggestions.length}`;
  }, [updatedAcceptedSuggestions, validatedCells]);

  const customTextOnSelect = (node: customNode): string => {
    if (
      node &&
      node.props &&
      Array.isArray(node.props.children) &&
      node.props.children[0] &&
      node.props.children[0].props
    ) {
      return node.props.children[0].props.children.toString();
    }
    return '';
  };

  const handleSearchTerm = useCallback(
    async (term: string) => {
      let searchResponse: SearchResult[] = [];
      if (term) {
        searchResponse = await search({
          searchTerm: term,
          searchThreshold: SearchThreshold,
          dataDocIds: sourceDocsArray.map((doc) => +doc.value),
        }).then((result) => result.data.data);
      }
      if (!searchResponse.length || !term) {
        const results = [
          {
            label: <p className="text-sm font-semibold text-gray-500">No Result Found</p>,
            value: -1,
          },
        ];
        setSearchOptions(results);
      } else {
        setSearchResult(searchResponse);
        const options: SearchOptions[] = searchResponse?.map((result: SearchResult, i: number) => {
          return {
            label: (
              <div onClick={() => setIsSearched(true)}>
                <p className="text-sm font-semibold">{result.dataText}</p>
                <p className="text-xs text-gray-500">{result.fileTitle}</p>
              </div>
            ),
            value: i,
            nodeId: result.dataNodeId,
          };
        });
        setSearchOptions(options);
      }
    },
    [selectedCatalogId, sourceDocuments, targetId],
  );

  const { toggleFullScreen } = useFullscreen();

  useEffect(() => {
    generatedDocumentRef.current = generatedDocument;

    return () => {
      generatedDocumentRef.current = null;
    };
  }, [generatedDocument]);

  const navigateCells = (direction: 'next' | 'previous') => {
    removeSearchedHighlight();
    const nodeId = navigateToDVCell(direction);
    if (nodeId) {
      updateDVJumpIndex(nodeId);
      setCurrentTargetNodeIds((prev) => {
        setPreviousTargetNodeIds(prev);
        return [nodeId];
      });
    }
  };

  const removeSearchedHighlight = () => {
    const prevEl = getElementsByQuery(editor2Ref, '.searched-source-node');
    if (prevEl && prevEl.length > 0) {
      (prevEl[0] as HTMLElement).classList.remove('searched-source-node');
      (prevEl[0] as HTMLElement).style.backgroundColor = '';
    }
  };

  useEffect(() => {
    setSearchTerm('');
    const handleEnterKey = (e: KeyboardEvent) => handleEnterKeyPress(e, acceptButtonRef);

    document.addEventListener('keypress', handleEnterKey);

    return () => {
      document.removeEventListener('keypress', handleEnterKey);
    };
  }, []);

  const exportDocument = (exportDocument: any) => {
    switch (exportDocument) {
      case 'Annotated Document':
      case 'Clean Document':
        handleDownload(
          editor1Ref?.current?.getBody()?.innerHTML,
          generatedDocument?.filename,
          updatedAcceptedSuggestions,
          exportDocument === 'Annotated Document',
        );
        break;
      case 'Detailed Records':
        exportDetailedRecords();
        break;
      case 'Finalized Records':
        exportFinalizedRecords();
        break;
      case 'DV Form Records':
        exportDVFormRecords();
        break;
      case 'Export Rejection Notes':
        exportNotes();
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    navigate('/catalogs');
  };

  return (
    <>
      <Layout>
        <Layout.Header>
          <DataVerificationHeader
            handleExportDocument={exportDocument}
            handleClose={handleClose}
            handleViewComments={toggleComments}
            isViewCommentsDisabled={!displayedCommentButton}
            handleCommentOnClick={() => openCommentModalWithThread(currentTargetNodeIds[0])}
            isCommentDisabled={!currentSuggestionId}
            isExportDisabled={!updatedAcceptedSuggestions?.length}
          />
        </Layout.Header>
        <Layout.Body>
          {isLoading ? (
            <LoadingOverlay />
          ) : (
            <div className="w-full flex flex-col flex-grow">
              {!isFullScreen && (
                <InfoPanel
                  lastSaved={lastSaved}
                  badges={[
                    { label: 'Authored by', values: authors },
                    { label: 'Verified by', values: validators },
                  ]}
                />
              )}
              <div className={`flex flex-col flex-grow`}>
                <DataVerificationActions
                  suggestionOptions={[]}
                  searchOptions={searchOptions}
                  isFullScreen={isFullScreen}
                  handleFullScreen={toggleFullScreen}
                  handleSearchTerm={handleSearchTerm}
                  customTextOnSelect={customTextOnSelect}
                  handleAiDropDownChange={(option) =>
                    goToSourceCell(searchResult[option.value].dataNodeId)
                  }
                  targetChangesStatus={TargetSaveState.AUTOSAVED}
                />
                {loadingSourceDocument && <LoadingOverlay />}
                <div className="w-full flex justify-between items-center space-x-5">
                  <div className="w-full">
                    <SourcesActions
                      currentSourceVersion={currentFileVersion}
                      handleSourceToggle={handleSourceToggle}
                      lockedFileSwitch={lockedFileSwitch}
                      sourceFilesDropdown={sourceFilesDropdown}
                      displayLockFileButton={false}
                      navigateAddSource={() => {
                        navigate('/add-source', {
                          state: {
                            sessionId,
                            previousAction: AddSourcesPreviousAction.AUTHORING_REQUEST,
                          },
                        });
                      }}
                    />
                  </div>

                  <div className="w-full flex flex-col">
                    <div className="flex justify-start space-x-2">
                      <h2 className="text-primary-300 mb-2">Target:</h2>
                      <h3 className="text-primary-300 font-normal">
                        {generatedDocument?.filename}
                      </h3>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2 whitespace-nowrap">
                        <Button
                          variant="positive"
                          ref={acceptButtonRef}
                          onClick={() => receiveSuggestions(true)}
                          disabled={disableApprove}>
                          Approve
                        </Button>
                        <Button
                          variant="negative"
                          disabled={disableReject}
                          onClick={
                            currentTargetNodeIds.length > 1
                              ? () => receiveSuggestions(false)
                              : handleRejectionModalOpen
                          }>
                          Reject
                        </Button>
                      </div>
                      <SuggestionNavigateButtons
                        isPreviousAvailable={isPreviousDVCellAvailable}
                        isNextAvailable={isNextDVCellAvailable}
                        navigateMapping={navigateCells}
                        label={validatedCellCount}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="relative flex space-x-3 my-5 flex-grow"
                  data-testid="editor-wrapper">
                  <div className="flex-1 border border-neutral-200 rounded-[4px] p-3">
                    <TinyEditor
                      key="source"
                      editorType={EditorTypeEnum.Source}
                      disabled={false}
                      editorOptions={(editor) => {
                        editor.on('keydown', (e: KeyboardEvent) => {
                          handleEnterKeyPress(e, acceptButtonRef, editor2Ref);
                        });
                      }}
                      initialize={() => highlightAllSourceDocumentSuggestions()}
                      height="100%"
                    />
                  </div>

                  <div className="flex-1 border border-neutral-200 rounded-[4px] p-3">
                    <TinyEditor
                      key="target"
                      editorType={EditorTypeEnum.Target}
                      disabled={false}
                      initialize={() => {
                        highlightAllTargetSuggestions(editor1Ref);
                      }}
                      onClickHandler={targetOnClickHandler}
                      editorOptions={(editor: any) => {
                        editor.on('keydown', (e: any) =>
                          handleEnterKeyPress(e, acceptButtonRef, editor),
                        );
                      }}
                      height="100%"
                    />
                  </div>

                  <Button
                    variant="primary"
                    circular={true}
                    className="h-[42px] w-[42px] absolute -bottom-2 right-0">
                    ?
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Layout.Body>
      </Layout>
      <DeleteConfirmationDialogue
        isOpen={showSourceDeleteModal}
        handleClose={handleFileRemoveModalClose}
        item="this file"
        handleDelete={handleRemoveSourceFile}
      />
      <CommentSideBar
        comments={mappingComments}
        hidden={!isCommentDisplayed}
        toggleDisplay={toggleComments}
        onCommentClick={goToTargetCell}
      />
      <RejectionSlideOver
        handleSubmit={onRejectionFormSubmit}
        isOpen={showRejectionSlideOver}
        onClose={closeRejectionSlideOver}
        initialFormValues={mappingData}
      />
      {commentModal}
    </>
  );
};

export default DataVerification;
