// React-related imports
import { useContext, useState } from 'react';

// Contexts
import { MappingContext } from '../../../contexts/MappingContext';

// Hooks
import useModal from '../../../hooks/useModal';

// Helpers
import { getActiveSvgPin, removeActivePin } from '../../../helpers/imageTaggingHelper';
import { constructSuggestionLocation } from '../../../helpers/dvHelpers';
import { getElement } from '../../../helpers/generalizationHelpers';

// Types
import { AcceptanceMethodEnum, AIResponse } from '../../../types';
import { ImageTagFormValues } from '../types';

// Constants
import { initialImageTagValues } from '../utils/constants';

export const useImageTagging = ({
  updatedAiResponse,
  selectedTargetNode,
  onSubmit,
}: {
  updatedAiResponse: AIResponse;
  selectedTargetNode: string;
  onSubmit: (formData: ImageTagFormValues, acceptanceMethod: AcceptanceMethodEnum.AUTHOR) => void;
}) => {
  const { editor1Ref, editor2Ref } = useContext(MappingContext);

  const [isImageTagging, setIsImageTagging] = useState(false);
  const [imageTagFormValues, setImageTagFormValues] = useState(initialImageTagValues);
  const { show, closeModal, openModal } = useModal();

  const toggleImageTagging = () => {
    const editorDoc = editor2Ref.current?.getDoc();

    if (editorDoc) {
      const activeTagElement = getActiveSvgPin(editorDoc);

      const targetNodeId = activeTagElement?.getAttribute('data-pinid');

      if (targetNodeId && !(targetNodeId in updatedAiResponse)) {
        removeActivePin(editorDoc);
      }
    }

    setIsImageTagging((prev) => !prev);
  };

  const closeTaggingSlideOver = () => {
    const editorDoc = editor2Ref.current?.getDoc();

    if (editorDoc) removeActivePin(editorDoc);
    closeModal();
    setIsImageTagging(false);
  };

  const openTaggingSlideOver = () => {
    const editorDoc = editor1Ref.current?.getDoc();

    if (editorDoc) {
      const {
        tableNumber: targetTableNumber,
        tableColumn: targetTableColumn,
        tableRowNumber: targetTableRow,
      } = constructSuggestionLocation(editorDoc, selectedTargetNode);
      setImageTagFormValues({
        ...initialImageTagValues,
        targetTableNumber,
        targetTableColumn,
        targetTableRow,
        targetDocumentData: getElement(editor1Ref, selectedTargetNode)?.innerText ?? '',
      });
    }

    openModal();
  };

  const handleSubmitTag = (formData: ImageTagFormValues) => {
    onSubmit(formData, AcceptanceMethodEnum.AUTHOR);
    setIsImageTagging(false);
    closeModal();
  };

  return {
    toggleImageTagging,
    isImageTagging,
    imageTagFormValues,
    isTaggingSlideOverOpen: show,
    closeTaggingSlideOver: closeTaggingSlideOver,
    openTaggingSlideOver,
    handleSubmitTag,
  };
};
