import { FC } from 'react';
import Button from './elements/Button';

interface ITab {
  active: boolean;
  onClickHandler: () => void;
  title: string | number;
}

export const Tab: FC<ITab> = ({ active, onClickHandler, title }) => {
  return (
    <Button
      title={title}
      className={`${
        active ? 'bg-primary_bg_color' : 'bg-gray-400'
      }  py-2 px-3 text-white rounded-t-md`}
      onClick={onClickHandler}
    />
  );
};
