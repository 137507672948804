import {
  REACT_APP_BACKEND_URL,
  REACT_APP_USE_STATIC_PROJECT_NAME,
  REACT_APP_PROJECT_NAME,
} from '../constants';

const ENV_SUBDOMAINS = ['dev', 'staging', 'stg', 'prod'];
const MULTI_ENV_DEPLOYMENT = ['autowriter', 'modernauthor', 'regwriter'];

const { hostname } = window.location;
export const getEnvFromHostname = (url = hostname) => {
  url = removeHttps(url);
  // Temporary fix for prod migration
  const env =
    ENV_SUBDOMAINS.includes(url.split('.')[0]) && url.split('.')[0] !== 'prod'
      ? `${url.split('.')[0]}-api.`
      : 'api.';
  return env;
};

export const getClientName = (url = hostname) => {
  if (REACT_APP_USE_STATIC_PROJECT_NAME) {
    if (REACT_APP_PROJECT_NAME === undefined)
      throw new Error(
        'REACT_APP_PROJECT_NAME is undefined. Please check your .env file and makk sure it is defined, or set REACT_APP_USE_STATIC_PROJECT_NAME to false',
      );
    return REACT_APP_PROJECT_NAME;
  }
  url = removeHttps(url);
  let name: string | undefined = ENV_SUBDOMAINS.includes(url.split('.')[0])
    ? url.split('.')[1]
    : url.split('.')[0];
  name =
    name === 'localhost'
      ? process.env.REACT_APP_PROJECT_NAME?.toLowerCase() || 'autowriter'
      : (name as string);
  if (name === 'autowriter') return 'modernauthor';
  return name || '';
};

export const getAPIUrl = (url: string = hostname) => {
  url = removeHttps(url);
  if (url === 'localhost') return REACT_APP_BACKEND_URL;
  // if https:// is included in the url, remove it
  const clientName = getClientName(url) || (REACT_APP_BACKEND_URL as string);
  const env = (MULTI_ENV_DEPLOYMENT.includes(clientName) && getEnvFromHostname(url)) || 'api.';
  return `https://${env}${clientName}.globalxt.io`;
};

const removeHttps = (url: string) => {
  return url.replace('https://', '');
};
