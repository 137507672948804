// React-related imports
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

// Component imports
import { DataLocation } from '../DataLocation';

// Types
import { SourceTargetFormProps } from './SourceTargetForm.types';
import { ImageTagFormValues } from '../../../../types';
import { TextArea } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { imageTagFormSchema } from '../../utils/validationSchema';

const SourceTargetForm = forwardRef(
  ({ handleSubmitData, defaultValues }: SourceTargetFormProps, ref) => {
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<ImageTagFormValues>({
      defaultValues,
      resolver: zodResolver(imageTagFormSchema),
    });

    const onSubmit: SubmitHandler<ImageTagFormValues> = (data: ImageTagFormValues) => {
      handleSubmitData(data);
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues, reset]);

    return (
      <form className="flex flex-col gap-4 self-stretch">
        <DataLocation
          control={control}
          errors={errors}
          prefix="source"
          requiredFieldLabel="Source Document Data"
          requiredFieldName="sourceDocumentData"
        />
        <DataLocation
          control={control}
          errors={errors}
          prefix="target"
          requiredFieldLabel="Target Document Data"
          requiredFieldName="targetDocumentData"
        />

        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <TextArea
                {...field}
                id="note"
                heading="Note"
                placeholder="Add a note"
                className="w-full"
                customStyles={{
                  input: { width: '100%' },
                }}
              />
            </div>
          )}
        />
      </form>
    );
  },
);

SourceTargetForm.displayName = 'SourceTargetForm';

export default SourceTargetForm;
