import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { Context } from '../routes/PrivateRoutes';
import { useContext, useEffect } from 'react';
import MultipleFileUploader from './MultipleFileUploader';
import { LOADER_COLOR } from '../constants';

export const FileUploader = () => {
  const { singleFileHandler, templateFileHandler, isButtonDisable, showLoader, setMultipleFile } =
    useContext(Context);

  useEffect(() => {
    setMultipleFile([]);
  }, []);

  return (
    <form action={`${process.env.HOST}/viewer`}>
      <div className="flex justify-center space-x-6 mt-10">
        <div className="p-6 bg-slate-100 h-64 rounded-lg border border-gray-200 shadow-lg ">
          <div className="shrink-0">
            <p className="mb-5 text-black font-bold text-sm">Select a Template file</p>
            <div className="flex justify-center items-center space-x-2">
              <input
                type="file"
                onChange={templateFileHandler}
                className="block w-full text-sm text-slate-500 border border-primary_border_color rounded-md p-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-white file:text-primary_text_color hover:file:bg-blue-100"
              />
              {showLoader.templateFileLoader && (
                <ReactLoading type={'spin'} color={LOADER_COLOR} height={25} width={30} />
              )}
            </div>
          </div>
        </div>
        <div className="p-6 bg-slate-100 h-64 rounded-lg border border-gray-200 shadow-lg">
          <div className="shrink-0 mb-7">
            <p className="mb-3 text-black font-bold text-sm">Select a single target file</p>
            <div className="flex justify-center items-center space-x-2">
              <input
                type="file"
                onChange={singleFileHandler}
                className="block w-full text-sm text-slate-500 border border-primary_border_color rounded-md p-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-white file:text-primary_text_color hover:file:bg-blue-100"
              />
              {showLoader.singleFileLoader && (
                <ReactLoading type={'spin'} color={LOADER_COLOR} height={25} width={30} />
              )}
            </div>
          </div>
          <MultipleFileUploader showLoader={showLoader} />
        </div>
      </div>
      <div className=" flex justify-center mt-5">
        <Link to="/viewer">
          <button
            type="button"
            className={`text-white ${
              isButtonDisable.singleFileSelected ||
              isButtonDisable.multipleFileSelected ||
              isButtonDisable.templateFileSelected
                ? 'bg-gray-500 hover:bg-gray-500'
                : 'bg-primary_bg_color hover:bg-secondary_bg_color'
            } font-medium rounded-lg text-sm px-5 py-2.5`}
            disabled={
              isButtonDisable.singleFileSelected ||
              isButtonDisable.multipleFileSelected ||
              isButtonDisable.templateFileSelected
            }>
            Proceed
          </button>
        </Link>
      </div>
    </form>
  );
};
