import { FC } from 'react';
import { GeneralizationDocument, IAllSourceFiles, IDropdown } from '../../types';
import Input from './Input';

interface IDropdownComponent {
  options: void | IDropdown[] | GeneralizationDocument[] | IAllSourceFiles[];
  changeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedValue: string | number;
  placeholder?: string;
  disabled?: boolean;
}

const Dropdown: FC<IDropdownComponent> = ({
  options,
  changeHandler,
  selectedValue,
  placeholder = '',
  disabled,
}) => {
  return (
    <Input
      type="select"
      className="text-white text-sm rounded-lg w-full p-2.5 bg-primary_bg_color hover:bg-secondary_bg_color hover:cursor-pointer"
      dataTestId="select-dv"
      options={options}
      onChange={changeHandler}
      value={selectedValue}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default Dropdown;
