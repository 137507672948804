/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState, useMemo } from 'react';
import _ from 'lodash';
import { Editor as TinyMCEEditor } from 'tinymce';

import {
  addColorToObjects,
  getAllNodesIdsInElement,
  getCatalogId,
  getCatalogWithSourceIdsFromSuggestions,
  getElement,
  getCellElement,
  highlightSuggestion,
  updateElementBackgroundColor,
  getCellNodeId,
} from '../../../helpers/generalizationHelpers';
import {
  EditorRef,
  FilterType,
  GeneralizationSuggestionWithColor,
  HighlightObject,
} from '../../../types';
import {
  fetchMappingSessionComments,
  saveSuggestion,
  saveSuggestionStatus,
} from '../../../services/apiCalls';
import { notifyError, notifySuccess } from '../../../helpers/utils';
import {
  LIGHT_GRAY_COLOR,
  mappingByType,
  suggestionStatus,
  VERIFICATION_COLORS,
} from '../../../constants';
import { MappingContext } from '../../../contexts/MappingContext';
import { GeneralizationContext } from '../../../contexts/GeneralizationContext';
import { useGeneralizationComments } from '../../../hooks/generalizationHooks/useGeneralizationComments';
import useSearchResult from '../../../hooks/useSearchResult';
import { useGeneralizationNavigation } from '../../../hooks/generalizationHooks/useGeneralizationNavigation';
import { applyCustomSVGs, handleSvgPinActivation } from '../../../helpers/imageTaggingHelper';
import useClientInfo from '../../../hooks/useClientInfo';
import { RejectionFormValues } from '../types';
import { useSuggestion } from '../../../services/api/suggestion';
import { useRejectionNote } from './useRejectionNote';

export const useDataVerification = () => {
  const {
    editor1Ref,
    setEditor1Content,
    editor2Ref,
    sourceFileDoc,
    setMappingComments,
    mappingComments,
    isCommentDisplayed,
    editor1Content,
    addCommentBubble,
  } = useContext(MappingContext);

  const {
    loadingGeneratedDocument,
    acceptedSuggestions,
    generatedDocument,
    selectedCatalogId,
    loadingSourceDocument,
    sessionId,
    handleSourcechange,
    setLastSaved,
    setValidators,
    validators,
    cells,
    targetNodeIdSuggestionMap,
    sourceNodeIdSuggestionMap,
  } = useContext(GeneralizationContext);
  const { firstName, lastName } = useClientInfo();

  const [updatedAcceptedSuggestions, setUpdatedAcceptedSuggestions] =
    useState<GeneralizationSuggestionWithColor[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isDisabledApprove, setIsDisabledApprove] = useState<boolean>(true);
  const [hasNoResponse, setHasNoResponse] = useState<boolean>(false);
  const [target, setTarget] = useState<number>(-1);
  const [currentTargetNodeIds, setCurrentTargetNodeIds] = useState<string[]>([]);
  const [currentSourceNodeIds, setCurrentSourceNodeIds] = useState<string[]>([]);
  const [previousTargetNodeIds, setPreviousTargetNodeIds] = useState<string[]>([]);
  const [previousSourceNodeIds, setPreviousSourceNodeIds] = useState<string[]>([]);
  const [disableTransferModal, setDisableTransferModal] = useState<boolean>(true);
  const [nodeValue, setNodeValue] = useState<string>('');
  const [nodeStatus, setNodeStatus] = useState<string>('');

  const { addRejectionNote } = useSuggestion();

  const filterCells = (cells: string[], suggestions: GeneralizationSuggestionWithColor[]) => {
    const filteredCells = cells.filter(
      (element) =>
        suggestions?.some((suggestion) => suggestion.targetNodeId === element) ||
        currentTargetNodeIds[0] === element,
    );

    return filteredCells;
  };

  const validatedCells = useMemo(() => {
    return updatedAcceptedSuggestions
      ? updatedAcceptedSuggestions.filter((s) => s.status !== 'pending')
      : [];
  }, [updatedAcceptedSuggestions]);

  const nonValidatedCells = useMemo(() => {
    return updatedAcceptedSuggestions
      ? updatedAcceptedSuggestions.filter((s) => s.status === 'pending')
      : [];
  }, [updatedAcceptedSuggestions]);

  const { navigateToCell: jumpNext, updateIndex: updateDVJumpIndex } = useGeneralizationNavigation(
    [...filterCells(cells, nonValidatedCells)],
    0,
    currentTargetNodeIds[0],
  );

  const {
    navigateToCell: navigateToDVCell,
    isPreviousCellAvailable: isPreviousDVCellAvailable,
    isNextCellAvailable: isNextDVCellAvailable,
    updateIndex: updateDVNavigationIndex,
    nextStep,
  } = useGeneralizationNavigation(
    [...filterCells(cells, updatedAcceptedSuggestions || [])],
    0,
    currentTargetNodeIds[0],
  );

  const { isSearched, setIsSearched, searchResult, setSearchResult } = useSearchResult();

  const currentSuggestionId = targetNodeIdSuggestionMap[currentTargetNodeIds[0]]
    ? targetNodeIdSuggestionMap[currentTargetNodeIds[0]].id
    : null;

  const { commentModal, openCommentModalWithThread } = useGeneralizationComments(
    currentSuggestionId ? currentSuggestionId : 0,
  );

  const getCurrentNodeId = (target: HTMLElement) => {
    const elementNodeId = target.getAttribute('data-nodeid');

    const allNodesIds = getAllNodesIdsInElement(
      target.getAttribute('data-comment') ? target.parentElement : target,
    );

    const nodeWithSuggestion = allNodesIds.find((nodeId) => !!targetNodeIdSuggestionMap[nodeId]);

    return nodeWithSuggestion || elementNodeId || '';
  };

  const targetOnClickHandler = (event: MouseEvent, editor: TinyMCEEditor) => {
    setIsSearched(false);
    const target = event.target as HTMLElement;
    const targetNodeId = target.getAttribute('data-nodeid');
    const selectedCells = editor.model.table.getSelectedCells();

    const isImageTag = target.tagName === 'IMG';
    const isImageWrapped =
      isImageTag && target.parentElement && target.parentElement.tagName === 'P';
    const imagePTagNodeId = target?.parentElement?.getAttribute('data-nodeid');

    if (isImageWrapped && imagePTagNodeId && targetNodeIdSuggestionMap[imagePTagNodeId]) {
      handleTargetNodeIds([imagePTagNodeId]);
      return;
    }
    if (target.getAttribute('data-comment')) openCommentModalWithThread(getCurrentNodeId(target));
    const cellElement = getCellElement(target, editor1Ref.current.getBody());
    if (cellElement && cellElement.hasAttribute('data-nodeid')) {
      handleTargetNodeIds([getCurrentNodeId(cellElement)]);
      updateDVJumpIndex(getCurrentNodeId(cellElement));
      updateDVNavigationIndex(getCurrentNodeId(cellElement));
    } else if (selectedCells.length > 0) {
      const validNodeIds = selectedCells.reduce((acc: string[], cell: HTMLElement) => {
        const nodeId = getCurrentNodeId(cell);
        return targetNodeIdSuggestionMap[nodeId]?.status !== suggestionStatus.APPROVED
          ? [...acc, nodeId]
          : acc;
      }, [] as string[]);
      handleTargetNodeIds(validNodeIds);
    } else if (targetNodeId && targetNodeIdSuggestionMap[targetNodeId]) {
      handleTargetNodeIds([targetNodeId]);
    }
  };

  const nextSuggestions = () => {
    if (!updatedAcceptedSuggestions) return;
    const groupedSuggestionsObject = _.groupBy(updatedAcceptedSuggestions, 'targetNodeId');
    const groupedSuggestionArray = Object.values(groupedSuggestionsObject);

    if (currentTargetNodeIds.length > 1) {
      const lastNodeId = _.last(currentTargetNodeIds);
      if (lastNodeId) {
        handleTargetNodeIds([lastNodeId]);
      }
    } else {
      const updatedIndex = target + 1;
      const lastIndex = groupedSuggestionArray.length - 1;
      const index = Math.min(Math.max(updatedIndex, 0), lastIndex);
      if (groupedSuggestionArray[index]?.length) {
        handleTargetNodeIds([groupedSuggestionArray[index][0].targetNodeId]);
      }
    }
  };

  const prevSuggestions = () => {
    if (!updatedAcceptedSuggestions) return;

    if (currentTargetNodeIds.length > 1) {
      const firstNodeId = _.first(currentTargetNodeIds);
      if (firstNodeId) {
        handleTargetNodeIds([firstNodeId]);
      }
    } else {
      const updatedIndex = Math.max(target - 1, 0);
      const lastIndex = updatedAcceptedSuggestions.length - 1;
      const selectedSuggestion = updatedAcceptedSuggestions[Math.min(updatedIndex, lastIndex)];
      handleTargetNodeIds([selectedSuggestion.targetNodeId]);
    }
  };

  const isNextAvailable = () => {
    if (currentTargetNodeIds.length > 1) {
      return true;
    }
    return updatedAcceptedSuggestions && target < updatedAcceptedSuggestions?.length - 1;
  };

  const isPreviousAvailable = () => {
    if (currentTargetNodeIds.length > 1) {
      return true;
    }
    return target > 0;
  };

  const highlightSuggestions = (
    editorRef: any,
    highlightObjects: HighlightObject[],
    defaultColor: string | null = null,
  ) => {
    highlightObjects.forEach(({ id, color }) => {
      const element = getElement(editorRef, id);
      if (element) {
        highlightSuggestion({ element, color: defaultColor ?? color });
      }
    });
  };

  const getHighlightedObjects = (): HighlightObject[] => {
    return (updatedAcceptedSuggestions || []).reduce(
      (highlightedObjects: HighlightObject[], suggestion) => {
        const id = getCellNodeId(suggestion.targetNodeId, editor1Ref) || '';
        if (id) {
          highlightedObjects.push({ id, color: suggestion.color });
        }
        return highlightedObjects;
      },
      [],
    );
  };

  const highlightAllTargetSuggestions = (editorRef: EditorRef) => {
    if (updatedAcceptedSuggestions && updatedAcceptedSuggestions?.length > 0) {
      const highlightObjects = getHighlightedObjects();
      highlightSuggestions(editorRef, highlightObjects);
      addCommentBubbles();

      const initialSuggestions =
        updatedAcceptedSuggestions.find((s) => s.status === 'pending')?.targetNodeId ||
        updatedAcceptedSuggestions[updatedAcceptedSuggestions.length - 1].targetNodeId;
      setCurrentTargetNodeIds([initialSuggestions]);
    }
  };

  const svgValues = useMemo(() => {
    const values: Record<string, any> = {};
    updatedAcceptedSuggestions?.forEach((suggestion: GeneralizationSuggestionWithColor) => {
      const { sourceFileId, targetValue, positionX, positionY, targetNodeId, sourceNodeId } =
        suggestion;

      const sourceNode = {
        value: targetValue,
        sourceFileId,
        positionX,
        positionY,
        targetId: targetNodeId,
      };

      values[sourceNodeId] = [...(values[sourceNodeId] || []), sourceNode];
    });
    return values;
  }, [updatedAcceptedSuggestions]);

  const highlightAllSourceDocumentSuggestions = () => {
    if (selectedCatalogId && updatedAcceptedSuggestions && memoizedCatalogWithSourceIds) {
      const nodeIds = memoizedCatalogWithSourceIds[selectedCatalogId];
      if (!nodeIds) return;
      const highlightObjects = updatedAcceptedSuggestions
        .filter((suggestion) => nodeIds.includes(suggestion.sourceNodeId))
        .map((suggestion) => ({
          id: suggestion.sourceNodeId,
          color: LIGHT_GRAY_COLOR,
        }));

      highlightSuggestions(editor2Ref, highlightObjects);
    }
  };

  const highlightActiveNode = (
    editorRef: any,
    nodeIds: string[],
    previousNodeIds: string[] = [],
  ) => {
    previousNodeIds.forEach((prevNodeId) =>
      highlightSuggestion({
        element: getElement(editorRef, prevNodeId),
        color: LIGHT_GRAY_COLOR,
      }),
    );

    nodeIds.forEach((nodeId) =>
      highlightSuggestion({
        element: getElement(editorRef, nodeId),
        color: VERIFICATION_COLORS.SELECTED,
      }),
    );
    applyCustomSVGs(svgValues, editor2Ref);
  };

  const highlightNode = (
    editorRef: any,
    previousElements: (HTMLElement | null)[],
    currentElements: (HTMLElement | null)[],
    filterType: FilterType = 'targetNodeId',
  ) => {
    if (!updatedAcceptedSuggestions) return;

    const highlightObjects = (element: HTMLElement | null, color?: string) => {
      if (!element) return;
      const nodeIds: string[] = getAllNodesIdsInElement(element);
      const highlightObjs = nodeIds.map((nodeId) => ({ id: nodeId, color }));
      highlightSuggestions(editorRef, highlightObjs, color || '');
    };

    previousElements.forEach((prevElement) => {
      const previousElementNodeIds: string[] = getAllNodesIdsInElement(prevElement);
      const foundSuggestion = updatedAcceptedSuggestions.find((suggestion) =>
        previousElementNodeIds.includes(suggestion[filterType]),
      );
      const color = filterType === 'sourceNodeId' ? LIGHT_GRAY_COLOR : foundSuggestion?.color;
      highlightObjects(prevElement, color);
    });

    currentElements.forEach((currentElement) => {
      highlightObjects(currentElement, VERIFICATION_COLORS.SELECTED);
    });
  };

  const addCommentBubbles = () => {
    mappingComments.forEach((comment) =>
      addCommentBubble(comment.mappingNumber, comment.targetNodeId),
    );
  };

  useEffect(() => {
    const disableTransferModal =
      !updatedAcceptedSuggestions ||
      currentTargetNodeIds.length !== 1 ||
      !currentSourceNodeIds ||
      !updatedAcceptedSuggestions.some(
        (suggestion) =>
          suggestion.targetNodeId === currentTargetNodeIds[0] &&
          suggestion.status !== suggestionStatus.APPROVED,
      ) ||
      updatedAcceptedSuggestions.some(
        (suggestion) =>
          suggestion.targetNodeId === currentTargetNodeIds[0] &&
          suggestion.sourceNodeId === currentSourceNodeIds[0],
      );

    setDisableTransferModal(disableTransferModal);
  }, [updatedAcceptedSuggestions, currentTargetNodeIds, currentSourceNodeIds]);

  const disableApprove = useMemo(() => {
    if (!updatedAcceptedSuggestions || !currentTargetNodeIds) {
      return true;
    }

    const allowedNodeIds = [];
    currentTargetNodeIds.forEach((nodeId) => {
      if (
        targetNodeIdSuggestionMap[nodeId] &&
        targetNodeIdSuggestionMap[nodeId].status !== suggestionStatus.APPROVED
      ) {
        allowedNodeIds.push(nodeId);
      }
    });

    return allowedNodeIds.length === 0;
  }, [updatedAcceptedSuggestions, currentTargetNodeIds, currentSourceNodeIds]);

  const disableReject = useMemo(() => {
    if (!updatedAcceptedSuggestions || !currentTargetNodeIds || currentTargetNodeIds.length === 0) {
      return true;
    }

    const allowedNodeIds = [];
    currentTargetNodeIds.forEach((nodeId) => {
      if (
        targetNodeIdSuggestionMap[nodeId] &&
        targetNodeIdSuggestionMap[nodeId].status === suggestionStatus.PENDING
      ) {
        allowedNodeIds.push(nodeId);
      }
    });

    return allowedNodeIds.length === 0;
  }, [updatedAcceptedSuggestions, currentTargetNodeIds, currentSourceNodeIds]);

  const displayedCommentButton = !!(
    updatedAcceptedSuggestions &&
    currentTargetNodeIds.length === 1 &&
    targetNodeIdSuggestionMap[currentTargetNodeIds[0]]
  );

  const setTargetElement = (text = '', nodeId: string | null = null) => {
    if (!nodeId) return;
    const element = getElement(editor1Ref, nodeId);
    if (element) {
      const tdElement = element.closest('td');
      tdElement?.setAttribute('data-nodeid', nodeId);
      if (tdElement) {
        tdElement.innerHTML = text;
      }
    }
  };

  const editMapping = async (
    rejectionFormValues: RejectionFormValues = mappingData,
    showNotification = true,
  ) => {
    const suggestion = targetNodeIdSuggestionMap[currentTargetNodeIds[0]];
    if (suggestion) rejectionFormValues.mappingId = suggestion.id.toString();
    await addRejectionNote
      .mutateAsync({
        ...rejectionFormValues,
        tableNumber: rejectionFormValues.targetTableNumber,
        tableColumn: rejectionFormValues.targetTableColumn,
        tableRow: rejectionFormValues.targetTableRow,
        value: rejectionFormValues.targetValue,
        suggestedChange: rejectionFormValues.suggestedChange,
        sourceDocNumber: String(rejectionFormValues.documentNumber),
        sourceTitle: rejectionFormValues.documentTitle,
        sourceVersion: rejectionFormValues.documentVersion,
        notes: rejectionFormValues.note,
        noteType: 'suggestion',
      })
      .then(() => {
        showNotification && notifySuccess('Successfully added rejection notes');
        resetMappingData();
      });

    if (!updatedAcceptedSuggestions) return;
    receiveSuggestions(false);
  };

  const {
    resetMappingData,
    mappingData,
    onChangeHandler,
    onRejectionFormSubmit,
    onRejectionModalOpen,
    showRejectionSlideOver,
    closeRejectionSlideOver,
    openRejectionSlideOver,
  } = useRejectionNote({
    targetNodeId: currentTargetNodeIds[0],
    sourceNodeId: currentSourceNodeIds[0],
    editMapping,
  });

  const transferValue = () => {
    if (!updatedAcceptedSuggestions) return;

    editMapping(mappingData, false);
    const data = {
      sessionId: sessionId,
      targetNodeId: currentTargetNodeIds[0],
      sourceNodeId: currentSourceNodeIds[0],
      documentId: getCatalogId(editor2Ref, currentSourceNodeIds[0]),
      previousSuggestionId: updatedAcceptedSuggestions[target].id,
      targetValue: getElement(editor2Ref, currentSourceNodeIds[0])?.innerText ?? '',
      order: updatedAcceptedSuggestions[target]?.order ?? updatedAcceptedSuggestions[target].id,
      mapperType: mappingByType.verifier,
    };
    setTargetElement(data.targetValue, data.targetNodeId);
    const sourceDocumentNodeElement = getElement(editor2Ref, previousSourceNodeIds[0]);
    updateElementBackgroundColor(sourceDocumentNodeElement, LIGHT_GRAY_COLOR);
    const previousSuggestions = updatedAcceptedSuggestions;
    saveSuggestion(data)
      .then((res) => {
        setLastSaved(new Date(res.data.lastSavedDate));
        setUpdatedAcceptedSuggestions((prevAcceptedSuggestions: any) => {
          return (
            prevAcceptedSuggestions &&
            prevAcceptedSuggestions.map((acceptedSuggestion: GeneralizationSuggestionWithColor) => {
              if (acceptedSuggestion.targetNodeId === res.data.targetNodeId) {
                return {
                  ...res.data,
                  color: LIGHT_GRAY_COLOR,
                };
              } else {
                return acceptedSuggestion;
              }
            })
          );
        });
      })
      .catch((err) => {
        setTargetElement(previousSuggestions[target].targetValue);
        setUpdatedAcceptedSuggestions(previousSuggestions);
        console.error(err);
      });
    addCommentBubbles();
  };

  const receiveSuggestions = async (status: boolean) => {
    if (!updatedAcceptedSuggestions) {
      return;
    }

    const allowedStatus = status
      ? [suggestionStatus.APPROVED]
      : [suggestionStatus.REJECTED, suggestionStatus.APPROVED];

    const nodeIds: string[] = [];
    currentTargetNodeIds.forEach((nodeId) => {
      if (
        targetNodeIdSuggestionMap[nodeId] &&
        !allowedStatus.includes(targetNodeIdSuggestionMap[nodeId].status)
      ) {
        nodeIds.push(nodeId);
      }
    });

    const suggestionIds = updatedAcceptedSuggestions.map((suggestion) => {
      if (nodeIds.includes(suggestion.targetNodeId)) {
        return suggestion.id;
      }
    });

    const data = {
      multiple: true,
      sessionId: sessionId,
      suggestionIds: suggestionIds,
      status: status ? suggestionStatus.APPROVED : suggestionStatus.REJECTED,
    };

    const color = status ? VERIFICATION_COLORS.ACCEPTED : VERIFICATION_COLORS.REJECTED;
    const previousSuggestions = [...updatedAcceptedSuggestions];

    setUpdatedAcceptedSuggestions((prevAcceptedSuggestions: any) => {
      const updatedSuggestions = prevAcceptedSuggestions.map(
        (suggestion: GeneralizationSuggestionWithColor) => {
          if (nodeIds.includes(suggestion.targetNodeId)) {
            return {
              ...suggestion,
              status: data.status,
              color: color,
            };
          }
          return suggestion;
        },
      );

      return updatedSuggestions;
    });

    try {
      await saveSuggestionStatus(data);
      setLastSaved(new Date());
      const nextNodeId = nextStep(currentTargetNodeIds[0]);
      if (nextNodeId) {
        handleTargetNodeIds([nextNodeId]);
      }
      const fullName = `${firstName} ${lastName}`;
      if (!validators.find((v) => v === `${firstName} ${lastName}`)) {
        setValidators((prev) => [...prev, fullName]);
      }
    } catch (err: any) {
      notifyError(err.message);
      setUpdatedAcceptedSuggestions([...previousSuggestions]);
    }
  };

  const getElementObjects = (nodeIds: string[], highlightColor = '') => {
    return Array.from(nodeIds, (nodeId) => ({
      id: nodeId,
      color: highlightColor,
    }));
  };

  const highlightNodes = (
    editorRef: any,
    nodeIds: string[],
    highlightColor: string | null = null,
  ) => {
    const matchedSuggestion = updatedAcceptedSuggestions?.find((suggestion) =>
      nodeIds.includes(suggestion.targetNodeId),
    );

    const elementObjects = getElementObjects(nodeIds, matchedSuggestion?.color);

    highlightSuggestions(editorRef, elementObjects, highlightColor);
  };

  const highlightPreviousNode = (editorRef: any, previousElement: HTMLElement | null) => {
    const previousElementNodeIds: string[] = getAllNodesIdsInElement(previousElement);

    if (!updatedAcceptedSuggestions || !previousElementNodeIds) {
      return;
    }

    highlightNodes(editorRef, previousElementNodeIds);
  };

  const highlightCurrentNode = (editorRef: any, currentElement: HTMLElement | null) => {
    const elementNodeIds: string[] = getAllNodesIdsInElement(currentElement);

    highlightNodes(editorRef, elementNodeIds, VERIFICATION_COLORS.SELECTED);
  };

  const commentsHTML = () => {
    if (isCommentDisplayed) {
      const commentsHtml = mappingComments
        .map((comment) => {
          return `<p>${
            comment?.firstName + ' - ' + comment?.firstName.slice(0, 1) + comment.commentNumber
          }: ${comment.comment}</p>`;
        })
        .join(' ');
      return `<div style="margin-top: 5rem;">${commentsHtml}</div>`;
    } else {
      return '';
    }
  };

  const jumpToNextPendingCell = () => {
    const nodeId = jumpNext('next');
    if (nodeId) {
      updateDVNavigationIndex(nodeId);
      handleTargetNodeIds([nodeId]);
    }
  };

  const goToTargetCell = (id: string) => {
    handleTargetNodeIds([id]);
  };

  const handleTargetNodeIds = (ids: string[]) => {
    setCurrentTargetNodeIds((prev) => {
      setPreviousTargetNodeIds(prev);
      return ids;
    });
  };

  const handleSourceNodeIds = (ids: string[]) => {
    setCurrentSourceNodeIds((prev) => {
      setPreviousSourceNodeIds(prev);
      return ids;
    });
  };

  const goToSourceCell = (id: string) => {
    handleSourceNodeIds([id]);
  };

  const sourceNodeIds = (sourceId: string): string[] | undefined => {
    const currentTemplateNodeSuggestions =
      acceptedSuggestions &&
      acceptedSuggestions.filter(
        (suggestion) => suggestion.targetNodeId === currentTargetNodeIds[0],
      );
    if (currentTemplateNodeSuggestions?.length)
      return currentTemplateNodeSuggestions.map((suggestion) => suggestion.sourceNodeId);
    return [sourceId];
  };

  useEffect(() => {
    previousTargetNodeIds.forEach((previousTargetNodeId) => {
      const element = getElement(editor1Ref, previousTargetNodeId);
      const cellElement = element && getCellElement(element, editor1Ref);
      highlightPreviousNode(editor1Ref, cellElement ?? element);
    });

    currentTargetNodeIds.forEach((currentTargetNodeId) => {
      const element = getElement(editor1Ref, currentTargetNodeId);
      const cellElement = element && getCellElement(element, editor1Ref);
      highlightCurrentNode(editor1Ref, cellElement ?? element);
    });
  }, [currentTargetNodeIds]);

  useEffect(() => {
    if (currentTargetNodeIds.length > 0 && currentSourceNodeIds.length > 0) {
      setIsDisabledApprove(false);
    }
  }, [currentTargetNodeIds, currentSourceNodeIds]);

  useEffect(() => {
    if (!!acceptedSuggestions && acceptedSuggestions.length > 0) {
      setHasNoResponse(false);

      const coloredSuggestions = addColorToObjects(
        acceptedSuggestions,
        LIGHT_GRAY_COLOR,
      ) as GeneralizationSuggestionWithColor[];
      setUpdatedAcceptedSuggestions(coloredSuggestions);
    }
  }, [acceptedSuggestions]);

  const memoizedCatalogWithSourceIds = useMemo(() => {
    return (
      updatedAcceptedSuggestions &&
      getCatalogWithSourceIdsFromSuggestions(updatedAcceptedSuggestions)
    );
  }, [updatedAcceptedSuggestions]);

  useEffect(() => {
    if (!loadingGeneratedDocument) {
      setEditor1Content({
        html: generatedDocument ? generatedDocument.html : '',
      });
    }
  }, [generatedDocument]);

  useEffect(() => {
    if (sessionId) {
      fetchMappingSessionComments(sessionId)
        .then((res) => {
          setMappingComments([...res.data]);
        })
        .catch((err) => console.error(err));
    }
  }, [sessionId]);

  useEffect(() => {
    if (!loadingGeneratedDocument && !loadingSourceDocument) {
      setIsLoading(false);
    }
  }, [loadingGeneratedDocument, loadingSourceDocument]);

  useEffect(() => {
    if (!updatedAcceptedSuggestions || currentTargetNodeIds.length !== 1) {
      return;
    }

    const targetIndex = updatedAcceptedSuggestions.findIndex(
      (suggestion) => suggestion.targetNodeId === currentTargetNodeIds[0],
    );
    setTarget(targetIndex);
    const currentSuggestion = targetNodeIdSuggestionMap[currentTargetNodeIds[0]];
    if (currentSuggestion) {
      setNodeValue(`${getElement(editor1Ref, currentSuggestion.targetNodeId)?.innerText}`);
      setNodeStatus(currentSuggestion.status);
      handleSourceNodeIds(sourceNodeIds(currentSuggestion.sourceNodeId) ?? []);
    }
  }, [currentTargetNodeIds]);

  useEffect(() => {
    const prevSourceEle = previousSourceNodeIds.map((nodeId) => getElement(editor2Ref, nodeId));
    const currSourceEle = currentSourceNodeIds.map((nodeId) => getElement(editor2Ref, nodeId));

    const catId = isSearched
      ? searchResult.find((s) => s.dataNodeId === currentSourceNodeIds[0])?.catalog_id
      : sourceNodeIdSuggestionMap[currentSourceNodeIds[0]]?.sourceFileId;

    if (catId && selectedCatalogId !== +catId) {
      handleSourcechange(catId.toString());
    }

    if (!!memoizedCatalogWithSourceIds && acceptedSuggestions) {
      highlightNode(editor2Ref, prevSourceEle, currSourceEle, 'sourceNodeId');
      handleSvgPinActivation(editor2Ref, currentTargetNodeIds.at(0));
    }
  }, [currentSourceNodeIds]);

  useEffect(() => {
    const handleSourceDocumentClick = (event: { target: any }) => {
      const target = event.target;
      const cellElement = getCellElement(target, editor2Ref.current.getBody());
      if (cellElement && cellElement.hasAttribute('data-nodeid')) {
        handleSourceNodeIds([target.getAttribute('data-nodeid')] ?? []);
      }
    };

    const currentEditor2 = editor2Ref.current;
    if (currentEditor2) {
      currentEditor2.on('click', handleSourceDocumentClick);
    }

    return () => {
      if (currentEditor2) {
        currentEditor2.off('click', handleSourceDocumentClick);
      }
    };
  }, [editor2Ref.current]);

  useEffect(() => {
    if (currentSourceNodeIds?.length > 0) {
      highlightActiveNode(editor2Ref, currentSourceNodeIds, previousSourceNodeIds);
      handleSvgPinActivation(editor2Ref, currentTargetNodeIds.at(0));
    }
  }, [sourceFileDoc]);

  useEffect(() => {
    if (editor1Ref?.current?.getDoc()) {
      const editorDocs = editor1Ref.current.getDoc();
      editorDocs.body.innerHTML = editor1Content.html + commentsHTML();
      highlightAllTargetSuggestions(editor1Ref);
      currentTargetNodeIds.length && handleTargetNodeIds([currentTargetNodeIds[0]]);
    }
  }, [isCommentDisplayed, mappingComments, updatedAcceptedSuggestions]);

  return {
    isLoading,
    isDisable,
    hasNoResponse,
    isDisabledApprove,
    setIsDisable,
    nextSuggestions,
    prevSuggestions,
    isNextAvailable,
    isPreviousAvailable,
    highlightAllTargetSuggestions,
    highlightAllSourceDocumentSuggestions,
    onRejectionModalOpen,
    disableApprove,
    mappingData,
    onChangeHandler,
    onRejectionFormSubmit,
    editMapping,
    receiveSuggestions,
    currentSuggestionId,
    displayedCommentButton,
    targetOnClickHandler,
    disableReject,
    disableTransferModal,
    updatedAcceptedSuggestions,
    nodeValue,
    nodeStatus,
    jumpToNextPendingCell,
    goToTargetCell,
    goToSourceCell,
    currentTargetNodeIds,
    transferValue,
    commentModal,
    openCommentModalWithThread,
    setSearchResult,
    searchResult,
    setIsSearched,
    validatedCells,
    setCurrentTargetNodeIds,
    setPreviousTargetNodeIds,
    navigateToDVCell,
    isPreviousDVCellAvailable,
    isNextDVCellAvailable,
    updateDVJumpIndex,
    showRejectionSlideOver,
    closeRejectionSlideOver,
    openRejectionSlideOver,
  };
};
