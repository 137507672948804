import { useEffect, useState } from 'react';

const DEFAULT_VALUE = '{"history":[]}';

export const useHistory = <T>(initialState: T[], key = 'history') => {
  const [history, setHistory] = useState<T[]>(initialState);
  const [index, setIndex] = useState<number>(initialState.length - 1);

  useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem(key) || DEFAULT_VALUE);
    if (savedState && savedState.history.length > 0) {
      setHistory(savedState.history);
      setIndex(savedState.index);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify({ history, index }));
  }, [history, key, index]);

  const isUndoAvailable = index > -1;
  const isRedoAvailable = index < history.length - 1;

  const undo = (): { current: T; previous: T } | null => {
    if (isUndoAvailable) {
      setIndex((prev) => prev - 1);
      return { current: history[index], previous: history[index - 1] };
    } else {
      return null;
    }
  };

  const redo = (): { current: T; next: T } | null => {
    if (isRedoAvailable) {
      setIndex((prev) => prev + 1);
      return { current: history[index], next: history[index + 1] };
    } else return null;
  };

  const addHistory = (newValues: T[]) => {
    setIndex((prev) => {
      setHistory((previousHistory) => {
        const newHistory = [...previousHistory.slice(0, prev + 1), ...newValues];
        return newHistory;
      });
      return prev + newValues.length;
    });
  };

  return {
    history,
    addHistory,
    isUndoAvailable,
    isRedoAvailable,
    undo,
    redo,
    setHistory,
  };
};
