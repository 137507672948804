// React-related imports
import { FC } from 'react';

// Components
import { Modal } from '@gloabal-regulatory-writing-consulting/gxt-components';
import TinyEditor from '../../../../../components/TinyEditor';
import SvgIcon from '../../../../../components/elements/SvgIcon';

// External libraries
import { useQuery } from '@tanstack/react-query';

// Types
import { GetSourceFilesResponseItem } from '../../../../../services/api/types';
import { ITinyMceModal } from './TinyMceModal.types';

// API services
import { fetchSourceFiles } from '../../../../../services/api';

const TinyMceModal: FC<ITinyMceModal> = ({
  editorModalOpen,
  closeEditorModalOpen,
  editorType,
  catalogId = 0,
  isFileInteractive,
}) => {
  const fetchCatalog = async (): Promise<GetSourceFilesResponseItem> => {
    if (!catalogId) return {} as GetSourceFilesResponseItem;

    const { data } = await fetchSourceFiles([catalogId]);
    return data[0];
  };

  const { data } = useQuery<GetSourceFilesResponseItem, Error>({
    queryKey: [catalogId],
    queryFn: fetchCatalog,
    enabled: !!catalogId,
  });

  return (
    <Modal
      isOpen={editorModalOpen}
      maxWidth="64rem"
      overlay={true}
      onClose={closeEditorModalOpen}
      data-testid="editor-modal">
      <Modal.ModalHeader className="w-[900px]">
        <div className="flex flex-col items-start gap-6">
          <div className="flex justify-between items-start self-stretch">
            <h2 className="text-[1.75rem] text-primary-200 font-bold">{data?.filename}</h2>
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={closeEditorModalOpen}
              data-testid="editor-modal-close-button">
              <SvgIcon iconType="close" />
            </button>
          </div>
          <h3 className="text-neutral-300 text-lg font-bold">
            Document Type:
            <span className="text-neutral-300 text-lg font-bold">
              {isFileInteractive ? ' Interactive format' : ' Image view only'}
            </span>
          </h3>
        </div>
      </Modal.ModalHeader>
      <Modal.ModalHeaderBody className="w-full">
        <TinyEditor
          editorType={editorType}
          disabled={true}
          content={data?.html}
          toolBar={false}
          menuBar={false}
        />
      </Modal.ModalHeaderBody>
    </Modal>
  );
};

export default TinyMceModal;
