// React-related imports
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

// React Hook Form imports
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

// Component imports
import {
  Input,
  SlideOverFilters,
  TagsField,
} from '@gloabal-regulatory-writing-consulting/gxt-components';
import { InputError } from '../../../../components/inputError';

// Type and utility imports
import { CreateProjectFormProps, CreateProjectFormValues } from './CreateNewProjectForm.types';
import { inputList } from './utils';

// Services and API's imports
import { ItemType } from '../../../../services/api/types';
import { getSubgroupsCatalogItems } from '../../../../services/api';

// Hook imports
import useModal from '../../../../hooks/useModal';
import { getAllCatalogItems } from '../../../settings/components/utils/apiHelpers';
import { useQuery } from '@tanstack/react-query';
import { RolesSlideOver } from '../../../createUser/components/RolesSlideOver';
import {
  mapSelectedGroupsToOptions,
  mapSelectedSubGroupsToOptions,
  mapSubgroupsToOptions,
} from './utils/helper';

const CreateProjectForm = forwardRef(
  ({ handleSubmitData, defaultValues }: CreateProjectFormProps, ref) => {
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
    const [selectedSubGroups, setSelectedSubGroups] = useState<number[]>([]);

    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<CreateProjectFormValues>({
      defaultValues,
      mode: 'onChange',
    });

    const onSubmit: SubmitHandler<CreateProjectFormValues> = (data: CreateProjectFormValues) => {
      const formDataWithGroups = {
        ...data,
        groupIds: selectedGroups,
        subGroupIds: selectedSubGroups,
      };
      handleSubmitData(formDataWithGroups);
    };

    const [groupsSlideOver, subGroupsSlideOver] = [useModal(), useModal()];

    const { data: groups, isLoading: groupsLoading } = useQuery({
      queryKey: [ItemType.Group],
      queryFn: () => getAllCatalogItems(ItemType.Group),
      placeholderData: (previousData) => previousData,
    });

    const { data: subGroups, isLoading: subGroupsLoading } = useQuery({
      queryKey: [ItemType.Subgroup, selectedGroups],
      queryFn: () => getSubgroupsCatalogItems(selectedGroups),
      placeholderData: (previousData) => previousData,
      enabled: selectedGroups.length > 0,
    });

    const selectedGroupsOptions = useMemo(() => {
      return mapSelectedGroupsToOptions(selectedGroups, groups ?? []);
    }, [selectedGroups, groups]);

    const selectedSubGroupsOptions = useMemo(() => {
      return mapSelectedSubGroupsToOptions(subGroups?.data as any, selectedSubGroups);
    }, [subGroups, selectedSubGroups]);

    const filtersOptionsData = useMemo(
      () =>
        mapSubgroupsToOptions(
          subGroups?.data as any,
          groups ?? [],
          selectedSubGroups,
          setSelectedSubGroups,
        ),
      [subGroups, groups, selectedSubGroups],
    );

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues, reset]);

    return (
      <>
        <form className="flex flex-col items-start gap-6 flex-1 flex-shrink-0 basis-0">
          {inputList.map((input) => (
            <Controller
              key={input.name}
              {...input}
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id={input.name}
                    data-testId={input.name}
                    label={input.label}
                    placeholder={input.placeholder}
                    isFilled={!!field.value}
                    value={field.value?.toString()}
                    customStyles={{
                      container: { width: '23.75rem' },
                    }}
                    error={!!errors[input.name]?.message}
                    helpText={<InputError errors={errors} field={input.name} />}
                  />
                </>
              )}
            />
          ))}
          <TagsField
            value={selectedGroupsOptions}
            onClick={groupsSlideOver.openModal}
            disabled={groupsLoading}
            label="Group*"
            className="w-[23.75rem]"
            placeholder="Click to select"
          />
          <TagsField
            value={selectedSubGroupsOptions}
            onClick={subGroupsSlideOver.openModal}
            disabled={!selectedGroups.length || subGroupsLoading}
            label="Subgroup*"
            className="w-[23.75rem]"
          />
        </form>
        {groupsSlideOver.show && !groupsLoading && (
          <RolesSlideOver
            isOpen={groupsSlideOver.show}
            heading="Assign Groups"
            onClose={groupsSlideOver.closeModal}
            handleSave={(selectedgroups) => {
              setSelectedGroups(selectedgroups);
              groupsSlideOver.closeModal();
            }}
            onCloseSlideOver={groupsSlideOver.closeModal}
            selectedRoles={selectedGroups}
            allRoles={Object.values(groups ?? {})}
          />
        )}
        {subGroupsSlideOver.show && (
          <SlideOverFilters
            isOpen={subGroupsSlideOver.show}
            onCloseHandler={subGroupsSlideOver.closeModal}
            onApplyHandler={() => subGroupsSlideOver.closeModal()}
            onResetHandler={() => {}}
            filtersOptions={filtersOptionsData}
            applyButtonLabel="Add"
            closeButtonLabel="Close"
            resetButtonLabel="Clear Selection"
            title="Assign Subgroups"
          />
        )}
      </>
    );
  },
);

CreateProjectForm.displayName = 'CreateProjectForm';

export default CreateProjectForm;
