import { SourceFileGroup } from '../../upload';

export const generateCatalogTemplatesIdsList = (data: SourceFileGroup[]) => {
  return JSON.stringify(data.map((sectionItem: SourceFileGroup) => sectionItem.id));
};

export const generateTagsFieldOptions = (data: SourceFileGroup[]) => {
  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};
