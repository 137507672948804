import { useEffect, useState } from 'react';

import Button from './Button';
import ModalInputFields from '../modals/modalInputFields';
import Modal from 'react-modal';
import { editMappingSession } from '../../services/apiCalls';
import { modalCustomStyles } from '../../constants';
import { DOCUMENT_VERSION_PATTERN } from '../../constants/catalog';
import { notifyError } from '../../helpers/utils';

const EditGeneralizationDoc = ({ handleClose, show, automatedDocData, refreshData }) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({ ...automatedDocData });
  }, [automatedDocData]);

  const fileNameModalInputList = [
    {
      label: 'Automated Document Title',
      placeholder: 'Enter title',
      type: 'text',
      name: 'title',
      inputClassName: 'w-full',
      disabled: false,
      value: formData?.title,
    },
    {
      label: 'Content Specifics',
      placeholder: 'Enter content specifics',
      type: 'text',
      name: 'contentSpecifics',
      inputClassName: 'w-full',
      disabled: false,
      value: formData?.contentSpecifics,
    },
    {
      label: 'Created By',
      placeholder: 'Created by',
      type: 'text',
      name: 'createdBy',
      inputClassName: 'w-full bg-gray-100',
      disabled: true,
      value: formData?.createdBy?.firstName,
    },
    {
      label: 'Created time',
      placeholder: 'Created time',
      type: 'text',
      name: 'createdTime',
      inputClassName: 'w-full bg-gray-100',
      disabled: true,
      value: formData?.createdAt,
    },
    {
      label: 'Number of Suggestions',
      placeholder: 'Number of Suggestions',
      type: 'number',
      name: 'numOfSuggestions',
      inputClassName: 'w-full bg-gray-100',
      disabled: true,
      value: formData?.totalSuggestionsCount,
    },
    {
      label: 'CTD Section',
      placeholder: 'Enter category',
      type: 'text',
      name: 'ctdSection',
      inputClassName: 'w-full',
      disabled: false,
      value: formData?.ctdSection,
    },
    {
      label: 'Program',
      placeholder: 'Enter program',
      type: 'text',
      name: 'program',
      inputClassName: 'w-full',
      disabled: false,
      value: formData?.program,
    },
    {
      label: 'Version',
      placeholder: '1.0',
      type: 'number',
      name: 'version',
      inputClassName: `w-full ${errors.version ? 'border-reject_color' : ''}`,
      disabled: false,
      value: formData?.version,
    },
    {
      label: 'Review status',
      placeholder: 'Review status',
      type: 'text',
      name: 'reveiwStatus',
      inputClassName: 'w-full bg-gray-100',
      disabled: true,
      value: formData?.reveiwStatus ? formData?.reveiwStatus : 'Done',
    },
    {
      label: 'Notes on automated document',
      placeholder: 'Notes on automated document',
      type: 'text',
      name: 'detailedNotes',
      inputClassName: 'w-full',
      disabled: false,
      value: formData?.detailedNotes,
    },
  ];

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    name === 'version' && !DOCUMENT_VERSION_PATTERN.test(value)
      ? setErrors({
          ...errors,
          version: 'Invalid format. Version should be up to 2 decimal places.',
        })
      : setErrors({ ...errors, version: false });
  };

  const validateForm = (data) => {
    const newObj = Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        acc[key] = true;
      }
      return acc;
    }, {});
    return newObj;
  };

  const updateGeneralizationHandler = async () => {
    if (!DOCUMENT_VERSION_PATTERN.test(formData?.version)) {
      notifyError('Enter a valid Document version!');
      return;
    }
    const data = {
      id: formData?.id,
      title: formData?.title,
      contentSpecifics: formData?.contentSpecifics,
      ctdSection: formData?.ctdSection,
      program: formData?.program,
      version: formData?.version,
      detailedNotes: formData?.detailedNotes,
    };

    const result = validateForm(data);
    if (Object.keys(result).length > 0) {
      return setErrors(result);
    }
    setErrors({});
    await editMappingSession(formData);
    refreshData();
    setFormData({});
    handleClose();
  };

  return (
    <Modal isOpen={show} onRequestClose={handleClose} style={modalCustomStyles} ariaHideApp={false}>
      <h1 className="font-bold text-center p-4 text-xl">Edit Generalization</h1>
      <div className="space-y-8 sm:p-8">
        <div className="grid grid-cols-2 gap-5">
          <ModalInputFields
            inputList={fileNameModalInputList}
            errors={errors}
            onChangeHandler={onChangeHandler}
          />
        </div>
        <div className="flex justify-between">
          <Button
            data-testid="cancelDelete"
            onClick={handleClose}
            title="Cancel"
            className={`text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-400`}
          />
          <Button
            data-testid="deleteItem"
            onClick={updateGeneralizationHandler}
            title="Update"
            className={`text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-secondary_bg_color `}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditGeneralizationDoc;
