import { useMemo } from 'react';

import PrivateRoutes from './routes/PrivateRoutes';
import PublicRoutes from './routes/PublicRoutes';
import './App.css';
import { useAuth } from './contexts/AuthContext';
import { Auth } from 'aws-amplify';
import { AWS_CONFIG_AUTH } from './constants';
import Loading from './components/Loading';

Auth.configure(AWS_CONFIG_AUTH);

export const RenderRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth();

  const renderRoutes = useMemo(() => {
    if (isLoading) {
      return <Loading backgroundColor="bg-zinc-200" type="spin" />;
    }
    return isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />;
  }, [isAuthenticated, isLoading]);

  return renderRoutes;
};

export default RenderRoutes;
