import {
  getUserDataFromLocalStorage,
  getUserGroupsFromLocalStorage,
} from '../helpers/localStorage';

const useClientInfo = () => {
  const clientInfo = getUserDataFromLocalStorage();
  const userRoles = getUserGroupsFromLocalStorage();

  return {
    isAdmin: userRoles?.includes('admin') || userRoles?.includes('globalAdmin'),
    isWriter: userRoles?.includes('writer'),
    isReviewer: userRoles?.includes('reviewer'),
    isGlobalAdmin: userRoles?.includes('globalAdmin'),
    firstName: clientInfo?.firstName,
    lastName: clientInfo?.lastName,
    imageUrl: clientInfo?.imageUrl,
    userId: clientInfo?.userId,
    email: clientInfo?.email,
    permissions: clientInfo?.permissions,
    allRoles: clientInfo?.allRoles,
    token: clientInfo?.token,
    username: clientInfo?.userName,
    avatar: clientInfo ? clientInfo['custom:avatar'] || clientInfo['avatar'] : '',
  };
};

export default useClientInfo;
