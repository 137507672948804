import React, { ChangeEvent, useEffect } from 'react';
import { useState } from 'react';
import Input from './elements/Input';
import { useDebounce } from '../hooks/useDebounce';
import { twMerge } from 'tailwind-merge';

interface SearchProps {
  id?: string;
  name?: string;
  onChangeCallback: (searchTem: string) => unknown;
  className?: string;
}

export const Search: React.FC<SearchProps> = ({ id, name, onChangeCallback, className }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    onChangeCallback(debouncedTerm);
  }, [debouncedTerm]);

  return (
    <div className="search w-full">
      <Input
        type="text"
        id={id || 'search'}
        name={name || 'search'}
        label="Search"
        placeholder="Search"
        dataTestId="search"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setSearchTerm((e.target as HTMLInputElement)?.value)
        }
        value={searchTerm}
        className={twMerge('outline-primary_bg_color w-auto sm:w-full', className)}
      />
    </div>
  );
};
