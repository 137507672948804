// React related imports
import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';

// Components
import {
  Button,
  Header,
  Search,
  SlideOverFilters,
} from '@gloabal-regulatory-writing-consulting/gxt-components';
import Layout from '../../components/layout';
import SvgIcon from '../../components/elements/SvgIcon';
import { PaginatedTable } from '../../components/PaginatedTable';
import NonInteractiveDocumentsModal from '../../components/modals/NonInteractiveDocumentsModal';
import StartAuthoringModal from './components/modals/AuthoringModal/StartAuthoringModal';

// Hooks and Contexts
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';
import useModal from '../../hooks/useModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useStartAuthoring } from '../authoring/hooks/useStartAuthoring';
import { GeneralizationContext } from '../../contexts/GeneralizationContext';

// Services and APIs
import {
  getCatalogs,
  getMappingSessionSourceFiles,
  useMappingSessionAPI,
} from '../../services/api';

// Utilities
import defaultFilters from '../catalog/util/defaultCatalogTabsFilters';
import { generateColumnsByTab } from '../catalog/components/CatalogTable/utils/catalogColumns';
import { getFilterFetcher, mapToFilterOptions } from '../catalog/util/helpers';
import { notifyError } from '../../helpers/utils';
import { AddSourcesPreviousAction } from '../catalog/util/constants';
import { mergeDynamicSuggestions } from './utils/helpers';

// Types
import { DocumentTabs, ICategory, IGroupingVariables, ISourceDocument } from '../../types';
import { CatalogFilterParams, CatalogSortParams } from '../../services/api/types';

const AddSource = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sessionId, templateType, previousAction, dynamicMappingPayload } = location.state || {};
  const queryKeyValue = [
    previousAction === AddSourcesPreviousAction.DYNAMIC_MAPPING ? 'DYNAMIC_MAPPING' : 'SOURCE',
  ];
  if (!sessionId) {
    notifyError('Invalid URL');
    console.error('Missing session id');
    navigate('/catalog');
  }

  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters(DocumentTabs.SOURCE));
  const [nonInteractiveDocuments, setNonInteractiveDocuments] = useState<ISourceDocument[]>([]);

  const {
    filters,
    setSearchByKey,
    paginatedFilteredData,
    appliedFiltersCount,
    handlePageChange,
    handlePerPageChange,
    setFilters,
    setAppliedFiltersCount,
    handleColumnSort,
  } = usePaginatedFilters<CatalogFilterParams, CatalogSortParams>({
    filters: defaultFilters(DocumentTabs.SOURCE),
    search: {
      query: '',
      columns: ['id', 'title'],
    },
    sort: { type: 'DESC', column: 'id' },
  });

  const slideOverFilters = useModal();
  const {
    show: showAuthoringModal,
    closeModal: closeAuthoringModal,
    openModal: openAuthoringModal,
  } = useModal();

  const {
    show: showNonInteractiveDocumentModal,
    closeModal: closeNonInteractiveDocumentModal,
    openModal: openNonInteractiveDocumentModal,
  } = useModal();

  const { setAiResponse, setAiResponseCopy, setGVCategories, sanitizeAiResponse } =
    useContext(GeneralizationContext);

  const { addSourceFile, dynamicMappingSuggestions } = useMappingSessionAPI();
  const { mutateAsync: getDynamicSuggestions } = dynamicMappingSuggestions;

  const numSelectedRecords = useMemo(() => {
    return selectedRecords.map((id) => +id);
  }, [selectedRecords]);

  const { handleStartAuthoring, isPending } = useStartAuthoring({
    selectedRecords: numSelectedRecords,
    sessionId,
    openNonInteractiveDocumentModal,
    templateType,
    closeAuthoringModal,
    nonInteractiveDocuments,
  });

  const handleSelectAll = useCallback((allChecked: boolean, records: ISourceDocument[]) => {
    if (allChecked) {
      setSelectedRecords([]);
      setNonInteractiveDocuments([]);
    } else {
      setSelectedRecords(records.map((item) => String(item.id)));
      setNonInteractiveDocuments(records.filter((item) => !item.isInteractive));
    }
  }, []);

  const handleSelect = (event: ChangeEvent<HTMLInputElement>, record: ISourceDocument) => {
    if (event.target.checked) {
      setSelectedRecords((prev) => [...prev, event.target.value]);
      !record.isInteractive && setNonInteractiveDocuments((prev) => [...prev, record]);
    } else {
      setSelectedRecords((prev) => prev.filter((id) => id !== event.target.value));
      !record.isInteractive &&
        setNonInteractiveDocuments((prev) => prev.filter((doc) => doc.id !== +event.target.value));
    }
  };

  const sortedColumn = useMemo(
    () => ({
      column: paginatedFilteredData.sort?.column || '',
      order: paginatedFilteredData.sort?.type || 'DESC',
    }),
    [paginatedFilteredData.sort?.column, paginatedFilteredData.sort?.type],
  );

  const columns = useMemo(
    () => generateColumnsByTab(DocumentTabs.SOURCE, handleColumnSort, sortedColumn),
    [sortedColumn],
  );

  const getFilteredPaginatedCatalogs = () => {
    switch (previousAction) {
      case AddSourcesPreviousAction.DYNAMIC_MAPPING:
        return getMappingSessionSourceFiles(paginatedFilteredData, sessionId);
      default:
        return getCatalogs(paginatedFilteredData);
    }
  };

  const navigateToSession = () => navigate(`/mapping-sessions/${sessionId}`);

  const handleAddSource = () => {
    addSourceFile
      .mutateAsync({
        sourceFileIds: selectedRecords.map((id) => Number(id)),
        sessionId,
      })
      .then(() => navigateToSession());
  };

  const fetchFilterOptions = async () => {
    try {
      const fetchFilterOptionsFn = getFilterFetcher(DocumentTabs.SOURCE);

      const { data } = await fetchFilterOptionsFn();
      return data;
    } catch (error) {
      console.error('Error fetching filterOptions data:', error);
      throw error;
    }
  };

  const filtersQuery = useQuery({
    queryFn: fetchFilterOptions,
    queryKey: ['SourceFilters'],
  });

  const handleApplyFilters = () => {
    setFilters(appliedFilters);

    const appliedFiltersCount = Object.values(filters || {}).reduce((sum: number, value) => {
      if (Array.isArray(value) && value.length) {
        return sum + 1;
      }
      return sum;
    }, 0);

    setAppliedFiltersCount(appliedFiltersCount);
    slideOverFilters.closeModal();
  };

  const handleResetFilters = () => {
    setAppliedFilters(defaultFilters(DocumentTabs.SOURCE));
  };

  const handleCloseFilters = () => {
    slideOverFilters.closeModal();
    setAppliedFilters(filters);
  };

  const filtersOptionsData = useMemo(() => {
    return mapToFilterOptions(filtersQuery.data, (filters) => {
      setAppliedFilters(filters);
    });
  }, [filtersQuery.data]);

  const handleRun = () => {
    getDynamicSuggestions({
      sessionId,
      newTargetNodeId: dynamicMappingPayload.targetSelectionNodeId,
      suggestionId: dynamicMappingPayload.referenceSelectionSuggestionId,
      sourceIds: selectedRecords.map((id) => Number(id)),
    }).then((res) => {
      const groupingVariables: IGroupingVariables = res.data.groupingVariables;
      setGVCategories((prev: ICategory[]) =>
        prev.map((item) => {
          const additionalVariables = groupingVariables[item.title] || [];
          return {
            ...item,
            options: [...item.options, ...additionalVariables],
          };
        }),
      );
      const sanitizedAIResponse = sanitizeAiResponse(res.data.aiSuggestions);
      setAiResponse((prev) => mergeDynamicSuggestions(prev, sanitizedAIResponse));
      setAiResponseCopy((prev) => mergeDynamicSuggestions(prev, sanitizedAIResponse));
    });

    navigate(`/mapping-sessions/${sessionId}`, {
      state: { targetNodeId: dynamicMappingPayload.targetSelectionNodeId },
    });
  };

  const actionButtonProps = useMemo(() => {
    switch (previousAction) {
      case AddSourcesPreviousAction.DYNAMIC_MAPPING:
        return {
          disabled: !selectedRecords.length,
          onClick: handleRun,
          label: 'Run',
          dataTestId: 'run-btn',
        };
      case AddSourcesPreviousAction.AUTHORING_REQUEST:
        return {
          disabled: !selectedRecords.length || isPending,
          onClick: openAuthoringModal,
          label: 'Continue',
          dataTestId: 'continue-btn',
        };
      default:
        return {
          disabled: !selectedRecords.length || addSourceFile.isPending,
          onClick: handleAddSource,
          label: 'Add',
          dataTestId: 'add-btn',
        };
    }
  }, [selectedRecords]);

  return (
    <Layout>
      <Layout.Header>
        <Header>
          <Header.Heading>Add Source Document(s)</Header.Heading>
        </Header>
      </Layout.Header>
      <Layout.Body>
        <div className="flex w-full gap-2">
          <Search
            onChangeCallback={(term) => {
              setSearchByKey('query', term);
            }}
            width="100%"
            className="h-full"
          />
          <Button
            variant="secondary"
            onClick={slideOverFilters.openModal}
            data-testid="filter-btn"
            className="flex justify-between items-center gap-3 whitespace-nowrap">
            {`Filter (${appliedFiltersCount})`}
            <SvgIcon
              date-testid={'filter-icon-svg'}
              iconType={'filter-icon'}
              stroke={addSourceFile.isPending ? 'var(--neutral-200, #9CA3AF)' : undefined}
            />
          </Button>
        </div>
        <PaginatedTable
          additionalColumns={columns}
          paginatedFilteredData={paginatedFilteredData}
          getData={getFilteredPaginatedCatalogs as any}
          handleSelectAll={handleSelectAll}
          onSelect={handleSelect}
          selectedData={selectedRecords}
          handlePageChange={handlePageChange}
          handlePerPageChange={handlePerPageChange}
          queryKey={queryKeyValue}
        />
        <div className="flex justify-end items-start self-stretch">
          <div className="flex justify-end items-start gap-3">
            <Button
              data-testid={actionButtonProps.dataTestId}
              variant="primary"
              disabled={actionButtonProps.disabled}
              onClick={actionButtonProps.onClick}>
              {actionButtonProps.label}
            </Button>
            <Button
              data-testid="navigate-cancel"
              variant="secondary"
              onClick={
                templateType
                  ? () => navigate('/catalog')
                  : () => navigate(`/mapping-sessions/${sessionId}`)
              }>
              Cancel
            </Button>
          </div>
        </div>
        <SlideOverFilters
          isOpen={slideOverFilters.show}
          onCloseHandler={handleCloseFilters}
          onApplyHandler={handleApplyFilters}
          onResetHandler={handleResetFilters}
          filtersOptions={filtersOptionsData}
          applyButtonLabel="Filter"
          closeButtonLabel="Close"
          resetButtonLabel="Clear Selection"
          title="Filters Templates"
        />
        <StartAuthoringModal
          isOpen={showAuthoringModal}
          handleClose={closeAuthoringModal}
          handleAuthoringSubmit={handleStartAuthoring}
        />
        <NonInteractiveDocumentsModal
          isOpen={showNonInteractiveDocumentModal}
          closeModal={closeNonInteractiveDocumentModal}
          documents={nonInteractiveDocuments}
        />
      </Layout.Body>
    </Layout>
  );
};

export default AddSource;
