import { useState, createContext, FC, ReactNode, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';

import { MappingContext } from './MappingContext';
import { acceptAutoMappingSuggestion, fetchSourceFiles, search } from '../services/apiCalls';
import { getElement, getElementsByQuery, getCellElement } from '../helpers/generalizationHelpers';
import {
  IAutoMappingContext,
  SourceNode,
  IAllSourceFiles,
  AutoMappingLoading,
  SearchResult,
} from '../types';
import React from 'react';
import { SearchThreshold } from '../constants';

const defaultCatalogContext: IAutoMappingContext = {
  currentTargetNodeId: 0,
  setCurrentTargetNodeId: () => {},
  currentSourceNodeId: '',
  setCurrentSourceNodeId: () => {},
  scrollTarget: () => {},
  handleApproveState: (_state: boolean) => {},
  sourceContent: {
    filename: '',
    html: '',
    id: 0,
  },
  setSourceContent: () => {},
  handleHighlight: () => {},
  handleSuggestionClick: (_element: SourceNode, _index: number) => {},
  handleSuggestionSelected: (_element: SourceNode, _index: number) => {},
  customTextOnSelect: (_node: JSX.Element) => '',
  navigateMapping: (_direction: 'next' | 'previous') => {},
  navigateSuggestions: (_direction: 'next' | 'previous') => {},
  isNextAvailable: () => {},
  isPreviousAvailable: () => {},
  handleApprove: (_paramsId: number) => {},
  hasNoResponse: false,
  setHasNoResponse: () => {},
  isLoading: { isMappingSessionsLoading: false, isCatalogDocLoading: false },
  setIsLoading: () => {},
  selectedSuggestion: '',
  setSelectedSuggestion: () => {},
  isDisabledApprove: true,
  setIsDisabledApprove: () => {},
  resetPlaceholder: false,
  setResetPlaceholder: () => {},
  approveDisable: true,
  setApproveDisable: () => {},
  autoMappedId: 0,
  setAutoMappedId: () => {},
  allSourceFiles: [
    {
      id: 0,
      title: '',
    },
  ],
  setAllSourceFiles: () => {},
  checkApprove: false,
  setCheckApprove: () => {},
  falseMappedId: 0,
  setFalseMappedId: () => {},
  activeSuggestion: '',
  setActiveSuggestion: () => {},
  activeSuggestionId: 0,
  setActiveSuggestionId: () => {},
  activeSuggestionIndex: 0,
  setActiveSuggestionIndex: () => {},
  editorModal: false,
  setEditorModal: () => {},
  sourceId: -1,
  setSourceId: () => {},
  targetFileContent: {
    title: '',
    html: '',
  },
  setTargetFileContent: () => {},
  mappingSuggestions: [
    {
      mappingId: '',
      sourceFileNodes: [
        {
          nodeId: '',
          fileId: 0,
          value: '',
          id: 0,
          approved: false,
        },
      ],
    },
  ],
  setMappingSuggestions: () => {},
  currentNodeSuggestionFiles: () => {},
  handleGetSource: () => {},
  editor1Ref: {},
  editor2Ref: {},
  isNextSuggestionAvailable: () => {},
  isPreviousSuggestionAvailable: () => {},
  suggestionIndex: 0,
  setSuggestionIndex: () => {},
  handleSearchTerm: (_term: any) => {},
  searchResults: [],
  setSearchResults: () => {},
  sessionParamsId: 0,
  setSessionParamsId: () => {},
};

export const AutoMappingContext = createContext<IAutoMappingContext>(defaultCatalogContext);

interface Props {
  children: ReactNode;
}

const AutoMappingContextContainer: FC<Props> = (props) => {
  const [currentTargetNodeId, setCurrentTargetNodeId] = useState(0);
  const [currentSourceNodeId, setCurrentSourceNodeId] = useState('');
  const [hasNoResponse, setHasNoResponse] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<AutoMappingLoading>({
    isMappingSessionsLoading: false,
    isCatalogDocLoading: false,
  });
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const [isDisabledApprove, setIsDisabledApprove] = useState<boolean>(true);
  const [resetPlaceholder, setResetPlaceholder] = useState(false);
  const [approveDisable, setApproveDisable] = useState(true);
  const [autoMappedId, setAutoMappedId] = useState<number>(0);
  const [allSourceFiles, setAllSourceFiles] = useState<IAllSourceFiles[]>([{ id: 0, title: '' }]);
  const [checkApprove, setCheckApprove] = useState(false);
  const [falseMappedId, setFalseMappedId] = useState<number>();
  const [activeSuggestion, setActiveSuggestion] = useState('');
  const [activeSuggestionId, setActiveSuggestionId] = useState<number>();
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>();
  const [editorModal, setEditorModal] = useState(false);
  const [sourceId, setSourceId] = useState<number>(-1);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [sessionParamsId, setSessionParamsId] = useState<number>(0);
  const [sourceContent, setSourceContent] = useState({
    filename: '',
    html: '',
    id: -1,
  });

  const [targetFileContent, setTargetFileContent] = useState({
    title: '',
    html: '',
  });

  const [mappingSuggestions, setMappingSuggestions] = useState([
    {
      sourceFileNodes: [
        {
          nodeId: '',
          fileId: -1,
          value: '',
          id: -1,
          approved: false,
        },
      ],
      mappingId: '',
    },
  ]);
  const [suggestionIndex, setSuggestionIndex] = useState<number>(0);

  const { editor1Ref, editor2Ref, getElements } = useContext(MappingContext);

  const currentNodeSuggestionFiles = () => {
    const currentTargetNodeSuggestions = mappingSuggestions[currentTargetNodeId].sourceFileNodes;
    const distinctIds = [...new Set(currentTargetNodeSuggestions.map((item) => item.fileId))];
    return allSourceFiles.filter((item: { id: number }) => distinctIds.includes(item.id));
  };

  const copyAcceptedHtml = () => {
    const approvedNode = mappingSuggestions[currentTargetNodeId].sourceFileNodes.find(
      (node: { approved: boolean }) => node.approved === true,
    );
    const approvedValue = approvedNode?.value;
    if (approvedValue) {
      const els = getElements(
        editor1Ref,
        `[data-nodeid='${mappingSuggestions[currentTargetNodeId].mappingId}']`,
      );
      if (els?.length > 0) {
        els[0].innerHTML = approvedValue;
      }
    }
  };

  const handleGetSource = () => {
    if (sourceId > 0) {
      fetchSourceFiles([sourceId])
        .then((res) => {
          setSourceContent(res.data[0]);
        })
        .catch(() => {
          toast.error('Error fetching Source files');
        });
    }
  };

  const handleHighlight = () => {
    mappingSuggestions[currentTargetNodeId]?.sourceFileNodes?.forEach((element) => {
      if (element.approved) {
        setCheckApprove(true);
      }
    });
  };

  const handleSuggestionClick = (element: SourceNode, index: number) => {
    setCurrentSourceNodeId(element.nodeId);
    setActiveSuggestion(element.value);
    setActiveSuggestionId(element.fileId);
    setActiveSuggestionIndex(index);
    setAutoMappedId(element.id);
    setApproveDisable(element.approved);
  };

  const handleSuggestionSelected = (element: SourceNode, index: number) => {
    setSelectedSuggestion(element.nodeId);
    setAutoMappedId(element.id);
    setSourceId(element.fileId);
    setCurrentSourceNodeId(element.nodeId);
    setActiveSuggestion(element.value);
    setApproveDisable(element.approved);
    setSuggestionIndex(index);
  };

  const customTextOnSelect = (node: JSX.Element) => {
    if (typeof node === 'object' && node.props && node.props.children) {
      return node.props.children[0].props.children;
    }
    return '';
  };

  const navigateMapping = (direction: 'next' | 'previous') => {
    setActiveSuggestionIndex(undefined);
    setActiveSuggestionId(undefined);
    setApproveDisable(true);
    setCheckApprove(false);
    setSuggestionIndex(0);
    setAutoMappedId(0);
    setActiveSuggestion('');
    direction === 'next'
      ? setCurrentTargetNodeId(currentTargetNodeId + 1)
      : setCurrentTargetNodeId(currentTargetNodeId - 1);
  };
  const setStatesNavSuggestions = (indexStep: number) => {
    const sourceNodes = mappingSuggestions[currentTargetNodeId].sourceFileNodes;
    const node = sourceNodes[suggestionIndex + indexStep];
    if (
      (suggestionIndex < sourceNodes.length && indexStep === 1) ||
      (suggestionIndex > 0 && indexStep === -1)
    ) {
      setCurrentSourceNodeId(node.nodeId);
      setAutoMappedId(node.id);
      setActiveSuggestion(node.value);
      setSuggestionIndex(suggestionIndex + indexStep);
      setSourceId(node.fileId);
    }
  };
  const navigateSuggestions = (direction: 'next' | 'previous') => {
    setActiveSuggestionIndex(undefined);
    setActiveSuggestionId(undefined);
    setApproveDisable(true);
    const indexStep = direction === 'next' ? 1 : -1;
    setStatesNavSuggestions(indexStep);
  };

  const isNextAvailable = () => {
    return currentTargetNodeId < mappingSuggestions.length - 1;
  };

  const isPreviousAvailable = () => {
    return currentTargetNodeId != 0;
  };

  const isNextSuggestionAvailable = () => {
    return suggestionIndex < mappingSuggestions[currentTargetNodeId].sourceFileNodes.length - 1;
  };

  const isPreviousSuggestionAvailable = () => {
    return suggestionIndex > 0;
  };

  const handleApprove = (paramsId: number) => {
    const els = getElements(
      editor1Ref,
      `[data-nodeid='${mappingSuggestions[currentTargetNodeId].mappingId}']`,
    );
    if (els?.length > 0 || activeSuggestion) {
      els[0].innerHTML = activeSuggestion;
      const obj = {
        id: autoMappedId,
        alreadyApproved: -1,
        mappingSessionId: paramsId,
      };
      mappingSuggestions[currentTargetNodeId]?.sourceFileNodes?.forEach(async (element) => {
        if (element.approved === true) {
          obj.alreadyApproved = element?.id;
        }
      });
      handleApproveState(true);
      acceptAutoMappingSuggestion(obj)
        .then(async (_res) => {
          toast.success('Suggestion approved');
          setApproveDisable(true);
          handleHighlight();
        })
        .catch(() => {
          handleApproveState(false);
        });
    }
  };

  const scrollTarget = () => {
    const els = getElements(
      editor1Ref,
      `[data-nodeid='${mappingSuggestions[currentTargetNodeId].mappingId}']`,
    );
    const prevEl = getElements(editor1Ref, '.selected-target-node');
    if (prevEl?.length > 0) {
      prevEl[0].style.backgroundColor = '';
      prevEl[0].classList.remove('selected-target-node');
    }
    handleHighlight();
    if (els?.length > 0) {
      els[0].scrollIntoView({
        behavior: 'auto',
        inline: 'center',
        block: 'center',
      });
      const currentElement = getCellElement(els[0], editor1Ref);
      if (currentElement) {
        if (checkApprove && els) {
          currentElement.style.backgroundColor = '#85D6B6';
        } else {
          currentElement.style.backgroundColor = 'lightblue';
        }
        currentElement.classList.add('selected-target-node');
      }
      copyAcceptedHtml();
    }
  };

  const removeSearchedHighlight = () => {
    const prevEl = getElementsByQuery(
      editor2Ref,
      '.searched-source-node',
    ) as unknown as HTMLElement[];
    if (prevEl && prevEl?.length > 0) {
      prevEl[0].style.cssText = prevEl[0].getAttribute('data-mce-style') as string;
      prevEl[0].classList.remove('searched-source-node');
    }
  };

  const scrollToSearchedElement = (nodeId: any) => {
    const els = getElement(editor2Ref, nodeId);
    removeSearchedHighlight();
    if (els) {
      els?.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
        block: 'center',
      });
      els.style.backgroundColor = 'magenta';
      els.classList.add('searched-source-node');
    }
  };

  const handleSearchTerm = useCallback(
    async (term: string) => {
      let searchResponse: SearchResult[] = [];
      if (term) {
        searchResponse = await search({
          searchTerm: term,
          searchThreshold: SearchThreshold,
          dataDocIds: [sourceId],
        }).then((result) => result.data.data);
      }
      if (searchResponse?.length === 0 || !term) {
        const results = [
          {
            label: (
              <>
                <p className="font-semibold text-gray-500  text-sm">No Result Found</p>
              </>
            ),
          },
        ];
        setSearchResults(results);
      } else {
        const results = searchResponse?.map((s: any, i: any) => {
          const nodeId = s.dataNodeId;
          return {
            label: (
              <div onClick={() => scrollToSearchedElement(nodeId)}>
                <p className="font-semibold text-sm">{s.dataText}</p>
                <p className="text-gray-500 text-xs">{s.fileTitle}</p>
              </div>
            ),
            value: i,
          };
        });
        setSearchResults(results);
      }
    },
    [sourceId],
  );

  const handleApproveState = (state: boolean) => {
    mappingSuggestions[currentTargetNodeId]?.sourceFileNodes?.map(async (element) => {
      if (state) {
        if (element?.id === autoMappedId) {
          element.approved = state;
        }
        if (element.approved === true && element.id !== autoMappedId) {
          element.approved = !state;
          setAutoMappedId(element.id);
          setFalseMappedId(element.id);
        }
      } else {
        if (element.id === falseMappedId) {
          element.approved = !state;
        }
        if (element?.id === autoMappedId) {
          element.approved = state;
        }
      }
    });
  };

  return (
    <AutoMappingContext.Provider
      value={{
        currentTargetNodeId,
        setCurrentTargetNodeId,
        currentSourceNodeId,
        setCurrentSourceNodeId,
        scrollTarget,
        handleApproveState,
        sourceContent,
        setSourceContent,
        handleHighlight,
        handleSuggestionClick,
        handleSuggestionSelected,
        customTextOnSelect,
        navigateMapping,
        isNextAvailable,
        isPreviousAvailable,
        handleApprove,
        hasNoResponse,
        setHasNoResponse,
        isLoading,
        setIsLoading,
        selectedSuggestion,
        setSelectedSuggestion,
        isDisabledApprove,
        setIsDisabledApprove,
        resetPlaceholder,
        setResetPlaceholder,
        approveDisable,
        setApproveDisable,
        autoMappedId,
        setAutoMappedId,
        allSourceFiles,
        setAllSourceFiles,
        checkApprove,
        setCheckApprove,
        falseMappedId,
        setFalseMappedId,
        activeSuggestion,
        setActiveSuggestion,
        activeSuggestionId,
        setActiveSuggestionId,
        activeSuggestionIndex,
        setActiveSuggestionIndex,
        editorModal,
        setEditorModal,
        sourceId,
        setSourceId,
        targetFileContent,
        setTargetFileContent,
        mappingSuggestions,
        setMappingSuggestions,
        currentNodeSuggestionFiles,
        handleGetSource,
        editor1Ref,
        editor2Ref,
        navigateSuggestions,
        isNextSuggestionAvailable,
        isPreviousSuggestionAvailable,
        suggestionIndex,
        setSuggestionIndex,
        handleSearchTerm,
        searchResults,
        setSearchResults,
        sessionParamsId,
        setSessionParamsId,
      }}>
      {props.children}
    </AutoMappingContext.Provider>
  );
};

export default AutoMappingContextContainer;
