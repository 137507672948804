import { useContext, useState } from 'react';
import { MappingContext } from '../contexts/MappingContext';
import AddCommentModal from '../components/modals/AddCommentModal';
import { IMapComment, IUseGeneralizationComments } from '../types';
import Comment from '../components/Comment';

export const useGeneralizationComments = (
  currentSuggestionId: number,
): IUseGeneralizationComments => {
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [comments, setComments] = useState<JSX.Element[] | null>(null);
  const { isCommentDisplayed, toggleComments, mappingComments } = useContext(MappingContext);

  const openCommentModal = () => {
    setCommentModalIsOpen(true);
  };

  const openCommentModalWithThread = (id: string) => {
    setComments(
      mappingComments
        .filter((c: IMapComment) => c.targetNodeId === id)
        .map((c: IMapComment) => {
          return (
            <Comment
              key={c.commentNumber}
              name={`${c.firstName} ${c.lastName}`}
              message={c.comment}
            />
          );
        }),
    );
    setCommentModalIsOpen(true);
  };

  const commentModal = (
    <AddCommentModal
      commentModalIsOpen={commentModalIsOpen}
      closeModal={() => setCommentModalIsOpen(false)}
      suggestionId={currentSuggestionId}
      comments={comments || []}
    />
  );

  return {
    openCommentModal,
    isCommentDisplayed,
    toggleComments,
    commentModal,
    openCommentModalWithThread,
  };
};
