import { ProjectPlaceholdersParams } from './placeholder.types';
import { MessageResponse } from '../types';
import { AxiosResponse } from 'axios';
import axios from '../../../helpers/AxiosConfig';

export const createPlaceholders = async (
  data: ProjectPlaceholdersParams,
): Promise<AxiosResponse<MessageResponse>> => {
  return axios.post('/placeholder/create', data);
};

export const updatePlaceholders = async (
  data: ProjectPlaceholdersParams,
): Promise<AxiosResponse<MessageResponse>> => {
  return axios.put('/placeholder/update', data);
};
