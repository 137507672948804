import { NavItemUpdateParams } from '../types';

export const updatedNavItem = (navItem: NavItemUpdateParams) => {
  const { text, newKey, newValue, navItems } = navItem;
  const navItemIndex = navItems.findIndex((i) => i.text === text);
  if (navItemIndex !== -1) {
    const updatedNavItem = {
      ...navItems[navItemIndex],
      [newKey]: newValue,
    };
    navItems[navItemIndex] = updatedNavItem;
  }
  return navItems;
};
