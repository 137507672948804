import { CreateProjectFormValues, inputListType } from '../CreateNewProjectForm.types';

export const inputList: inputListType[] = [
  {
    label: 'Project Name*',
    type: 'text',
    name: 'name',
    placeholder: 'Project Name',
  },
];

export const ProjectDefaultValues: CreateProjectFormValues = {
  name: '',
  groupIds: [],
  subGroupIds: [],
};
