import { createContext, useState, ReactNode, FC } from 'react';

import { IUserInfoContext } from '../types';
import useClientInfo from '../hooks/useClientInfo';

interface Props {
  children: ReactNode;
}

export const UserInfoContext = createContext<IUserInfoContext>({
  firstName: '',
  lastName: '',
  userId: '',
  isViewChanged: false,
  setIsViewChanged: () => {},
  permissions: undefined,
});

const Container: FC<Props> = ({ children }) => {
  const [isViewChanged, setIsViewChanged] = useState(false);
  const { firstName, lastName, userId, permissions } = useClientInfo();

  return (
    <UserInfoContext.Provider
      value={{
        firstName: firstName,
        lastName: lastName,
        userId: userId,
        isViewChanged,
        setIsViewChanged,
        permissions: permissions,
      }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default Container;
