// React related imports
import { useEffect, FC } from 'react';
import { Controller } from 'react-hook-form';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

// Components
import { Input, TagsField } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { InputError } from '../../components/inputError';
import useModal from '../../hooks/useModal';
import CatalogItemSlideOver from '../../components/CatalogItemSlideOver';

// Types
import { ITemplateFields } from './types';
import { ItemType } from '../../services/api/types';
import { SourceFileGroup } from '../upload';
// apicalls
import { getAllCatalogItems } from '../settings/components/utils/apiHelpers';

// utils
import { generateTagsFieldOptions } from './utils/helpers';

const TemplateFields: FC<ITemplateFields> = ({ control, errors, setValue }) => {
  const catalogItemSlideOver = useModal();
  const [selectedGroups, setSelectedGroups] = useState<SourceFileGroup[]>([]);
  const [selectedSections, setSelectedSections] = useState<SourceFileGroup[]>([]);
  const [isGroupsModal, setIsGroupsModal] = useState<boolean>(true);
  const handleCloseCreateItem = () => {
    catalogItemSlideOver.closeModal();
  };

  const { data: sections } = useQuery({
    queryKey: [ItemType.Section],
    queryFn: () => getAllCatalogItems('Section'),
  });

  const { data: groups } = useQuery({
    queryKey: [ItemType.Group],
    queryFn: () => getAllCatalogItems('Group'),
  });

  const handleOpenGroupsModal = () => {
    setIsGroupsModal(true);
    catalogItemSlideOver.openModal();
  };
  const handleResetSelection = () =>
    isGroupsModal ? setSelectedGroups([]) : setSelectedSections([]);

  const handleOpenSectionsModal = () => {
    setIsGroupsModal(false);
    catalogItemSlideOver.openModal();
  };

  useEffect(() => {
    setValue('group', selectedGroups);
  }, [selectedGroups]);

  useEffect(() => {
    setValue('section', selectedSections);
  }, [selectedSections]);

  return (
    <form className="flex flex-col items-start gap-4 w-2/5 self-stretch">
      <p className="text-lg font-bold leading-6 tracking-tighter text-primary-300">
        Template information
      </p>
      <div className="flex flex-col items-start gap-1 w-full">
        <Controller
          name="templateName"
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <Input
                {...field}
                inputSize={'small'}
                label={'Template name*'}
                primary
                placeholder="Template name"
                customStyles={{
                  container: { width: '100%' },
                  input: { borderRadius: '0.375rem' },
                  label: { color: 'var(--Primary-300, #115873)' },
                }}
              />
              <InputError errors={errors} field={'templateName'} />
            </div>
          )}
        />
      </div>
      <div className="flex items-start gap-4 self-stretch">
        <Controller
          name="group"
          control={control}
          render={({ field }) => {
            const groupsListOptions = generateTagsFieldOptions(selectedGroups);
            return (
              <div className="w-full" onClick={handleOpenGroupsModal}>
                {/* TODO: on disable click should be disabled */}
                <TagsField
                  {...field}
                  value={groupsListOptions}
                  onClick={handleOpenGroupsModal}
                  label="Group*"
                />
                <InputError errors={errors} field={'group'} />
              </div>
            );
          }}
        />
        <Controller
          name="section"
          control={control}
          render={({ field }) => {
            const sectionListOptions = generateTagsFieldOptions(selectedSections);
            return (
              <div className="w-full" onClick={handleOpenSectionsModal}>
                <TagsField
                  {...field}
                  value={sectionListOptions}
                  onClick={handleOpenGroupsModal}
                  label="Section*"
                />
                <InputError errors={errors} field={'section'} />
              </div>
            );
          }}
        />
        {
          // TODO: This dropdown will be used for Section in future
          // <Dropdown
          //   customStyles={{
          //     button: { width: '100%', height: '36px' },
          //     container: { flex: 1 },
          //     placeholder: { justifyContent: 'flex-start' },
          //     label: { color: 'var(--Primary-300, #115873)', fontWeight: '700' },
          //   }}
          //   dropdownIcon
          //   placeholder="Section"
          //   label="Section*"
          //   onSelect={() => {}}
          //   options={['Option 1', 'Option 2', 'Option 3 is a very big option']}
          //   position="bottom"
          //   type="select"
          // />
        }
      </div>
      <div className="flex items-start gap-4 self-stretch">
        <Controller
          name="version"
          control={control}
          render={({ field }) => (
            <div className="w-1/2">
              <Input
                {...field}
                inputType={'number'}
                min={0}
                step={0.1}
                inputSize={'small'}
                label={'Version*'}
                primary
                placeholder="Add a version"
                customStyles={{
                  container: { marginRight: '0.5rem' },
                  input: { borderRadius: '0.375rem' },
                  label: { color: 'var(--primary-300, #115873)' },
                }}
              />
              <InputError errors={errors} field={'version'} />
            </div>
          )}
        />
      </div>
      {catalogItemSlideOver.show && (
        <CatalogItemSlideOver
          onClose={handleCloseCreateItem}
          isOpen={catalogItemSlideOver.show}
          heading={isGroupsModal ? 'Assign Groups' : 'Assign Sections'}
          catalogItems={(isGroupsModal ? groups : sections) || []}
          selectedItems={isGroupsModal ? selectedGroups : selectedSections}
          handleAdd={isGroupsModal ? setSelectedGroups : setSelectedSections}
          handleResetSelection={handleResetSelection}
        />
      )}
      <p className="text-primary-200 text-xs not-italic font-normal leading-[1.125rem] tracking-[0.00088rem]">
        Items marked with a * are required
      </p>
    </form>
  );
};

export default TemplateFields;
