/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useState, createContext, FC, ReactNode } from 'react';
import { getCatalogs, getDistinctValues } from '../services/apiCalls';
import { ICatalogContext, IDropdown, SourceDocumentTypeEnum } from '../types';
import { removeNullAttributes } from '../helpers/catalogHelpers';
import { CatalogFilesUploadStatus } from '../types/CatalogTypes';

const defaultCatalogContext: ICatalogContext = {
  sourceFiles: null,
  setSourceFiles: () => {},
  isLoading: false,
  distinctValues: { uploadedBy: [], programs: [], documentTypes: [], documentCategories: [] },
  setRefreshSourceFiles: () => {},
  setFilters: () => {},
  getUserName: (_id: number) => '',
  pagination: {
    page: 1,
    perPage: 10,
    total: 1,
  },
  setPagination: () => {},
  resetPagination: () => {},
  refreshCatalogFiles: (documentType) => {},
  selectedSourceDocuments: [],
  setSelectedSourceDocuments: () => {},
  selectedTargetDocument: null,
  setSelectedTargetDocument: () => {},
  refreshDistinctValues: (documentType) => {},
  dropdownUsersData: [{ selected: false, text: '', value: '' }],
  setDropdownUsersData: () => {},
  dropdownProgramsData: [{ selected: false, text: '', value: '' }],
  setDropdownProgramsData: () => {},
  dropdownDocCatData: [{ selected: false, text: '', value: '' }],
  setDropdownDocCatData: () => {},
  catalogFilesUploadStatus: [],
  setCatalogFilesUploadStatus: () => {},
};
export const CatalogContext = createContext<ICatalogContext>(defaultCatalogContext);

interface Props {
  children: ReactNode;
}

const CatalogContextContainer: FC<Props> = (props) => {
  const [catalogFilesUploadStatus, setCatalogFilesUploadStatus] = useState<
    CatalogFilesUploadStatus[]
  >([]);

  // TODO: Remove these states
  const [sourceFiles, setSourceFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshSourceFiles, setRefreshSourceFiles] = useState(false);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(defaultCatalogContext.pagination);
  const [distinctValues, setDistinctValues] = useState(defaultCatalogContext.distinctValues);
  const [dropdownUsersData, setDropdownUsersData] = useState<IDropdown[]>(
    defaultCatalogContext.dropdownUsersData,
  );
  const [dropdownProgramsData, setDropdownProgramsData] = useState<IDropdown[]>(
    defaultCatalogContext.dropdownProgramsData,
  );
  const [dropdownDocCatData, setDropdownDocCatData] = useState<IDropdown[]>(
    defaultCatalogContext.dropdownDocCatData,
  );
  const [selectedSourceDocuments, setSelectedSourceDocuments] = useState<number[]>([]);
  const [selectedTargetDocument, setSelectedTargetDocument] = useState(null);

  const resetPagination = () => {
    setPagination(defaultCatalogContext.pagination);
  };

  const getUserName = (id: number) =>
    distinctValues.uploadedBy?.find((user: any) => user.key === id)?.value;

  const refreshDistinctValues = (documentType?: SourceDocumentTypeEnum) => {
    getDistinctValues(documentType).then((res) => {
      setDistinctValues(res.data);
      setDropdownUsersData(generateDataArray(res.data.uploadedBy));
      setDropdownProgramsData(generateDataArray(res.data.programs));
      setDropdownDocCatData(generateDataArray(res.data.documentCategories));
    });
  };

  const generateDataArray = (data: { value: string; key: string }[]) =>
    data.map((option) => ({
      selected: false,
      text: option.value,
      value: option.key,
    }));

  const refreshCatalogFiles = (documentType?: SourceDocumentTypeEnum) => {
    setRefreshSourceFiles(true);
    refreshDistinctValues(documentType);
  };

  useEffect(() => {
    if (refreshSourceFiles) {
      setIsLoading(true);
      getCatalogs({
        page: +pagination.page || 1,
        perPage: +pagination.perPage || 10,
        ...removeNullAttributes(filters),
      }).then((res) => {
        setPagination((prev) => ({
          ...prev,
          total: res.data.total,
        }));
        setSourceFiles(res.data.catalog);
        setIsLoading(false);
        setRefreshSourceFiles(false);
      });
    }
  }, [refreshSourceFiles, filters, pagination]);

  useEffect(() => {
    setSelectedSourceDocuments([]);
  }, [window.location.pathname]);

  return (
    <CatalogContext.Provider
      value={{
        sourceFiles,
        setSourceFiles,
        isLoading,
        distinctValues,
        setRefreshSourceFiles,
        setFilters,
        getUserName,
        pagination,
        setPagination,
        resetPagination,
        refreshCatalogFiles,
        selectedSourceDocuments,
        setSelectedSourceDocuments,
        selectedTargetDocument,
        setSelectedTargetDocument,
        refreshDistinctValues,
        dropdownUsersData,
        setDropdownUsersData,
        dropdownProgramsData,
        setDropdownProgramsData,
        dropdownDocCatData,
        setDropdownDocCatData,
        catalogFilesUploadStatus,
        setCatalogFilesUploadStatus,
      }}>
      {props.children}
    </CatalogContext.Provider>
  );
};

export default CatalogContextContainer;
