import { useState, useEffect, useRef, RefObject, useContext } from 'react';
import { GeneralizationContext } from '../../contexts/GeneralizationContext';
import { extractTextFromNode } from '../../helpers/generalizationHelpers';
import { useDebounce } from '../../hooks/useDebounce';
import { AiOutlineSearch } from 'react-icons/ai';
import SvgIcon from './SvgIcon';

interface SearchableDropdownProps {
  options: { value: number; label: JSX.Element | string; nodeId?: string }[];
  placeholder?: string;
  onOptionSelected?: (option: {
    value: number;
    label: JSX.Element | string;
    nodeId?: string;
  }) => void;
  className?: string;
  customTextOnSelect?: ((node: JSX.Element) => string) | null;
  resetPlaceholder?: boolean;
  setResetPlaceholder?: (arg: boolean) => void;
  onSearch?: (searchTerm: string) => void;
  hideIcon?: boolean;
}

const SearchableDropdown = ({
  options,
  placeholder = 'Search...',
  onOptionSelected,
  className = '',
  customTextOnSelect = null,
  resetPlaceholder,
  setResetPlaceholder,
  onSearch,
  hideIcon,
}: SearchableDropdownProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const { searchTerm, setSearchTerm } = useContext(GeneralizationContext);

  const wrapperRef: RefObject<HTMLDivElement> = useRef(null);

  const debounceValue = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (onSearch) {
      onSearch(debounceValue);
    }
  }, [debounceValue, onSearch]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as HTMLElement)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (resetPlaceholder) {
      setSearchTerm('');
      setResetPlaceholder && setResetPlaceholder(false);
    }
  }, [resetPlaceholder]);

  return (
    <div className={`relative ${className}`} ref={wrapperRef}>
      <div className="w-full px-3 py-2 border rounded-md focus:outline-none bg-white overflow-hidden">
        {!hideIcon && (
          <AiOutlineSearch className="absolute left-3 mt-1 text-xl text-gray-500"></AiOutlineSearch>
        )}
        <input
          className="ml-7 w-full focus:outline-none"
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={() => {
            setDropdownOpen(!isDropdownOpen);
          }}
        />
        {searchTerm && (
          <button
            className="absolute inset-y-0 right-0 flex items-center pr-2 mr-2  text-gray-400"
            onClick={() => setSearchTerm('')}>
            <SvgIcon iconType="close" stroke="currentColor" />
          </button>
        )}
      </div>
      {isDropdownOpen && (
        <div
          className="absolute w-full max-h-60 mt-1 overflow-auto border rounded-md z-10 bg-white"
          role={'listbox'}>
          {options?.map((option, i) => (
            <div
              key={i}
              onClick={() => {
                if (typeof option.label === 'string') {
                  setSearchTerm(option.label);
                } else {
                  if (customTextOnSelect) setSearchTerm(customTextOnSelect(option.label));
                  else setSearchTerm(extractTextFromNode(option.label));
                }
                setDropdownOpen(false);
                if (onOptionSelected) {
                  onOptionSelected(option);
                }
              }}
              className="px-3 py-2 cursor-pointer hover:bg-gray-200">
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
