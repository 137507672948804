import { FC } from 'react';
import { IClientStyling } from '../types';
import { ColorProperties, TAILWIND_COLOR_VARIABLES } from '../constants';

interface StyledWrapperProps {
  id: string;
  clientStyling: IClientStyling;
  children?: React.ReactNode;
}

const addHashInStart = (hex?: string) => {
  if (!hex) return '';

  const isValidHexColor = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);
  if (!isValidHexColor) return hex;

  return `${hex?.startsWith('#') ? '' : '#'}${hex}`;
};

const StyledWrapper: FC<StyledWrapperProps> = ({ id, clientStyling, children }) => {
  const dynamicStyles: ColorProperties = {
    [TAILWIND_COLOR_VARIABLES.PRIMARY._50]: addHashInStart(clientStyling.colors.primary._50),
    [TAILWIND_COLOR_VARIABLES.PRIMARY._100]: addHashInStart(clientStyling.colors.primary._100),
    [TAILWIND_COLOR_VARIABLES.PRIMARY._200]: addHashInStart(clientStyling.colors.primary._200),
    [TAILWIND_COLOR_VARIABLES.PRIMARY._300]: addHashInStart(clientStyling.colors.primary._300),
    [TAILWIND_COLOR_VARIABLES.PRIMARY._400]: addHashInStart(clientStyling.colors.primary._400),

    [TAILWIND_COLOR_VARIABLES.SYSTEM._50]: addHashInStart(clientStyling.colors.system?._50),
    [TAILWIND_COLOR_VARIABLES.SYSTEM._100]: addHashInStart(clientStyling.colors.system?._100),

    [TAILWIND_COLOR_VARIABLES.NEUTRAL._50]: addHashInStart(clientStyling.colors.neutral?._50),
    [TAILWIND_COLOR_VARIABLES.NEUTRAL._100]: addHashInStart(clientStyling.colors.neutral?._100),
    [TAILWIND_COLOR_VARIABLES.NEUTRAL._200]: addHashInStart(clientStyling.colors.neutral?._200),
    [TAILWIND_COLOR_VARIABLES.NEUTRAL._300]: addHashInStart(clientStyling.colors.neutral?._300),
    [TAILWIND_COLOR_VARIABLES.NEUTRAL._400]: addHashInStart(clientStyling.colors.neutral?._400),

    [TAILWIND_COLOR_VARIABLES.POSITIVE._50]: addHashInStart(clientStyling.colors.positive?._50),
    [TAILWIND_COLOR_VARIABLES.POSITIVE._100]: addHashInStart(clientStyling.colors.positive?._100),
    [TAILWIND_COLOR_VARIABLES.POSITIVE._200]: addHashInStart(clientStyling.colors.positive?._200),

    [TAILWIND_COLOR_VARIABLES.NEGATIVE._50]: addHashInStart(clientStyling.colors.negative?._50),
    [TAILWIND_COLOR_VARIABLES.NEGATIVE._100]: addHashInStart(clientStyling.colors.negative?._100),
    [TAILWIND_COLOR_VARIABLES.NEGATIVE._200]: addHashInStart(clientStyling.colors.negative?._200),

    [TAILWIND_COLOR_VARIABLES.WARNING._50]: addHashInStart(clientStyling.colors.warning?._50),
    [TAILWIND_COLOR_VARIABLES.WARNING._100]: addHashInStart(clientStyling.colors.warning?._100),
    [TAILWIND_COLOR_VARIABLES.WARNING._200]: addHashInStart(clientStyling.colors.warning?._200),
  };

  return (
    <div id={id} style={{ ...dynamicStyles }}>
      {children}
    </div>
  );
};

export default StyledWrapper;
