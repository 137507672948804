import Modal from 'react-modal';
import { modalCustomStyles } from '../../constants';
import { ISourceDocument } from '../../types';
import DocumentCard from '../DocumentCard';
import Button from '../elements/Button';
import ModalHeader from './ModalHeader';

type NonInteractiveDocumentsModalType = {
  isOpen: boolean;
  closeModal: () => void;
  documents: ISourceDocument[];
};

const NonInteractiveDocumentsModal = ({
  isOpen,
  closeModal,
  documents,
}: NonInteractiveDocumentsModalType) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalCustomStyles}
      ariaHideApp={false}>
      <div className="w-[40rem] border-gray-200 bg-white md:mt-3 xl:p-0">
        <div className="space-y-6 p-6 sm:p-8">
          <div>
            <ModalHeader title={'Non-Interactive Documents Detected'} closeModal={closeModal} />
            <p className="text-gray-500">Count: {documents.length}</p>
          </div>
          <div className="flex items-start rounded-md border border-red-800 p-2 text-red-800">
            <p>
              {`Won't be able to get suggestions from these documents but you will be able to use
              these to map manually.`}
            </p>
          </div>
          <div className="h-96 space-y-2 overflow-y-scroll">
            {documents.map((document) => (
              <DocumentCard key={document.id} document={document} />
            ))}
          </div>
          <Button
            type="button"
            onClick={closeModal}
            title="Close"
            className="float-right w-fit rounded-lg border border-primary_bg_color px-10 py-2.5 text-center text-sm font-bold text-primary_bg_color duration-300 hover:bg-secondary_bg_color hover:text-white"
          />
        </div>
      </div>
    </Modal>
  );
};
export default NonInteractiveDocumentsModal;
