import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { IExpandableSection } from '../types';
import { Button } from '@gloabal-regulatory-writing-consulting/gxt-components';

const ExpandableSection = ({
  title,
  options,
  selectedOption,
  handleOptionChange,
  defaultOpen = false,
}: IExpandableSection) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <>
      <div key={title} className="py-3">
        <Button
          className="w-full px-4 font-medium text-xl text-gray-700"
          data-testid={`${title}-btn`}
          onClick={() => setIsOpen((prev) => !prev)}>
          <div className="flex justify-between">
            {title}
            <ChevronRightIcon
              className={twMerge(`text-gray-300 h-6 w-6`, `${isOpen ? 'rotate-90 transform' : ''}`)}
            />
          </div>
        </Button>

        {isOpen && (
          <div className="px-6 text-gray-600">
            {options.map((option, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  id={`${title}-${index}`}
                  value={option}
                  checked={selectedOption === option}
                  data-testid={`options-${title}-${option}`}
                  onChange={() => handleOptionChange(option)}
                  className="mr-2"
                />
                <label htmlFor={`${title}-${index}`}>
                  <span>{option}</span>
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
      <hr className="mx-4 " />
    </>
  );
};

export default ExpandableSection;
