const ACTIVE_USER = 'activeUser';
const USER_GROUPS = 'userGroups';
const AUTHORIZED_PAGES = 'authorizedPages';

export const deleteDataFromLocalStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const storeUserDataInLocalStorage = (data: any) => {
  localStorage.setItem(ACTIVE_USER, JSON.stringify(data));
};

export const getUserDataFromLocalStorage = () => {
  try {
    const userData = localStorage.getItem(ACTIVE_USER);
    return userData ? JSON.parse(userData) : null;
  } catch (error) {
    console.error('Error parsing user data from localStorage:', error);
    return null;
  }
};

export const storeUserGroupsInLocalStorage = (data: any) => {
  localStorage.setItem(USER_GROUPS, JSON.stringify(data));
};

export const getUserGroupsFromLocalStorage = () => {
  try {
    const userGroups = localStorage.getItem(USER_GROUPS);
    return userGroups ? JSON.parse(userGroups) : null;
  } catch (error) {
    console.error('Error parsing user groups from localStorage:', error);
    return null;
  }
};

export const storeAuthorizedPagesInLocalStorage = (data: any) => {
  localStorage.setItem(AUTHORIZED_PAGES, JSON.stringify(data));
};

export const getAuthorizedPagesFromLocalStorage = () => {
  try {
    const authorizedPages = localStorage.getItem(AUTHORIZED_PAGES);
    return authorizedPages ? JSON.parse(authorizedPages) : null;
  } catch (error) {
    console.error('Error parsing authorized pages from localStorage:', error);
    return null;
  }
};
