import { useState, useEffect, useCallback } from 'react';

export const useLocalStorage = (key: string, initialValue: any): [any, (value: any) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    return getLocalStorage(key, initialValue);
  });

  useEffect(() => {
    setLocalStorage(key, storedValue);
  }, [key, storedValue]);

  const setValue = (value: any) => {
    setStoredValue(value);
  };

  const setLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const handleStorageChange = useCallback(() => {
    setStoredValue(getLocalStorage(key, initialValue));
  }, [key, initialValue]);

  // need to add an event listener that listens for changes to local storage
  // and updates the state accordingly
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, initialValue]);

  function getLocalStorage(key: string, initialValue: any): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  }

  return [storedValue, setValue];
};
