import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/elements/Button';
import Input from '../../components/elements/Input';
import { IResetPasswordProps } from '../../types';
import {
  FIELD_EMPTY_ERROR,
  PASSWORD_MATCH_ERROR,
  PASSWORD_VALIDATION_ERROR,
} from '../../constants';
import { useAuth } from '../../contexts/AuthContext';
import { useDynamicStylingContext } from '../../contexts/DynamicStylingContext';
import { isPasswordValid } from '../../helpers/utils';

const ResetPassword: React.FC<IResetPasswordProps> = ({ forReset }) => {
  const [data, setData] = useState({ newPassword: '', confirmPassword: '' });
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const { clientStyling } = useDynamicStylingContext();
  const { logo } = clientStyling;

  const { createNewPassword } = useAuth();

  const inputList = [
    {
      label: 'New Password',
      type: 'password',
      name: 'newPassword',
      value: data.newPassword,
      placeholder: 'Enter new password',
    },
    {
      label: 'Confirm Password',
      type: 'password',
      name: 'confirmPassword',
      value: data.confirmPassword,
      placeholder: 'Enter confirm password',
    },
  ];

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const resetPasswordHandler = async () => {
    if (data.newPassword === '' || data.confirmPassword === '') {
      setError(FIELD_EMPTY_ERROR);
      return;
    }

    if (!isPasswordValid(data.newPassword)) {
      setError(PASSWORD_VALIDATION_ERROR);
      return;
    }

    if (data.newPassword !== data.confirmPassword) {
      setError(PASSWORD_MATCH_ERROR);
      return;
    }

    await createNewPassword(data.newPassword);

    navigate('/login');
    setError('');
  };

  return (
    <>
      <img src={logo} alt="logo" width={285} className="mx-auto mt-32" />
      <div className="flex justify-center mt-4 px-6 py-8 mx-auto md:h-9/12 lg:py-0">
        <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-white border-gray-200">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl text-primary_text_color text-center">
              {forReset ? 'Reset Password' : 'Set Password'}
            </h1>
            <div className="space-y-4 md:space-y-6">
              {inputList?.map((item, index) => (
                <div key={index}>
                  <label
                    htmlFor={item.name}
                    className="block mb-2 text-sm font-medium text-gray-900">
                    {item.label}
                  </label>
                  <Input
                    type={item.type}
                    name={item.name}
                    value={item.value}
                    placeholder={item.placeholder}
                    onChange={changeHandler}
                    className={undefined}
                    dataTestId={undefined}
                  />
                </div>
              ))}
              <Button
                type="submit"
                onClick={resetPasswordHandler}
                title={forReset ? 'Reset Password' : 'Set Password'}
                className="w-full text-white bg-primary_bg_color font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:bg-secondary_bg_color"
              />
              {error && <p className="text-red-500 text-sm">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
