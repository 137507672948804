// React-related imports
import { FC, useRef } from 'react';

// Component imports
import { Button, SlideOver } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { RejectionForm } from './components/RejectionForm';

// Types
import { RejectionSlideOverProps } from './RejectionSlideOver.types';
import { initialRejectionFormValues } from './utils/constants';

const RejectionSlideOver: FC<RejectionSlideOverProps> = ({
  isOpen,
  onClose,
  handleSubmit,
  initialFormValues = initialRejectionFormValues,
}) => {
  const formRef = useRef<{ submitForm: () => void; resetForm: () => void }>(null);

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const onCloseSlideOver = () => {
    onClose();
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} width="28rem" mountElementId="styled-wrapper">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        Rejection notes
      </SlideOver.Header>
      <RejectionForm
        ref={formRef}
        handleSubmitData={handleSubmit}
        defaultValues={initialFormValues}
      />
      <SlideOver.Footer className="flex flex-col items-center self-stretch border-t !border-neutral-100">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" onClick={handleFormSubmit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={onCloseSlideOver}>
            Cancel
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default RejectionSlideOver;
