import { AxiosResponse } from 'axios';
import axios from '../../../helpers/AxiosConfig';

import {
  DeleteUserResponse,
  GetUsersParams,
  GetUsersResponse,
  SignupResponse,
  UpdateUserParams,
  UpdateUserResponse,
  User,
} from './user.types';

export const getUsers = async (
  params: GetUsersParams,
): Promise<AxiosResponse<GetUsersResponse>> => {
  const response = await axios.get<GetUsersResponse>('users', {
    params,
  });
  return response;
};

export const removeUser = async (id: number) => {
  return axios.delete<DeleteUserResponse>(`users/${id}`);
};

export const editUser = async (params: UpdateUserParams) => {
  return await axios.put<UpdateUserResponse>('auth/editUser', params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const addUserToDb = async (data: FormData) => {
  return axios.post<SignupResponse>('auth/signup', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const checkPasswordChange = async (params: { email: string }) => {
  return axios.get('/auth/change-password', { params: params });
};

export const fetchUserByEmail = async (data: { email: string }) => {
  return axios.get<User>('user', { params: data });
};
