import { useMemo } from 'react';

const Select = ({ options, placeholder, label, dataTestId, ...rest }) => {
  const sortOptionsAlphabetically = useMemo(
    () => (options) =>
      options.sort((a, b) => {
        const option = (option) =>
          (
            option.text ||
            option.originalName ||
            option.filename ||
            option.value ||
            option.title ||
            ''
          )
            .toString()
            .toLowerCase();
        return option(a).localeCompare(option(b));
      }),
    [options],
  );

  const sortedOptions = sortOptionsAlphabetically(options || []);

  const selectTag = (
    <select
      {...rest}
      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 placeholder-gray-400 hover:bg-secondary_bg_color hover:cursor-pointer w-50 ${
        rest.className || 'block w-full'
      }`}
      value={
        rest.value ||
        (placeholder
          ? ''
          : sortedOptions.find((option) => option.text !== '' && option.selected)?.value)
      }
      data-testid={`${dataTestId}-${label}`}
      onChange={rest.onChange && rest.onChange}>
      {placeholder && <option value="">{placeholder}</option>}
      {sortedOptions.map((option, index) => (
        <option key={index} value={option.id || option.value}>
          {option.text || option.originalName || option.filename || option.value || option.title}
        </option>
      ))}
    </select>
  );

  if (!label) return selectTag;

  return (
    <div>
      <label
        className={[
          'top-0 left-0 flex items-center text-opacity-50 p-2 transition-all duration-200 ease-in-out',
          'text-xs w-40',
        ].join(' ')}>
        {label}
      </label>
      {selectTag}
    </div>
  );
};

export default Select;
