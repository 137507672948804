import { AlertMessage } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { AlertMessageProps } from '@gloabal-regulatory-writing-consulting/gxt-components/dist/components/alertMessage/AlertMessage';
import { useMemo } from 'react';
import { CatalogFilesUploadStatus } from '../../../../types/CatalogTypes';

const CatalogUploadAlertMessage = ({
  uploadFileStatus,
  onReportClick = () => {},
}: {
  uploadFileStatus: CatalogFilesUploadStatus[];
  onReportClick?: () => void;
}) => {
  const totalFileCount = uploadFileStatus.length;

  const { error, success, pending } = useMemo(
    () =>
      uploadFileStatus.reduce(
        (counts, { status }) => {
          counts[status] += 1;
          return counts;
        },
        { success: 0, error: 0, pending: 0 },
      ),
    [uploadFileStatus],
  );

  const alertProps: AlertMessageProps = {
    linkText: 'View Report',
    onLinkClick: onReportClick,
    alertMessage: '',
    display: totalFileCount > 0,
  };

  if (success === totalFileCount) {
    alertProps.alertType = 'positive';
    alertProps.alertMessage = 'All items have been successfully uploaded.';
  } else if (error === totalFileCount) {
    alertProps.alertType = 'negative';
    alertProps.alertMessage = 'Items encountered errors while uploading, please upload again.';
  } else {
    alertProps.alertType = 'warning';
    const pendingMessage = pending === 0 ? '' : ` ${pending} files pending upload and`;
    alertProps.alertMessage = `${success} items uploaded successfully, ${pendingMessage} ${error} encountered errors please reupload these files.`;
  }

  return <AlertMessage {...alertProps} />;
};

export default CatalogUploadAlertMessage;
