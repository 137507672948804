// Importing components
import SvgIcon from '../../../../components/elements/SvgIcon';
import { DeleteConfirmationDialogue } from '../../../../components/modals/DeleteConfirmationDialogue';
import { DEFAULT_COLORS } from '../../../../constants';
// Hooks
import useModal from '../../../../hooks/useModal';
import { useCatalogItems } from '../../../../services/api/catalogItems';
import CatalogItem from '../CatalogItem';

// Types
import { ItemActionsProps } from './ItemActions.types';

const ItemActions: React.FC<ItemActionsProps> = ({ row }) => {
  const {
    show: showDeleteModal,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const editSlideOver = useModal();

  const { archiveCatalogItem } = useCatalogItems();

  const handleDelete = async () => {
    if (row.id) archiveCatalogItem.mutate(row.id);

    closeDeleteModal();
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="flex items-start gap-2">
          <SvgIcon
            iconType="edit-light"
            className="cursor-pointer"
            data-testid={`edit-item-${row.id}`}
            onClick={editSlideOver.openModal}
            width="1.25rem"
          />
          <SvgIcon
            iconType="delete-light-border"
            className="cursor-pointer"
            data-testid={`delete-item-${row.id}`}
            onClick={openDeleteModal}
            width="1.25rem"
          />
        </div>
      </div>
      <DeleteConfirmationDialogue
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        handleDelete={handleDelete}
        action="Delete"
        description="Item name may be assigned to multiple documents"
        item="this item name"
        maxWidth="30rem"
        descriptionStyle={`text-[${DEFAULT_COLORS.negative._200}]`}
      />
      {editSlideOver.show && (
        <CatalogItem
          isOpen={editSlideOver.show}
          onClose={editSlideOver.closeModal}
          defaultValues={row}
        />
      )}
    </>
  );
};

export default ItemActions;
