import { useState } from 'react';

export default function useModal(initialState = false) {
  const [show, setShow] = useState(initialState);

  const closeModal = () => setShow(false);
  const openModal = () => setShow(true);

  return {
    show,
    setShow,
    closeModal,
    openModal,
  };
}
