import { AxiosResponse } from 'axios';
import axios from '../../../helpers/AxiosConfig';
import {
  CreateProjectParams,
  CreateProjectResponse,
  GetProjectsParams,
  GetProjectsResponse,
  ProjectFilterOptions,
  GetProjectPlaceholders,
} from './projectSettings.types';

export const getProjects = async (
  params: GetProjectsParams,
): Promise<AxiosResponse<GetProjectsResponse>> => {
  return axios.get<GetProjectsResponse>('/projects', {
    params,
  });
};

export const createNewProject = async (data: CreateProjectParams) => {
  return axios.post<CreateProjectResponse>('/project/create', data);
};

export const getProjectFilterOptions = async (): Promise<AxiosResponse<ProjectFilterOptions>> => {
  return axios.get<ProjectFilterOptions>('/projects/filter-options');
};

export const getProjectPlaceholders = async (
  projectId: number,
): Promise<AxiosResponse<GetProjectPlaceholders>> => {
  return axios.get<GetProjectPlaceholders>(`/project/${projectId}/placeholders`);
};
