import { gql } from '@apollo/client';

export const GET_CLIENT_BY_NAME = gql`
  query getClientByName($name: String!) {
    getClientByName(name: $name) {
      clientName
      thumbnail
      logo
      landingTitle
      styling {
        colors {
          primary {
            _100
            _200
            _300
            _400
            _50
          }
        }
      }
    }
  }
`;
