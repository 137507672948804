/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// React related imports
import { useMemo } from 'react';

// Components
import { Button, Header, Search } from '@gloabal-regulatory-writing-consulting/gxt-components';
import Layout from '../../components/layout';
import SvgIcon from '../../components/elements/SvgIcon';
import { PaginatedTable } from '../../components/PaginatedTable';
import ProjectSettingsFilterSlideOver from './components/ProjectSettingsFilterSlideOver';

// Hooks and Contexts
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';
import useModal from '../../hooks/useModal';

// Services and APIs
import { getProjects } from '../../services/api/projectSettings';

// Utilities
import { generateColumns } from './components/CreateProject/utils/ProjectSettingsColumns';

// Types
import { ProjectFilterParams, ProjectSortParams } from '../../services/api/types';
import { ProjectSettingsTabs } from './types';
import { useNavigate } from 'react-router-dom';

const ProjectSettings = () => {
  const navigate = useNavigate();

  const {
    setSearchByKey,
    paginatedFilteredData,
    handlePageChange,
    handlePerPageChange,
    handleColumnSort,
    setFilters,
    filters,
  } = usePaginatedFilters<ProjectFilterParams, ProjectSortParams>({
    search: {
      query: '',
      columns: ['id', 'name'],
    },
    sort: { type: 'DESC', column: 'id' },
  });

  const sortedColumn = useMemo(
    () => ({
      column: paginatedFilteredData.sort?.column || '',
      order: paginatedFilteredData.sort?.type || 'DESC',
    }),
    [paginatedFilteredData.sort?.column, paginatedFilteredData.sort?.type],
  );

  const columns = useMemo(() => generateColumns(handleColumnSort, sortedColumn), [sortedColumn]);
  const getFilteredPaginatedProjects = () => {
    return getProjects(paginatedFilteredData);
  };

  const slideOverFilter = useModal();
  const filtersCount = useMemo(() => {
    let count = 0;
    Object.entries(filters || {}).forEach(([_key, value]) => {
      if (Array.isArray(value)) {
        count += value.length;
      }
    });
    return count;
  }, [filters]);

  return (
    <Layout>
      <Layout.Header>
        <Header>
          <Header.Heading>Project Settings</Header.Heading>
        </Header>
      </Layout.Header>
      <Layout.Body>
        <div className="flex w-full gap-2">
          <Search
            onChangeCallback={(term) => {
              setSearchByKey('query', term);
            }}
            width="100%"
            className="h-full"
          />
          <Button
            variant="secondary"
            onClick={slideOverFilter.openModal}
            data-testid="filter-btn"
            className="flex justify-between items-center gap-3 whitespace-nowrap">
            {`Filter (${filtersCount})`}
            <SvgIcon date-testid={'filter-icon-svg'} iconType={'filter-icon'} />
          </Button>
          <Button
            variant="secondary"
            onClick={() => navigate('/createProject')}
            data-testid="create-btn">
            Create New
          </Button>
        </div>
        <PaginatedTable
          additionalColumns={columns}
          paginatedFilteredData={paginatedFilteredData}
          getData={getFilteredPaginatedProjects as any}
          isCheckboxRequired={false}
          handlePageChange={handlePageChange}
          handlePerPageChange={handlePerPageChange}
          queryKey={['projects', ProjectSettingsTabs.PROJECT_SETTINGS]}
          label="projects"
        />
        {slideOverFilter.show && (
          <ProjectSettingsFilterSlideOver
            show={slideOverFilter.show}
            closeModal={slideOverFilter.closeModal}
            filters={filters}
            handleApplyFilters={(filters) => filters && setFilters(filters)}
          />
        )}
      </Layout.Body>
    </Layout>
  );
};

export default ProjectSettings;
