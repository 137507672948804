import { FormEvent, useState } from 'react';

import Input from '../../components/elements/Input';
import ReactLoading from 'react-loading';

import { useAuth } from '../../contexts/AuthContext';
import { useDynamicStylingContext } from '../../contexts/DynamicStylingContext';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordModal } from './ResetPasswordModal';
const Login = () => {
  const [userData, setUserData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { signIn } = useAuth();

  const navigate = useNavigate();

  const { clientStyling } = useDynamicStylingContext();
  const { logo } = clientStyling;

  const inputList = [
    {
      label: 'Email',
      type: 'text',
      name: 'email',
      value: userData.email,
      placeholder: 'Enter Email',
    },
    {
      label: 'Password',
      type: 'password',
      name: 'password',
      value: userData.password,
      placeholder: 'Enter password',
    },
  ];

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const submitData = async (e: FormEvent) => {
    setDisabled(true);
    e.preventDefault();
    if (userData.email === '' || userData.password === '') {
      setError('Email/Password field should not be empty!');
      setDisabled(false);
      return;
    }
    try {
      const res = await signIn(userData.email, userData.password);
      if (res.success && res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate('/reset-password');
      } else if (res.success) {
        navigate('/');
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDisabled(false);
    }
  };

  const disableButton = () => {
    return disabled ? 'pointer-events-none bg-[#c9c9c9]' : 'bg-[#438FFE]';
  };

  return (
    <>
      <img src={logo} alt="logo" width={285} className="mx-auto mt-32" />
      <div>
        <div className="flex justify-center mt-19 px-6 py-8 mx-auto md:h-9/12 lg:py-0">
          <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-white border-gray-200">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <form onSubmit={(e) => submitData(e)}>
                <h1 className="text-xl text-center font-bold leading-tight tracking-tight  md:text-2xl text-black">
                  Sign in to your account
                </h1>
                <div className="space-y-4 md:space-y-6">
                  {inputList?.map((item, index) => (
                    <div key={index}>
                      <label
                        htmlFor={item.name}
                        className="block mb-2 text-sm font-medium text-gray-900">
                        {item.label}
                      </label>
                      <Input
                        type={item.type}
                        name={item.name}
                        value={item.value}
                        placeholder={item.placeholder}
                        onChange={onChangeHandler}
                        className={undefined}
                        dataTestId={undefined}
                      />
                    </div>
                  ))}
                  <button
                    type="submit"
                    onClick={submitData}
                    className={`w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center  ${disableButton()}`}>
                    {!disabled ? (
                      'Sign in'
                    ) : (
                      <ReactLoading
                        type={'spin'}
                        className="mx-auto"
                        color="white"
                        height={25}
                        width={30}
                      />
                    )}
                  </button>
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                </div>
              </form>
              <ResetPasswordModal />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
