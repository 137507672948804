import { useState, useEffect, useRef, RefObject, useMemo, useCallback } from 'react';
import { removeNullFalseEmptyStringKeys } from '../helpers/utils';

type queryTypes = {
  type: string;
  statusType: string;
  columnSort?: string;
  sortType?: string;
};

const THRESHOLD = 50;

export const useInfiniteScroll = (
  types: queryTypes = { type: '', statusType: '' },
  containerRef: RefObject<HTMLElement>,
  fetchData: (data: any) => any,
  pageSize = 20,
  queryParams: any = {},
  dataSetterCallback?: (data: any) => void,
) => {
  const [combinedData, setcombinedData] = useState<any>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const lastQueryData = useRef<any>(null);
  const page = useRef<number>(1);
  const [response, setResponse] = useState<any>([]);

  const queryData = useMemo(() => {
    const params = {
      page: page.current,
      perPage: pageSize,
      ...removeNullFalseEmptyStringKeys(types),
    };
    return queryParams ? { ...params, ...removeNullFalseEmptyStringKeys(queryParams) } : params;
  }, [queryParams, pageSize, page, types]);

  const hasQueryChanged = JSON.stringify(queryParams) !== JSON.stringify(lastQueryData.current);

  interface IQueryData {
    page: number;
    perPage: number;
  }

  const setData = (data: any) => {
    if (dataSetterCallback) dataSetterCallback(data);
    setcombinedData(data);
  };

  const fetchPageData = useCallback(
    async (queryVar: IQueryData = queryData, lastPage = isLastPage) => {
      if (isDataLoading || !fetchData || lastPage) return;
      setIsDataLoading(true);
      try {
        lastQueryData.current = queryParams;
        const { data } = await fetchData(queryVar);
        setResponse(data);
        const key = Object.keys(data).find((key) => Array.isArray(data[key]));
        if (key && !!data[key]) {
          if (queryVar.page === 1) {
            setIsLastPage(data[key].length === data.total);
            setData(data[key]);
          } else {
            setIsLastPage(data[key].length + combinedData.length === data.total);
            setData((prevData: any) => [...prevData, ...data[key]]);
          }
        }
        page.current = queryVar.page;
      } catch (error) {
        console.error(error);
      } finally {
        setIsDataLoading(false);
      }
    },
    [isDataLoading, fetchData, pageSize, queryParams, isLastPage],
  );

  const refreshData = useCallback(() => {
    setIsLastPage(false);
    page.current = 1;
    fetchPageData({ ...queryData, perPage: pageSize, page: page.current }, false);
  }, [fetchPageData, queryParams]);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = async () => {
      if (!container || isDataLoading || isLastPage) return;
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - THRESHOLD && lastQueryData.current) {
        await fetchPageData({ ...queryData, page: page.current + 1 });
      }
    };
    container?.addEventListener('scroll', handleScroll);
    return () => container?.removeEventListener('scroll', handleScroll);
  }, [containerRef, isDataLoading, isLastPage, fetchPageData]);

  useEffect(() => {
    if (hasQueryChanged) refreshData();
  }, [queryParams]);

  return { data: combinedData, isDataLoading, fetchPageData, refreshData, response };
};
