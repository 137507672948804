import { useState, useContext, ChangeEvent, useEffect } from 'react';
import ReactSlider from 'react-slider';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

import { SourceDocuments } from '../../components/SourceDocuments';
import { CatalogContext } from '../../contexts/CatalogContext';
import { Search } from '../../components/Search';
import Button from '../../components/elements/Button';
import ModalHeader from '../../components/modals/ModalHeader';
import Input from '../../components/elements/Input';
import BreadCrumbs from '../../components/elements/BreadCrumbs';

import { convertBlobToBase64, convertFileToHTML } from '../../helpers/convertFileType';
import { autoMapping } from '../../services/apiCalls';
import { useFilterStates } from '../../hooks/useFilterStates';
import Dropdown from '../../components/elements/dropdown';
import { modalCustomStyles } from '../../constants';
import { AutomappingScreenFilterType, SourceDocumentTypeEnum } from '../../types';
import LoadingOverlay from '../../components/elements/LoadingOverlay';
import { notifyError } from '../../helpers/utils';

const AutoMappingScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAutoMapModal, setShowAutoMapModal] = useState(false);
  const [error, setError] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [threshold, setThreshold] = useState(80);
  const [title, setTitle] = useState('');

  const { filters, setFilters } = useFilterStates<AutomappingScreenFilterType>({
    columnSort: '',
    createdById: '',
    documentCat: '',
    documentType: 'Source',
    program: '',
    sortType: '',
    text: '',
  });

  const { program, documentCat, createdById } = filters;

  type TargetObject = {
    originalName?: string;
    file?: File;
  };
  const [targetFile, setTargetFile] = useState<TargetObject>({});
  const {
    selectedSourceDocuments,
    setSelectedSourceDocuments,
    refreshCatalogFiles,
    dropdownUsersData,
    dropdownProgramsData,
    dropdownDocCatData,
  } = useContext(CatalogContext);

  const onFileChangeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files?.length) return;
    const target = {
      originalName: files[0].name,
      file: files[0],
    };
    setTargetFile(target);
  };

  useEffect(() => {
    refreshCatalogFiles(SourceDocumentTypeEnum.Source);
  }, []);

  const handleSliderChange = (newValue: number) => {
    setThreshold(newValue);
  };

  const handleClose = () => {
    setError('');
    setShowAutoMapModal(false);
  };

  const isDataValidated = () => {
    if (title === '') {
      setError('Required fields should not be empty!');
      return false;
    }
    return true;
  };

  const resetStates = () => {
    setTitle('');
    handleClose();
    setSelectedSourceDocuments([]);
  };

  const handleAutoMapping = async () => {
    if (!isDataValidated() || !targetFile.file) return;
    const targetObject = await convertFileToHTML(targetFile.file);
    const targetHtml = await convertBlobToBase64(targetObject?.value);
    const data = {
      title,
      targetFileValue: targetHtml,
      originalName: targetFile.originalName,
      fuzzyThreshold: threshold / 100,
      sourceFileIds: selectedSourceDocuments,
    };
    resetStates();
    setIsLoading(true);
    autoMapping(data)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateData = () => {
    if (!targetFile.file || selectedSourceDocuments.length === 0) {
      toast.error('select required data!');
    } else {
      setShowAutoMapModal(true);
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>, type: string) => {
    switch (type) {
      case 'updatedby':
        setFilters('createdById', e.target.value);
        break;
      case 'program':
        setFilters('program', e.target.value);
        break;
      case 'docCat':
        setFilters('documentCat', e.target.value);
        break;
      default:
        notifyError('Filter is not selected');
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <BreadCrumbs
        className="text-start mt-8 mb-10 identifiers"
        data={[
          { link: '/', text: 'Home' },
          { text: 'Mapping', clickable: false },
        ]}
      />
      <div className="mt-20 mr-6 p-4 rounded-md bg-white">
        <Modal
          isOpen={showAutoMapModal}
          onRequestClose={handleClose}
          style={modalCustomStyles}
          ariaHideApp={false}>
          <div className="space-y-4 sm:p-8">
            <ModalHeader title="Auto Map" closeModal={handleClose} />
            <h3> Please enter a title: </h3>
            <Input
              type={'text'}
              name={'title'}
              value={title}
              placeholder={'Enter title'}
              onChange={(e: any) => setTitle(e.target.value)}
              className="w-72"
              dataTestId="target-title-input"
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}

            <div className="flex justify-between">
              <Button
                data-testid="close-modal-btn"
                onClick={handleClose}
                title="Cancel"
                className={`text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-red-500`}
              />
              <Button
                data-testid="confirm-auto-map-btn"
                onClick={handleAutoMapping}
                title="Auto Map"
                className={`text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-secondary_bg_color `}
              />
            </div>
          </div>
        </Modal>
        <div className="flex justify-between mr-8 mt-5">
          <div className="">
            <div className="w-10/12 text-sm text-left mb-4 font-bold">Target Document</div>
            <input
              data-testid="target-file-input"
              name={'file'}
              onChange={onFileChangeHandler}
              type="file"
              className="block mb-5 w-72 text-sm text-slate-500 border border-primary_border_color rounded-md p-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-white file:text-primary_text_color hover:file:bg-blue-100"
              title={targetFile.originalName}
            />

            <div className="w-10/12 mt-10 text-sm text-left font-bold">Threshold</div>
            <div className="mt-5 w-72">
              <ReactSlider
                className="slider"
                thumbClassName="border-solid border border-2 bg-black rounded-md text-white border-black m-1 rounded-50"
                trackClassName=" h-3 bg-gray-300"
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                value={threshold}
                onChange={handleSliderChange}
              />
            </div>
            <div className="mt-20">
              <Search onChangeCallback={(term) => setFilters('text', term)} />
            </div>
          </div>
          <div className="flex justify-end items-end -mb-2 space-x-2">
            <Dropdown
              options={dropdownDocCatData}
              placeholder="Category"
              changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleFilterChange(e, 'docCat')
              }
              selectedValue={documentCat}
            />
            <Dropdown
              options={dropdownProgramsData}
              placeholder="Program"
              changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleFilterChange(e, 'program')
              }
              selectedValue={program}
            />
            <Dropdown
              options={dropdownUsersData}
              placeholder="Uploaded By"
              changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleFilterChange(e, 'updatedby')
              }
              selectedValue={createdById}
            />
          </div>
        </div>
        <div className=" text-sm text-left font-bold">Source Documents</div>
        <div className=" mr-8 overflow-y-auto my-4" style={{ height: '60vh' }}>
          <SourceDocuments
            filters={filters}
            setFilters={setFilters}
            setIsLoading={setIsLoading}
            selectedSourceDocuments={selectedSourceDocuments}
            setSelectedSourceDocuments={setSelectedSourceDocuments}
            selectedTargetId={selectedTemplateId}
            setSelectedTarget={setSelectedTemplateId}
            originPath="auto-mapping"
            page={true}
            selectAllOption={true}
          />
        </div>
        <div className="flex justify-end mr-8">
          <Button
            type="button"
            data-testid="auto-map-button"
            onClick={() => validateData()}
            title="Auto Map"
            className="px-4 py-2 text-white bg-primary_bg_color font-medium rounded-lg hover:bg-secondary_bg_color"
          />
        </div>
      </div>
    </>
  );
};

export default AutoMappingScreen;
