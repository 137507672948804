import { useEffect, useMemo, useState } from 'react';

export const useGeneralizationNavigation = (
  cells: string[],
  initialIndex = 0,
  currentCell: string | null = null,
) => {
  const [index, setIndex] = useState<number>(initialIndex);
  const [isNextCellAvailable, setNextCellAvailable] = useState<boolean>(false);
  const [isPreviousCellAvailable, setPreviousCellAvailable] = useState<boolean>(false);

  const navigateToCell = (direction: 'next' | 'previous'): string => {
    let newIndex = index + (direction === 'next' ? 1 : -1);

    if (newIndex >= cells.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = cells.length - 1;
    }
    setIndex(newIndex);
    return cells[newIndex];
  };

  const updateIndex = (nodeId: string) => {
    const newIndex = cells.indexOf(nodeId);
    if (newIndex !== -1) setIndex(newIndex);
  };

  const resetIndex = () => {
    setIndex(-1);
  };

  const nextStep = (nodeId: string): string | null => {
    const newIndex = cells.indexOf(nodeId);

    if (newIndex !== -1) {
      const nextIndex = (newIndex + 1) % cells.length;
      setIndex(nextIndex);
      return cells[nextIndex];
    }

    return null;
  };

  const initialCell = useMemo(() => cells[index], [cells, index]);

  useEffect(() => {
    setNextCellAvailable(index + 1 < cells.length);
    setPreviousCellAvailable(index > 0);
  }, [index, cells]);

  useEffect(() => {
    currentCell && updateIndex(currentCell);
  }, [currentCell, cells]);

  return {
    isNextCellAvailable,
    isPreviousCellAvailable,
    navigateToCell,
    updateIndex,
    initialCell,
    nextStep,
    resetIndex,
  };
};
