import { FC, useState } from 'react';
import SvgIcon from './elements/SvgIcon';
import { DeleteConfirmationDialogue } from './modals/DeleteConfirmationDialogue';

interface IDeleteConfirmationModal {
  deleteHandler: (id: number) => void;
  id: number;
}

export const DeleteConfirmationModal: FC<IDeleteConfirmationModal> = ({ deleteHandler, id }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleDelete = () => {
    deleteHandler(id);
    handleClose();
  };

  return (
    <>
      <DeleteConfirmationDialogue
        isOpen={show}
        handleClose={() => setShow(false)}
        item="file"
        handleDelete={handleDelete}
      />
      <button onClick={() => setShow(true)}>
        <SvgIcon iconType="delete-light" />
      </button>
    </>
  );
};
