// React related imports
import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

// Components
import { Input } from '@gloabal-regulatory-writing-consulting/gxt-components';
import InputError from '../../../../../components/inputError/InputError';

// Contexts and Hooks
import { useDebounce } from '../../../../../hooks/useDebounce';
import { useCatalog } from '../../../../../services/api';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Types
import { IAuthoringFields } from './types';

const StartAuthoringFields: FC<IAuthoringFields> = ({ control, errors }) => {
  const [sectionOptions, setSectionOptions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { getColumn } = useCatalog();
  const { mutateAsync: getCatalogColumn } = getColumn;

  const debounceValue = useDebounce(searchTerm, 500);

  const handleOnChange = (fieldOnChange: (...event: any[]) => void, value: string) => {
    fieldOnChange(value || null);
    setSearchTerm(value);
  };

  useEffect(() => {
    if (searchTerm) {
      getCatalogColumn({ column: 'section', search: debounceValue }).then((res) => {
        const searchResponse = res.data.data;
        const options = searchResponse.length ? searchResponse : [`${searchTerm}`];
        setSectionOptions(options);
      });
    } else {
      setSectionOptions([]);
    }
  }, [debounceValue]);

  return (
    <form className="flex flex-col items-start gap-6 w-[50rem]">
      <div className="flex flex-col items-start gap-2 self-stretch">
        <Controller
          name="documentName"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              inputSize={'small'}
              label={'Document Name*'}
              primary
              placeholder="Document Name"
              error={!!errors['documentName']?.message}
              helpText={<InputError errors={errors} field={'documentName'} />}
            />
          )}
        />
      </div>
      <div className="flex flex-col items-start gap-2 self-stretch w-1/2">
        <p className={errors['section'] ? 'text-primary-300' : 'text-negative-200'}>Section</p>
        <Controller
          name="section"
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <Autocomplete
                {...field}
                placeholder="Add Section"
                options={sectionOptions}
                getOptionLabel={(option) => option}
                onChange={(_event, value) => handleOnChange(field.onChange, value)}
                fullWidth={true}
                clearOnBlur={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Section"
                    onChange={(event) => handleOnChange(field.onChange, event.currentTarget.value)}
                    size="small"
                    error={!!errors['section']}
                  />
                )}
              />
              <InputError errors={errors} field={'section'} />
            </div>
          )}
        />
      </div>
      <div className="flex flex-col items-start gap-2 self-stretch w-1/2">
        <Controller
          name="version"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              inputType={'number'}
              min={0}
              step={0.1}
              inputSize={'small'}
              label={'Version*'}
              primary
              placeholder="0.0"
              error={!!errors['version']?.message}
              helpText={<InputError errors={errors} field={'version'} />}
            />
          )}
        />
      </div>
    </form>
  );
};

export default StartAuthoringFields;
