import { useContext, useState } from 'react';
import { MappingContext } from '../../contexts/MappingContext';
import AddCommentModal from '../../components/modals/AddCommentModal';
import { IUseGeneralizationComments } from '../../types';
import Comment from '../../components/Comment';

export const useGeneralizationComments = (
  currentSuggestionId: number,
): IUseGeneralizationComments => {
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [comments, setComments] = useState<JSX.Element[] | null>(null);
  const { isCommentDisplayed, toggleComments, mappingComments } = useContext(MappingContext);

  const openCommentModal = () => {
    setCommentModalIsOpen(true);
  };

  const openCommentModalWithThread = (id: string) => {
    const filteredAndMappedComments = mappingComments.reduce((acc: JSX.Element[], comment) => {
      if (comment.targetNodeId === id) {
        acc.push(
          <Comment
            key={comment.commentNumber}
            name={`${comment.firstName} ${comment.lastName}`}
            message={comment.comment}
          />,
        );
      }
      return acc;
    }, []);
    setComments(filteredAndMappedComments);
    setCommentModalIsOpen(true);
  };

  const closeCommentModal = () => {
    setCommentModalIsOpen(false);
  };

  const commentModal = (
    <AddCommentModal
      commentModalIsOpen={commentModalIsOpen}
      closeModal={closeCommentModal}
      suggestionId={currentSuggestionId}
      comments={comments || []}
    />
  );

  return {
    openCommentModal,
    isCommentDisplayed,
    toggleComments,
    commentModal,
    openCommentModalWithThread,
  };
};
