import ReactLoading from 'react-loading';
import { LOADER_COLOR } from '../../constants';

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 bg-opacity-50 bg-gray-500 flex items-center justify-center z-50">
      <ReactLoading type="spin" color={LOADER_COLOR} />
    </div>
  );
};

export default LoadingOverlay;
