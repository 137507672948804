// React-related imports
import {
  ChangeEvent,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

// React Hook Form imports
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

// Component imports
import { Avatar, Input, TagsField } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { InputError } from '../../../../components/inputError';
import { RolesSlideOver } from '../RolesSlideOver';

// Type and utility imports
import { CreateUserFormProps, CreateUserFormValues } from './CreateUserForm.types';
import { inputList, userSchema } from './utils';
import { capitalizeFirstLetter } from '../../../../helpers/utils';

// Hook imports
import { useImageUploader } from '../../../../hooks/useImageUploader';
import useModal from '../../../../hooks/useModal';

// Context imports
import { UserContext } from '../../../../contexts/UserContext';

// API imports
import { fetchUserByEmail } from '../../../../services/api';
import { useQuery } from '@tanstack/react-query';
import fetchRoles from '../utils/userRolesAPI';

const CreateUserForm = forwardRef(
  ({ handleSubmitData, defaultValues, updateFormValidity }: CreateUserFormProps, ref) => {
    const {
      control,
      handleSubmit,
      reset,
      watch,
      setError,
      clearErrors,
      formState: { errors, isValid },
    } = useForm<CreateUserFormValues>({
      defaultValues,
      resolver: zodResolver(userSchema),
      mode: 'onChange',
    });

    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
    const [isChecking, setIsChecking] = useState(false);

    const onSubmit: SubmitHandler<CreateUserFormValues> = (data: CreateUserFormValues) => {
      handleSubmitData({
        ...data,
        avatar: imageUrl,
        roles: selectedRolesOptions.map((role) => role.value),
      });
    };

    const { imageUrl, uploadImageToS3 } = useImageUploader();

    const { userObj } = useContext(UserContext);
    const { firstName, lastName } = userObj;

    const rolesSlideOver = useModal();

    const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
      const imageFile = e.target.files?.[0];
      if (!imageFile) return;

      const userId = `${firstName?.[0] || ''}${lastName || ''}`.toLowerCase().replace(/\s/g, '');
      const fileName = `${userId || 'user'}-avatar`;
      await uploadImageToS3(imageFile, fileName);
    };

    const { data: rolesHash, isLoading: rolesLoading } = useQuery({
      queryKey: ['Roles'],
      queryFn: fetchRoles,
      placeholderData: [],
    });

    const selectedRolesOptions = useMemo(
      () =>
        rolesHash
          ? selectedRoles.reduce(
              (acc, id) => {
                const role = rolesHash[id];
                if (role) {
                  acc.push({ value: role.name, label: capitalizeFirstLetter(role.name) });
                }
                return acc;
              },
              [] as { value: string; label: string }[],
            )
          : [],
      [selectedRoles, rolesHash],
    );

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues, reset]);

    useEffect(() => {
      const checkEmail = async (email: string) => {
        setIsChecking(true);
        try {
          const user = await fetchUserByEmail({ email });
          if (user) {
            setError('email', {
              type: 'manual',
              message: 'Email already exists',
            });
          } else {
            clearErrors('email');
          }
        } catch (error) {
          console.error('Error checking email:', error);
        } finally {
          setIsChecking(false);
        }
      };

      const subscription = watch((value, { name }) => {
        if (name === 'email' && value.email) {
          const result = userSchema.pick({ email: true }).safeParse({ email: value.email });

          if (result.success) {
            checkEmail(value.email as string);
          }
        }
      });
      return () => subscription.unsubscribe();
    }, [watch, setError, clearErrors]);

    useEffect(() => {
      updateFormValidity(
        isValid && !isChecking && selectedRolesOptions.length > 0 && !errors.email,
      );
    }, [isValid, updateFormValidity, isChecking, selectedRolesOptions]);

    return (
      <>
        <form className="flex flex-col items-start gap-10 flex-1 flex-shrink-0 basis-0">
          <Avatar
            user={{
              firstName: watch('firstName'),
              lastName: watch('lastName'),
            }}
            size="large"
            handleImageUpload={handleImageUpload}
            imageUrl={imageUrl}
          />
          {inputList.map((input) => (
            <Controller
              key={input.name}
              {...input}
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id={input.name}
                    data-testId={input.name}
                    label={input.label}
                    placeholder={input.placeholder}
                    isFilled={!!field.value}
                    customStyles={{
                      container: { width: '23.75rem' },
                    }}
                    error={!!errors[input.name]?.message}
                    helpText={<InputError errors={errors} field={input.name} />}
                  />
                </>
              )}
            />
          ))}
          <TagsField
            value={selectedRolesOptions}
            onClick={rolesSlideOver.openModal}
            disabled={rolesLoading}
            label="Roles"
            className="w-[23.75rem]"
          />
        </form>

        {rolesSlideOver.show && !rolesLoading && (
          <RolesSlideOver
            isOpen={rolesSlideOver.show}
            onClose={rolesSlideOver.closeModal}
            handleSave={(selectedRoles) => {
              setSelectedRoles(selectedRoles);
              rolesSlideOver.closeModal();
            }}
            onCloseSlideOver={rolesSlideOver.closeModal}
            selectedRoles={selectedRoles}
            allRoles={Object.values(rolesHash ?? {})}
          />
        )}
      </>
    );
  },
);

CreateUserForm.displayName = 'CreateUserForm';

export default CreateUserForm;
