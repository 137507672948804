import { useContext } from 'react';
import ReactLoading from 'react-loading';

import { Context } from '../routes/PrivateRoutes';
import { convertAllFilesToHTML } from '../helpers/convertFileType';
import { cloneDeep } from 'lodash';
import { LOADER_COLOR } from '../constants';

const MultipleFileUploader = ({ title }) => {
  const {
    setShowLoader,
    showLoader,
    multipleFile,
    setMultipleFile,
    isButtonDisable,
    setIsButtonDisable,
  } = useContext(Context);

  const multipleFileHandler = (event) => {
    const files = Object.values(event.target.files);
    if (files.length > 0) {
      setShowLoader({ ...showLoader, multipleFileLoader: true });
      convertAllFilesToHTML(files)
        .then((res) => {
          const result = res.map((o) => ({ ...o, isChanged: false }));
          setMultipleFile(cloneDeep([...multipleFile, ...result]));
          setShowLoader({ ...showLoader, multipleFileLoader: false });
          setIsButtonDisable({ ...isButtonDisable, multipleFileSelected: false });
        })
        .catch((err) => {
          setShowLoader({ ...showLoader, multipleFileLoader: false });
          console.error(err);
        });
    }
  };

  return (
    <div className="shrink-0">
      {!!title?.length && <p className="mb-3 text-black font-bold text-sm">{title}</p>}
      <div className="flex justify-center items-center space-x-2">
        <input
          type="file"
          multiple
          onChange={multipleFileHandler}
          className="block w-full text-sm text-slate-500 border border-primary_border_color rounded-md p-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-white file:text-primary_text_color hover:file:bg-blue-100"
        />
        {showLoader.multipleFileLoader && (
          <ReactLoading
            type={'spin'}
            data-testId={'react-loading'}
            color={LOADER_COLOR}
            height={25}
            width={30}
          />
        )}
      </div>
    </div>
  );
};

export default MultipleFileUploader;
