import { Auth } from 'aws-amplify';
import { notifyError } from './utils';

export const signInUser = async (username: string, password: string) => {
  try {
    const response = await Auth.signIn(username, password);
    return { success: true as const, data: response };
  } catch (error: any) {
    return sendErrorResponse(error);
  }
};

export const signOutUser = async () => await Auth.signOut();

export const getCurrentUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return { success: true as const, user };
  } catch (error: any) {
    return sendErrorResponse(error);
  }
};

export const getAccessJwtToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (error: any) {
    console.error(error);
    notifyError(error.message);
    return '';
  }
};

export const getIdJwtToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error: any) {
    console.error(error);
    notifyError(error.message);
    return '';
  }
};

export const sendResetPasswordCode = async (username: string, tenantDomain: string) => {
  try {
    await Auth.forgotPassword(username, { tenantDomain });
  } catch (error: any) {
    return sendErrorResponse;
  }
};

export const resetPassword = async (username: string, code: string, password: string) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, password);
    return { success: true as const };
  } catch (error: any) {
    return sendErrorResponse(error);
  }
};

export const updateCognitoUserAttributes = async (
  attributes: Array<{
    Name: string;
    Value: string;
  } | null>,
) => {
  const user = await Auth.currentAuthenticatedUser();
  const attributesObject: { [key: string]: string } = {};
  attributes.forEach((attribute) => {
    if (attribute) {
      attributesObject[attribute.Name] = attribute.Value;
    }
  });
  const result = await Auth.updateUserAttributes(user, attributesObject);
  return { data: result };
};

const sendErrorResponse = (error: any) => {
  return { success: false as const, error: error, message: error.message };
};
