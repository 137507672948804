// React-related imports
import { FC, useRef } from 'react';

// Component imports
import { Button, SlideOver } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { SourceTargetForm } from './components/SourceTargetForm';

// Types
import { ImageTaggingProps } from './ImageTagging.types';

// Constants
import { initialImageTagValues } from '../../utils/constants';

const ImageTagging: FC<ImageTaggingProps> = ({
  isOpen,
  onClose,
  handleTag,
  initialFormValues = initialImageTagValues,
}) => {
  const formRef = useRef<{ submitForm: () => void }>(null);

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} width="28rem" mountElementId="styled-wrapper">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        Image Tagging
      </SlideOver.Header>
      <SourceTargetForm
        ref={formRef}
        handleSubmitData={handleTag}
        defaultValues={initialFormValues}
      />
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" onClick={handleFormSubmit}>
            Tag
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default ImageTagging;
