import React, { createContext, ReactNode, useMemo, useState } from 'react';
import Modal from 'react-modal';
import ModalHeader from '../components/modals/ModalHeader';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { LOADER_COLOR, modalCustomStyles } from '../constants';
import Button from '../components/elements/Button';
import { CatalogNotificationContextType, CatalogNotification } from '../types';

const defaultCatalogNotificationContext: CatalogNotificationContextType = {
  catalogNotifications: [],
  addCatalogNotification: () => {},
  removeCatalogNotification: () => {},
  openCatalogNotificationModal: () => {},
  closeCatalogNotificationModal: () => {},
  setCatalogNotifications: () => {},
};

export const CatalogNotificationContext = createContext<CatalogNotificationContextType>(
  defaultCatalogNotificationContext,
);

interface Props {
  children: ReactNode;
}

const CatalogNotificationContextContainer: React.FC<Props> = ({ children }) => {
  const [catalogNotifications, setCatalogNotifications] = useState<CatalogNotification[]>([]);
  const [isCatalogNotificationModalOpen, setIsCatalogNotificationModalOpen] = useState(false);

  const addCatalogNotification = (newNotification: CatalogNotification[]) => {
    setCatalogNotifications([...catalogNotifications, ...newNotification]);
    openCatalogNotificationModal();
  };

  const removeCatalogNotification = (id: number) => {
    const updatedNotifications = catalogNotifications.filter(
      (notification) => notification.id !== id,
    );
    setCatalogNotifications(updatedNotifications);
  };

  const openCatalogNotificationModal = () => {
    setIsCatalogNotificationModalOpen(true);
  };

  const closeCatalogNotificationModal = () => {
    const allOtherThanInProgress = catalogNotifications.every(
      (notification) => notification.status !== 'In Progress',
    );

    if (allOtherThanInProgress) {
      setCatalogNotifications([]);
    }

    setIsCatalogNotificationModalOpen(false);
  };

  const sortedNotifications = useMemo(() => {
    const pendingNotifications = catalogNotifications.filter(
      (notification) => notification.status === 'In Progress',
    );
    const failedNotifications = catalogNotifications.filter(
      (notification) => notification.status === 'Failed to Upload',
    );
    const successfulNotifications = catalogNotifications.filter(
      (notification) => notification.status === 'Uploaded Successfully',
    );

    const sortedPending = pendingNotifications.sort((a, b) => a.title.localeCompare(b.title));
    const sortedFailed = failedNotifications.sort((a, b) => a.title.localeCompare(b.title));
    const sortedSuccessful = successfulNotifications.sort((a, b) => a.title.localeCompare(b.title));

    return [...sortedPending, ...sortedFailed, ...sortedSuccessful];
  }, [catalogNotifications]);

  const getStatusIcon = (status: 'In Progress' | 'Uploaded Successfully' | 'Failed to Upload') => {
    switch (status) {
      case 'In Progress':
        return <ReactLoading type="spin" color={LOADER_COLOR} width={18} height={18} />;
      case 'Uploaded Successfully':
        return <FiCheckCircle className="text-green-500 text-lg" />;
      case 'Failed to Upload':
        return <FiXCircle className="text-red-500 text-lg" />;
      default:
        return null;
    }
  };

  const getNotificationBackgroundColor = (status: string): string => {
    switch (status) {
      case 'In Progress':
        return 'bg-in_progress';
      case 'Uploaded Successfully':
        return 'bg-success';
      case 'Failed to Upload':
        return 'bg-error';
      default:
        return 'bg-background_grey';
    }
  };

  return (
    <CatalogNotificationContext.Provider
      value={{
        catalogNotifications,
        addCatalogNotification,
        removeCatalogNotification,
        openCatalogNotificationModal,
        closeCatalogNotificationModal,
        setCatalogNotifications,
      }}>
      {children}
      <Modal
        isOpen={isCatalogNotificationModalOpen}
        onRequestClose={closeCatalogNotificationModal}
        ariaHideApp={false}
        contentLabel="Catalog Notification Modal"
        className="absolute overflow-y-hidden"
        style={modalCustomStyles}>
        <div className="p-10 bg-white">
          <ModalHeader
            title="File Upload Status"
            closeModal={closeCatalogNotificationModal}
            parentBoxStyle="mb-5"
          />
          <div className="overflow-y-auto max-h-[60vh]">
            {sortedNotifications.map((notification) => (
              <div
                key={notification.title}
                className={`p-3 rounded-md shadow-md mb-2 ${getNotificationBackgroundColor(
                  notification.status,
                )}`}>
                <div className="flex items-center">
                  <div className="mr-2">{getStatusIcon(notification.status)}</div>
                  <div>
                    <p className="font-semibold">{notification.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end min-h-fit">
            <Button
              title="Done"
              onClick={closeCatalogNotificationModal}
              className="mt-6 ml-auto bg-primary_bg_color text-white p-2 rounded-lg hover:bg-secondary_bg_color transition-colors duration-300"
            />
          </div>
        </div>
      </Modal>
    </CatalogNotificationContext.Provider>
  );
};

export default CatalogNotificationContextContainer;
