declare global {
  interface Window {
    env?: {
      REACT_APP_BACKEND_URL?: string;
      REACT_APP_TINYMCE_API_KEY?: string;
      REACT_APP_PDF2HTML_URL?: string;
      REACT_APP_DP_URL?: string;
      REACT_APP_AWS_S3_BUCKET_NAME?: string;
      REACT_APP_GRAPHQL_ENDPOINT?: string;
      REACT_APP_AUTH_URL?: string;
      REACT_APP_AWS_REGION?: string;
      REACT_APP_AUTH_REGION?: string;
      REACT_APP_AUTH_USER_POOL_ID?: string;
      REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID?: string;
      REACT_APP_PROJECT_NAME?: string;
      REACT_APP_USE_STATIC_PROJECT_NAME?: boolean;
    };
  }
}

const envVariables = { ...process.env, ...window.env };

export const REACT_APP_BACKEND_URL: string | undefined = envVariables.REACT_APP_BACKEND_URL;
export const REACT_APP_TINYMCE_API_KEY: string | undefined = envVariables.REACT_APP_TINYMCE_API_KEY;
export const REACT_APP_PDF2HTML_URL: string | undefined = envVariables.REACT_APP_PDF2HTML_URL;
export const REACT_APP_DP_URL: string | undefined = envVariables.REACT_APP_DP_URL;
export const REACT_APP_AWS_S3_BUCKET_NAME: string | undefined =
  envVariables.REACT_APP_AWS_S3_BUCKET_NAME;
export const REACT_APP_GRAPHQL_ENDPOINT: string | undefined =
  envVariables.REACT_APP_GRAPHQL_ENDPOINT;
export const REACT_APP_AUTH_URL: string | undefined = envVariables.REACT_APP_AUTH_URL;
export const REACT_APP_USE_STATIC_PROJECT_NAME: boolean | undefined =
  envVariables.REACT_APP_USE_STATIC_PROJECT_NAME;
export const REACT_APP_PROJECT_NAME: string | undefined = envVariables.REACT_APP_PROJECT_NAME;

export const AWS_CONFIG_AUTH = {
  aws_project_region: envVariables.REACT_APP_AWS_REGION,
  aws_cognito_region: envVariables.REACT_APP_AUTH_REGION,
  aws_user_pools_id: envVariables.REACT_APP_AUTH_USER_POOL_ID,
  aws_user_pools_web_client_id: envVariables.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
};
