import { FC } from 'react';
import { Modal, Button } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { DeleteModalProps } from './DeleteConfirmationDialogue.types';

const DeleteConfirmationDialogue: FC<DeleteModalProps> = ({
  maxHeight,
  overlayBackground,
  overlay = true,
  isOpen,
  handleClose,
  handleDelete,
  item = '',
  description = '',
  action = 'Delete',
  maxWidth = '24rem',
  descriptionStyle,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      overlayBackground={overlayBackground}
      overlay={overlay}
      onClose={handleClose}>
      <Modal.ModalHeader>
        <Modal.ModalHeaderHeading>
          {action}
          {action === 'Delete' ? '?' : ''}
        </Modal.ModalHeaderHeading>
        <Modal.ModalHeaderBody>
          Are you sure you want to {action.toLowerCase()}
          {item ? ` ${item}?` : '?'}
        </Modal.ModalHeaderBody>
        {description && (
          <Modal.ModalHeaderBody>
            <div
              className={`text-center flex justify-center font-bold self-center text-warning-200 ${descriptionStyle}`}>
              {description}
            </div>
          </Modal.ModalHeaderBody>
        )}
      </Modal.ModalHeader>
      <Modal.ModalFooter>
        <Button variant="primary" onClick={handleDelete}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationDialogue;
