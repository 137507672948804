export const DropdownCustomStyles = {
  placeholder: { color: 'var(--neutral-400)', justifyContent: 'left' },
  itemsWrapper: { zIndex: 100, width: '100%' },
  container: { width: '100%' },
  button: { width: '100%', justifyContent: 'left' },
  header: { display: 'none' },
  label: {
    color: 'var(--primary-300, #11587)',
  },
  item: {
    display: 'flex',
    padding: '0.5rem 1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
} as const;

export const defaultCatalogItem = {
  name: '',
};
