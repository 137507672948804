import React, { FC, ReactNode, useContext } from 'react';
import { GeneralizationContext } from '../../contexts/GeneralizationContext';

type LayoutProps = {
  children: ReactNode;
};

export const Header: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;
export const Body: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;

type LayoutType = FC<LayoutProps> & {
  Header: typeof Header;
  Body: typeof Body;
};

const Layout: LayoutType = ({ children }) => {
  const { isFullScreen } = useContext(GeneralizationContext);

  return (
    <>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return child;

        if (child.type === Header) {
          return isFullScreen ? null : <div className="mb-8">{child}</div>;
        }

        if (child.type === Body) {
          return (
            <div className={`h-full px-5 flex-grow ${isFullScreen ? 'pt-6 pb-5' : 'pt-0 pb-10'}`}>
              <div
                className={`flex flex-col items-start gap-[2rem] flex-1 basis-0 grow-0 bg-system-50 rounded-lg shadow-base h-full ${
                  isFullScreen ? 'p-5' : 'p-10'
                }`}>
                {child}
              </div>
            </div>
          );
        }

        return child;
      })}
    </>
  );
};

Layout.Header = Header;
Layout.Body = Body;

export default Layout;
