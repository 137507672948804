import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface IBadges {
  label?: string;
  values: string[];
  className?: string;
  labelClassName?: string;
  badgeIcon?: ReactNode;
}

const Badges = ({
  label,
  values,
  badgeIcon = <></>,
  labelClassName = '',
  className = 'bg-neutral-100',
}: IBadges) => {
  return (
    <div className="flex gap-2 items-center">
      {label && <h5 className={labelClassName}>{`${label} `}</h5>}
      {values.map((value, i) => {
        return (
          <div
            key={i}
            className={twMerge(
              `flex items-center space-x-1 py-1 px-2.5 rounded-3xl text-white`,
              className,
            )}
            data-testid="chip">
            <p>{value}</p>
            {badgeIcon}
          </div>
        );
      })}
    </div>
  );
};

export default Badges;
