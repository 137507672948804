interface IComment {
  name: string;
  message: string;
  onClick?: () => void;
  key?: number;
}

const Comment = ({ name, message, onClick, key }: IComment) => {
  return (
    <div
      key={key}
      className="bg-gray-50 rounded-lg p-4 mb-4 hover:bg-gray-100 cursor-pointer"
      onClick={onClick}>
      <div>
        <p className="font-bold text-gray-800 capitalize">{name}</p>
        <p className="text-gray-600">{message}</p>
      </div>
    </div>
  );
};

export default Comment;
