import { RejectionFormValues } from '../../../types';

export const initialRejectionFormValues: RejectionFormValues = {
  targetTableNumber: 0,
  targetTableColumn: 0,
  targetTableRow: 0,
  targetValue: '',
  documentNumber: '0',
  documentTitle: '',
  documentVersion: 1,
  suggestedChange: '',
  note: '',
  mappingId: '',
};
