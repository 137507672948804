//  A reusbale modal component that can be used to display any content
import { ReactNode } from 'react';

interface IModalProps {
  children: ReactNode;
  closeModal: () => void;
  modalIsOpen: boolean;
  title: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const Modal = ({ children, closeModal, modalIsOpen, title, size = 'md' }: IModalProps) => {
  if (!modalIsOpen) return null;

  const modalClassName = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full ${modalClassName[size]}`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold" aria-level={5} role="heading">
                {title}
              </p>
              <button className="cursor-pointer z-50" data-testid="close-modal-button">
                <svg
                  data-testid="close-modal-svg"
                  onClick={closeModal}
                  className="fill-current text-gray-700 hover:text-gray-600"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18">
                  <path
                    fillRule="evenodd"
                    d="M9 7.586L14.95 1.636a1 1 0 111.414 1.414L10.414 9l5.95 5.95a1 1 0 11-1.414 1.414L9 10.414l-5.95 5.95a1 1 0 11-1.414-1.414L7.586 9 .636 3.05A1 1 0 111.05 1.636L9 7.586z"
                  />
                </svg>
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Modal };
