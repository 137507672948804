// React-related imports
import { useContext, useEffect, useMemo, useState } from 'react';

// Contexts
import { GeneralizationContext } from '../../contexts/GeneralizationContext';

// Hooks
import { useMappingSessionAPI } from '../../services/api';

// Components
import SvgIcon from '../../components/elements/SvgIcon';
import { Button, Dropdown } from '@gloabal-regulatory-writing-consulting/gxt-components';

// Types
import { MappingTypeEnum } from '../../types';
import {
  ListItem,
  ListItemWithNonParsedCatalogId,
  SourceVersion,
  UseSourceFilesParams,
} from './useSourceFiles.types';
import { getDropdownOptions } from '../../helpers/utils';

export const useSourceFiles = ({
  handleLockedFileChange,
  sessionType = 'authoring',
}: UseSourceFilesParams) => {
  const {
    sourceDocuments,
    setSourceDocuments,
    sessionId,
    selectedCatalogId,
    isParsed,
    handleSourcechange,
    lockSourceFile,
    mappingType,
  } = useContext(GeneralizationContext);

  const [catalogToRemove, setCatalogToRemove] = useState<string | null>(null);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<ListItem | null>(null);

  const currentFileVersion: SourceVersion = isParsed ? 'Parsed' : 'Original';

  const lockType = lockSourceFile ? 'lock' : 'unlock';

  const { removeSourceFiles } = useMappingSessionAPI();

  const handleRemoveSourceFile = () => {
    if (catalogToRemove && sessionId) {
      removeSourceFiles
        .mutateAsync({
          catalogIds: [parseInt(catalogToRemove)],
          sessionId,
        })
        .then(() => {
          const updatedSourceDocuments = new Map(sourceDocuments);
          updatedSourceDocuments.delete(catalogToRemove);
          setSourceDocuments(updatedSourceDocuments);
        })
        .finally(() => {
          setCatalogToRemove(null);
        });
    }
  };

  const handleRemoveFileIconClick = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setCatalogToRemove(id);
  };

  const handleFileRemoveModalClose = () => {
    setCatalogToRemove(null);
  };

  const [sourceDocsArray, _sourceDocsWithoutNonParsedCatalogId, sourcesHash]: [
    ListItem[],
    ListItemWithNonParsedCatalogId[],
    Record<string, ListItem>,
  ] = useMemo(() => {
    const sourceDocumentArray: ListItem[] = [];
    const withNonParsedCatalogId: ListItemWithNonParsedCatalogId[] = [];
    const sourcesHash: Record<string, ListItem> = {};

    if (sourceDocuments && selectedCatalogId) {
      const selectedCatalogInfo = sourceDocuments.get(selectedCatalogId.toString());

      Array.from(sourceDocuments.values()).forEach((item) => {
        const shouldPushSelectedItem =
          selectedCatalogId.toString() === item.id.toString() && !selectedCatalogInfo;
        const withoutNonParsedCatalogId = {
          text: item.filename,
          value: shouldPushSelectedItem ? selectedCatalogId.toString() : item.id.toString(),
        };
        sourceDocumentArray.push(withoutNonParsedCatalogId);

        withNonParsedCatalogId.push({
          ...withoutNonParsedCatalogId,
          nonParsedCatalogId: item.nonParsedCatalogId?.toString() || '',
        });

        sourcesHash[item.id.toString()] = withoutNonParsedCatalogId;
      });
    }
    return [
      sourceDocumentArray.sort((a, b) => a.text.localeCompare(b.text)),
      withNonParsedCatalogId,
      sourcesHash,
    ];
  }, [sourceDocuments, selectedCatalogId]);

  const handleSourceToggle = () => {
    if (selectedCatalogId && sourceDocuments) {
      const selectedDoc = sourceDocuments.get(selectedCatalogId.toString());
      if (selectedDoc) {
        handleSourcechange(selectedDoc.id.toString(), isParsed);
      } else {
        const parsedCatalogId = Array.from(sourceDocuments.values()).find(
          (value) => value.nonParsedCatalogId === selectedCatalogId,
        );
        handleSourcechange(parsedCatalogId?.id?.toString() || selectedCatalogId.toString(), false);
      }
    }
  };

  const removeButton = (id: string) =>
    mappingType === MappingTypeEnum.AUTHORING &&
    sessionType === MappingTypeEnum.AUTHORING && (
      <SvgIcon
        iconType="close"
        stroke="currentColor"
        className="w-4 flex-shrink-0 text-xs"
        data-testid={`remove-file-icon-${id}`}
        onClick={(event) => handleRemoveFileIconClick(id, event)}
      />
    );

  const handleDropdownSelect = (option: ListItem) => {
    if (option.value === selectedDropdownValue?.value) return;

    setSelectedDropdownValue(option);
    handleSourcechange(option.value);
  };

  useEffect(() => {
    setSelectedDropdownValue(sourcesHash[selectedCatalogId.toString()]);
  }, [selectedCatalogId, sourcesHash]);

  const renderOption = (item: ListItem | null) => {
    if (!item) return null;

    return (
      <>
        <span
          className="cursor-pointer truncate"
          data-testid={`dropdown-item-${item.value}`}
          onClick={() => handleDropdownSelect(item)}>
          {item?.text}
        </span>
        {selectedDropdownValue?.value !== item.value && <span>{removeButton(item.value)}</span>}
      </>
    );
  };

  const sourceFilesDropdown = (
    <div className="w-96 flex-1">
      <Dropdown
        type="select"
        options={getDropdownOptions(sourceDocsArray)}
        dropdownIcon
        disabled={sourceDocsArray.length === 0 || removeSourceFiles.isPending || lockSourceFile}
        initialValue={{ value: selectedDropdownValue }}
        renderOption={(option) => renderOption(option?.value)}
        position="bottom"
        onSelect={(option) => option.value && handleDropdownSelect(option.value)}
        equalityFn={(a, b) => a?.value === b?.value}
        customStyles={{
          container: { height: '100%', width: '100%' },
          button: {
            width: '100%',
          },
          placeholder: {
            textAlign: 'left',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            justifyContent: 'left',
            display: 'block',
          },
          itemsWrapper: { width: '100%' },
          item: {
            justifyContent: 'space-between',
          },
        }}
      />
    </div>
  );

  const lockedFileSwitch = (
    <Button
      variant={lockType === 'lock' ? 'primary' : 'secondary'}
      onClick={handleLockedFileChange}
      disabled={!selectedCatalogId}>
      <div className="py-0.5">
        <SvgIcon iconType={lockType} />
      </div>
    </Button>
  );

  return {
    handleRemoveSourceFile,
    handleFileRemoveModalClose,
    sourceDocsArray,
    currentFileVersion,
    handleSourceToggle,
    sourceFilesDropdown,
    lockedFileSwitch,
    showSourceDeleteModal: !!catalogToRemove,
  };
};
