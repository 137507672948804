import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifyError, notifySuccess } from '../../../helpers/utils';
import {
  createCatalogItem,
  deleteCatalogItem,
  updateCatalogItem as updateCatalogItemAPI,
} from './catalogItems';
import { SettingsTabs } from '../../../pages/settings/types';

export const useCatalogItems = () => {
  const queryClient = useQueryClient();

  const archiveCatalogItem = useMutation({
    mutationFn: deleteCatalogItem,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [SettingsTabs.DROPDOWN_LISTS],
      });
      queryClient.invalidateQueries({ queryKey: ['DropdownListFilterOptions'] });
      notifySuccess(data.message);
    },
    onError: (err) => {
      console.error(err);
      notifyError(err.message);
    },
  });

  const addCatalogItem = useMutation({
    mutationFn: createCatalogItem,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [SettingsTabs.DROPDOWN_LISTS],
      });
      queryClient.invalidateQueries({ queryKey: ['DropdownListFilterOptions'] });
      notifySuccess(data.message);
    },
    onError: (err) => {
      notifyError(err.message || 'Something went wrong');
    },
  });

  const updateCatalogItem = useMutation({
    mutationFn: updateCatalogItemAPI,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [SettingsTabs.DROPDOWN_LISTS],
      });
      notifySuccess(data.message);
    },
    onError: (err) => {
      notifyError(err.message || 'Something went wrong');
    },
  });

  return {
    archiveCatalogItem,
    addCatalogItem,
    updateCatalogItem,
  };
};
