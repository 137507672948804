import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useFilterStates = <T extends Record<string, string>>(initialState: T) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const values = Object.entries(initialState).reduce(
    (acc, [key, value]) => {
      acc[key] = params.get(key) || value;
      return acc;
    },
    {} as Record<string, string>,
  );

  const [filters, _setFilters] = useState<T>(values as T);

  const setFilters = (key: keyof T, value: string) => {
    _setFilters((prev) => ({ ...prev, [key]: value }));
  };

  return {
    filters,
    setFilters,
  };
};
