import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const CustomRoute = ({ children, path }) => {
  const { authorizedPages } = useAuth();
  return authorizedPages?.includes(path) || path === '/rolePermission' ? (
    <>{children}</>
  ) : (
    <Navigate to="*" />
  );
};

export default CustomRoute;
