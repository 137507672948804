import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addSourceFileInSession,
  editMappingSession as editMappingSessionAPI,
  removeSourceFilesFromSession,
  autowrite,
  manualAuthor,
  updateSessionStatus,
  requestDynamicMapping as requestDynamicMappingAPI,
  autoAcceptSuggestion,
  archiveMappingSession,
} from './mappingSession';
import { notifyError, notifySuccess } from '../../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { DocumentTabs } from '../../../types';

export const useMappingSessionAPI = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateMappingSessionStatus = useMutation({
    mutationFn: updateSessionStatus,
    onSuccess: (_data, params) => {
      notifySuccess('Document status updated');
      if (params.status === 'partial') {
        navigate('/catalog', {
          state: { activeTab: DocumentTabs.IN_PROGRESS },
        });
      }
    },
    onError: () => {
      notifyError('Unable to update status');
    },
  });

  const addSourceFile = useMutation({
    mutationFn: addSourceFileInSession,
    onSuccess: () => {
      notifySuccess('Document added to mapping session');
    },
    onError: () => {
      notifyError('Unable to add document');
    },
  });

  const removeSourceFiles = useMutation({
    mutationFn: removeSourceFilesFromSession,
    onSuccess: (res) => {
      notifySuccess(res.data.message);
    },
    onError: (err: { data: string }) => {
      notifyError(err?.data || 'Unable to remove document');
    },
  });

  const autowriteMappingSession = useMutation({
    mutationFn: autowrite,
    onSuccess: (res) => {
      notifySuccess(res.data.message);
      navigate('/catalog');
    },
    onError: () => {
      notifyError('Unable to Autowrite');
    },
  });

  const manuallyAuthorMappingSession = useMutation({
    mutationFn: manualAuthor,
    onSuccess: (res) => {
      navigate(`/mapping-sessions/${res.data.id}`);
    },
    onError: () => {
      notifyError('Unable to Author');
    },
  });

  const editMappingSession = useMutation({
    mutationFn: editMappingSessionAPI,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['Catalog'],
      });
      notifySuccess(data?.message || 'Session updated successfully');
    },
    onError: () => {
      notifyError('Unable to update session');
    },
  });

  const dynamicMappingSuggestions = useMutation({
    mutationKey: ['dynamicMappingSuggestions'],
    mutationFn: requestDynamicMappingAPI,
    onError: () => {
      notifyError('Unable to get new suggestions');
    },
  });

  const autoFillSuggestions = useMutation({
    mutationFn: autoAcceptSuggestion,
    onSuccess: () => {
      notifySuccess('Auto Filled Successful');
    },
    onError: () => {
      notifyError('Unable to Auto Fill');
    },
  });

  const archiveSession = useMutation({
    mutationFn: archiveMappingSession,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Catalog'],
      });
    },
    onError: (e) => {
      console.error(e);
      notifyError('Unable to Delete');
    },
  });

  return {
    updateMappingSessionStatus,
    addSourceFile,
    removeSourceFiles,
    autowriteMappingSession,
    manuallyAuthorMappingSession,
    editMappingSession,
    dynamicMappingSuggestions,
    autoFillSuggestions,
    archiveSession,
  };
};
