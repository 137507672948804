import React from 'react';

interface TableSkeletonProps {
  numRows?: number;
  columns: { id: string; size: number }[];
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ numRows = 10, columns }) => {
  return (
    <>
      {[...Array(numRows)].map((_, rowIndex) => (
        <tr
          key={`skeleton-${rowIndex}`}
          className={`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
          {columns.map((column, colIndex) => (
            <td
              key={`skeleton-cell-${colIndex}`}
              className="p-2 border-b border-r"
              style={{ width: `${column.size}px` }}>
              <div className="bg-gray-200 h-4 rounded w-3/4 animate-pulse"></div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableSkeleton;
