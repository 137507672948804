import { useState } from 'react';
import { uploadUserAvatar } from '../services/apiCalls';

export const useImageUploader = () => {
  const [imageUrl, setImageUrl] = useState('');

  const uploadImageToS3 = (file: File, filename: string): Promise<string> => {
    return new Promise((resolve, _reject) => {
      uploadUserAvatar(file, filename)
        .then((response: { data: { url: string } }) => {
          setImageUrl(response?.data?.url);
          resolve(response?.data?.url);
        })
        .catch((err) => {
          console.error(err);
          resolve('');
        });
    });
  };

  return { imageUrl, uploadImageToS3 };
};
