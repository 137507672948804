import { FC } from 'react';
import ReactLoading from 'react-loading';

interface LoadingProps {
  type:
    | 'blank'
    | 'balls'
    | 'bars'
    | 'bubbles'
    | 'cubes'
    | 'cylon'
    | 'spin'
    | 'spinningBubbles'
    | 'spokes';
  color?: string;
  height?: number;
  width?: number;
  backgroundColor?:
    | 'bg-zinc-100'
    | 'bg-zinc-200'
    | 'bg-zinc-300'
    | 'bg-zinc-400'
    | 'bg-zinc-500'
    | 'bg-zinc-600'
    | 'bg-zinc-700'
    | 'bg-zinc-800'
    | 'bg-zinc-900';
}

const Loading: FC<LoadingProps> = ({
  type,
  color = '#000',
  height = 64,
  width = 64,
  backgroundColor = 'bg-zinc-700',
}) => {
  return (
    <div className={`${backgroundColor} fixed w-screen h-screen align-middle opacity-30`}>
      <ReactLoading
        className="m-auto mt-80"
        type={type}
        color={color}
        height={height}
        width={width}
      />
    </div>
  );
};

export default Loading;
