import { z } from 'zod';

export const rejectionFormSchema = z.object({
  targetTableNumber: z.coerce.number().min(0).optional().nullable(),
  targetTableColumn: z.coerce.number().min(0).optional().nullable(),
  targetTableRow: z.coerce.number().min(0).optional().nullable(),
  targetValue: z.string().min(1, { message: 'Target value is required' }),
  suggestedChange: z.string().min(1, { message: 'Suggested Change is required' }),
  documentNumber: z.coerce.number().min(0).optional().nullable(),
  documentVersion: z.coerce.number().min(0).optional().nullable(),
  documentTitle: z.string().optional().nullable(),
  note: z.string().optional(),
  mappingId: z.string(),
});

export type FormValues = z.infer<typeof rejectionFormSchema>;
