import { getRoles } from '../../../../services/api/roles';
import { Role } from '../../../../services/api/types';

const fetchRoles = async () => {
  try {
    const { data } = await getRoles();
    const rolesHash = data.reduce(
      (acc, role) => {
        acc[role.id] = role;
        return acc;
      },
      {} as Record<number, Role>,
    );
    return rolesHash;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default fetchRoles;
