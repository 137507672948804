import React, { forwardRef, ButtonHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'title'> {
  isLoading?: boolean;
  title: string | ReactNode;
  styles?: string;
  classType?: 'primary' | 'standard';
  buttonType?: 'primary' | 'secondary' | 'danger' | 'warning' | 'success' | 'info';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      isLoading = false,
      title,
      buttonType = 'primary',
      disabled = false,
      styles,
      classType = 'standard',
      ...rest
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const buttonTypeClass = {
      primary: 'bg-primary_bg_color hover:bg-secondary_bg_color',
      secondary: 'bg-gray-50 hover:bg-primary_bg_color',
      danger: 'bg-red-500 hover:bg-red-600',
      warning: 'bg-yellow-500 hover:bg-yellow-600',
      success: 'bg-green-500 hover:bg-green-600',
      info: 'bg-blue-500 hover:bg-blue-600',
    };

    const buttonTypeClassDisabled = {
      primary: `${buttonTypeClass.primary} opacity-50 hover:opacity-40 cursor-not-allowed`,
      secondary: 'bg-gray-100 hover:bg-gray-50',
      danger: 'bg-red-500 hover:bg-red-600',
      warning: 'bg-yellow-500 hover:bg-yellow-600',
      success: 'bg-green-500 hover:bg-green-600',
      info: 'bg-blue-500 hover:bg-blue-600',
    };

    const className = {
      standard: twMerge(
        styles,
        `text-white ${
          isLoading || disabled ? buttonTypeClassDisabled[buttonType] : buttonTypeClass[buttonType]
        } font-medium rounded-lg text-sm px-5 mx-1 py-2.5`,
      ),
      primary: `text-white font-bold bg-primary_bg_color hover:bg-secondary_bg_color rounded-md text-sm px-5 py-2.5 whitespace-nowrap mt-1 mx-1`,
    };

    return (
      <button ref={ref} className={className[classType]} {...rest} disabled={isLoading || disabled}>
        {title}
      </button>
    );
  },
);
Button.displayName = 'Button';
export default Button;
