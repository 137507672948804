import { SettingsTabs } from '../../types';

export interface UserFormProps {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: string[];
}

export type FormType = 'Users' | 'Dropdown Lists';

export type EditUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roles?: string[];
  avatar?: string;
};

export type EditUserProps = {
  isOpen: boolean;
  onClose: () => void;
  activeTab: SettingsTabs;
  defaultValues: EditUser;
  userRoles: Array<{ label: string; value: string }>;
  rolesOnClickHandler: () => void;
};

export const AWS_DEFAULT_ATTRIBUTES = [
  'email',
  'phone_number',
  'name',
  'given_name',
  'family_name',
  'preferred_username',
  'cognito:user_status',
];
