import Joi from 'joi-browser';

const schema = (_action) => ({
  firstName: Joi.string()
    .regex(/^\S*$/, {
      name: 'non-space characters',
      invert: false,
      language: { label: 'may only contain' },
    })
    .required(),
  lastName: Joi.string()
    .regex(/^\S*$/, {
      name: 'non-space characters',
      invert: false,
      language: { label: 'may only contain' },
    })
    .required(),
  email: Joi.string().email().required(),
  roles: Joi.array().min(1),
});

export const validate = (action, userData) => {
  const result = Joi.validate(
    {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      roles: userData.roles,
    },
    schema(action),
    {
      abortEarly: false,
    },
  );
  if (result.error === null) return;
  const errors = { firstName: '', lastName: '', email: '', roles: '' };
  for (let item of result.error.details) {
    errors[item.path[0]] = item.message;
  }
  return errors;
};

export const validateForm = (data) => {
  const newObj = Object.entries(data).reduce((acc, [key, value]) => {
    if (!value) {
      acc[key] = true;
    }
    return acc;
  }, {});
  return newObj;
};
