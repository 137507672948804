import { FC, useContext, useRef, useState } from 'react';
import { Button, SlideOver } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { UserFormProps, EditUserProps, AWS_DEFAULT_ATTRIBUTES } from './EditUsers.types';
import EditUserForm from './EditUsersForm';
import { UserContext } from '../../../../contexts/UserContext';
import { updateCognitoUserAttributes } from '../../../../helpers/amplify';
import { useAuth } from '../../../../contexts/AuthContext';
import { useUser } from '../../../../services/api/user/useUser';

const EditUser: FC<EditUserProps> = ({
  isOpen,
  onClose,
  defaultValues,
  userRoles,
  rolesOnClickHandler,
  activeTab,
}) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const formRef = useRef<{ submitForm: () => void }>(null);
  const { userObj, updateUserAttributes } = useContext(UserContext);
  const { setPermissions } = useAuth();

  const { UpdateUser } = useUser();

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };
  const isCurrentUser = userObj?.email === defaultValues?.email;

  const updateUserAttributesInCognito = async (data: UserFormProps) => {
    const { firstName, lastName, ...userDataWithoutName } = data;
    const name = `${data?.firstName} ${data?.lastName}`;

    const userAttributes = [
      ...Object.entries(userDataWithoutName).map(([key, value]) => {
        if (value === (userObj as any)[key]) {
          return null; // Skip unchanged attributes
        }
        if (!AWS_DEFAULT_ATTRIBUTES.includes(key)) {
          return { Name: `custom:${key}`, Value: value };
        } else {
          return { Name: key, Value: value };
        }
      }),
      { Name: 'name', Value: name }, // Include the 'name' attribute
      { Name: 'given_name', Value: firstName || '' }, // Include the 'given_name' attribute
      { Name: 'family_name', Value: lastName || '' }, // Include the 'family_name' attribute
    ];

    const filteredUserAttributes = userAttributes.filter(Boolean) as {
      Name: string;
      Value: string;
    }[];
    const resp = await updateCognitoUserAttributes(filteredUserAttributes);
    const updatedUserObj = filteredUserAttributes.reduce((acc, curr) => {
      if (curr) {
        acc[curr.Name] = curr.Value;
        if (curr.Name === 'custom:avatar') {
          acc['avatar'] = curr.Value;
        }
      }
      return acc;
    }, {} as any);
    updatedUserObj.firstName = firstName;
    updatedUserObj.lastName = lastName;
    updateUserAttributes(updatedUserObj);
    return resp;
  };
  const handleSubmitData = async (data: UserFormProps) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { email, ...rest } = data;
    const userData = {
      id: defaultValues.id,
      ...rest,
      roles: data.roles || [],
    };
    if (isCurrentUser) {
      await updateUserAttributesInCognito(data);
      setPermissions(data.roles || []);
    }
    await UpdateUser.mutateAsync({ ...userData });
    onClose();
  };

  const heading = {
    Users: 'Edit User',
    'Dropdown Lists': 'Edit Dropdown List',
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        {heading[activeTab]}
      </SlideOver.Header>
      <EditUserForm
        ref={formRef}
        handleSubmitData={handleSubmitData}
        defaultValues={defaultValues}
        userRoles={userRoles}
        rolesOnClickHandler={rolesOnClickHandler}
        updateFormValidity={(isValid) => setFormIsValid(isValid)}
      />
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" onClick={handleFormSubmit} disabled={!formIsValid}>
            Save
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default EditUser;
