import { ISourceDocument } from '../types';

const DocumentCard = ({ document }: { document: ISourceDocument }) => (
  <div
    key={document.id}
    className="rounded-md border p-4 text-red-800 hover:bg-gray-50 hover:shadow-md">
    <div className="flex gap-2">
      <p className="uppercase">id:</p>
      <p data-testid={`id-${document.id}`}>{document.id}</p>
    </div>
    <div className="flex gap-2">
      <p>Name:</p>
      <p data-testid={`name-${document.id}`}>{document.documentName || document.title}</p>
    </div>
  </div>
);

export default DocumentCard;
