import { FC } from 'react';
import { IInputErrorProps } from './types';

const InputError: FC<IInputErrorProps> = ({ errors, field }) => {
  return (
    <>
      {errors[field] && errors[field]?.message && (
        <p className="text-negative-100 text-xs mt-1">{errors[field]?.message?.toString()}</p>
      )}
    </>
  );
};

export default InputError;
