import { GetProjectsParams } from '../../services/api/types';

export const enum ProjectSettingsTabs {
  PROJECT_SETTINGS = 'projectSettings',
}

export type ProjectSettingsFilterSlideOverType = {
  show: boolean;
  closeModal: () => void;
  handleApplyFilters: (filters: GetProjectsParams['filters']) => void;
  filters: GetProjectsParams['filters'];
};
