import { FC } from 'react';
import { Modal, Button } from '@gloabal-regulatory-writing-consulting/gxt-components';
import StartAuthoringFields from './StartAuthoringFields';
import { IStartAuthoringModal } from './types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { authoringSchema } from '../../../utils/schema';

const StartAuthoringModal: FC<IStartAuthoringModal> = ({
  isOpen,
  handleClose,
  handleAuthoringSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    resolver: zodResolver(authoringSchema),
    mode: 'onChange',
  });

  return (
    <Modal isOpen={isOpen} maxWidth="55rem" maxHeight="30.4rem" onClose={handleClose}>
      <Modal.ModalHeader>
        <Modal.ModalHeaderHeading>Document information</Modal.ModalHeaderHeading>
        <Modal.ModalHeaderBody>
          <StartAuthoringFields control={control} errors={errors}></StartAuthoringFields>
        </Modal.ModalHeaderBody>
      </Modal.ModalHeader>
      <Modal.ModalFooter className="!justify-end">
        <Button variant="primary" onClick={handleSubmit(handleAuthoringSubmit)} disabled={!isValid}>
          Start Authoring
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.ModalFooter>
    </Modal>
  );
};

export default StartAuthoringModal;
