import Select from './Select';
import { FC, ChangeEvent } from 'react';
import { twMerge } from 'tailwind-merge';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
  label?: string;
  labelClassName?: string;
  disabled?: boolean;
  tooltip?: string;
  type?: InputType;
  name?: string;
  placeholder?: string;
  dataTestId?: string;
  value?: string | number;
  options?: any;
  onChange?: (
    event: ChangeEvent<HTMLInputElement> &
      ChangeEvent<HTMLTextAreaElement> &
      ChangeEvent<HTMLSelectElement>,
  ) => any;
}

export type InputType = 'select' | 'text' | 'password' | 'number' | 'email' | string;

const Input: FC<InputProps> = ({ type, className, dataTestId, ...rest }) => {
  if (type === 'select') {
    const { options = [], placeholder = '', label = '' } = rest;
    return (
      <Select
        {...rest}
        options={options}
        placeholder={placeholder}
        label={label}
        type={type}
        className={className}
        dataTestId={dataTestId}
        value={rest.value}
      />
    );
  } else if (type === 'textarea') {
    return (
      <textarea
        {...rest}
        data-testid={dataTestId}
        className={twMerge(
          'bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 placeholder-gray-400 block w-full',
          className,
        )}
      />
    );
  } else {
    return (
      <input
        {...rest}
        type={type}
        data-testid={dataTestId || 'input'}
        className={twMerge(
          'bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 placeholder-gray-400 block w-full',
          className,
        )}
      />
    );
  }
};

export default Input;
