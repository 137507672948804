import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

const Dashboard = () => {
  const { firstName } = useContext(UserContext).userObj;

  return (
    <p className="text-center mt-10 font-bold text-primary_text_color text-2xl">
      Welcome {firstName}!
    </p>
  );
};

export default Dashboard;
