import { createContext, useState, FC, ReactNode, useEffect } from 'react';
import { IUserObj } from '../types';
import { getUserDataFromLocalStorage, storeUserDataInLocalStorage } from '../helpers/localStorage';
import { getCurrentUser } from '../helpers/amplify';

export type UserContextType = {
  userObj: IUserObj;
  updateUserAttributes: (userAttribute: Partial<IUserObj>) => void;
};

const defaultUserContext = {
  userObj: {
    userId: '',
    name: '',
    email: '',
    avatar: '',
    firstName: '',
    userName: '',
    lastName: '',
  },
  setUserObj: () => {},
  updateUserAttributes: () => {},
};

export const UserContext = createContext<UserContextType>(defaultUserContext);

export const UserContextProvider = UserContext.Provider;
export const UserContextConsumer = UserContext.Consumer;

interface Props {
  children: ReactNode;
}
export const UserContextContainer: FC<Props> = ({ children }) => {
  const [userObj, setUserObj] = useState<IUserObj>(defaultUserContext.userObj);

  useEffect(() => {
    if (userObj.userId) return;
    const user = getUserDataFromLocalStorage();
    user ? setUserObj(user) : getUserAttributes();
  }, []);

  const getUserAttributes = async () => {
    const response = await getCurrentUser();
    if (!response.success) return;

    const { user } = response;
    const newUserAttributes = {
      userId: '',
      userName: user?.username,
      name: '',
      avatar: '',
      email: '',
      sub: '',
      firstName: '',
      lastName: '',
    };
    Object.entries(user?.attributes).forEach(([key, value]) => {
      const attributeName = key?.startsWith('custom:') ? key.substring(7) : key;
      if (attributeName === 'given_name') {
        newUserAttributes['firstName' as keyof IUserObj] = value;
      }
      if (attributeName === 'family_name') {
        newUserAttributes['lastName' as keyof IUserObj] = value;
      }
      newUserAttributes[attributeName as keyof IUserObj] = value;
    });
    newUserAttributes.userId = newUserAttributes['sub'];
    setUserObj(newUserAttributes);
    storeUserDataInLocalStorage(newUserAttributes);
  };

  const updateUserAttributes = (userAttribute: Partial<IUserObj>) => {
    if (userAttribute.name) {
      userAttribute = {
        ...userAttribute,
      };
    }
    setUserObj((prevUserObj) => ({ ...prevUserObj, ...userAttribute }));
    storeUserDataInLocalStorage({ ...userObj, ...userAttribute });
  };

  return (
    <UserContextProvider value={{ userObj, updateUserAttributes }}>{children}</UserContextProvider>
  );
};

export default UserContextContainer;
