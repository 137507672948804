// React-related imports
import { useContext, useEffect, useState } from 'react';

// Helpers
import {
  columnCells,
  convertAIResponseToSuggestions,
  deepCopyDocument,
  getCellElement,
  getElement,
  processAISuggestions,
  setTargetElement,
} from '../../helpers/generalizationHelpers';

// Hooks
import useModal from '../useModal';

// Types
import {
  AcceptanceMethodEnum,
  AIResponse,
  GeneralizationSuggestion,
  UseQuickFillParams,
} from '../../types';

// Hooks
import useAsyncOperation from '../useAsyncOperation';
import { mergeDynamicSuggestions } from '../../pages/addSource/utils/helpers';
import { GeneralizationContext } from '../../contexts/GeneralizationContext';
import { notifyError } from '../../helpers/utils';

export const useQuickFill = ({
  currentTargetNodeId,
  getCurrentNodeId,
  updatedAiResponse,
  callSaveSuggestions,
  setInitialTarget,
  editor1Ref,
  sessionId,
  aiResponse,
  targetNodeIdSuggestionMap,
}: UseQuickFillParams) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { show: isQuickFillModalOpen, closeModal, openModal: openQuickFillModal } = useModal();

  const {
    sanitizeAiResponse,
    setAiResponse,
    setAiResponseCopy,
    setIsAutoSave,
    acceptedSuggestions,
  } = useContext(GeneralizationContext);

  const { isLoading: isSavingFilledSuggestions, executeCallback: handleAcceptSuggestions } =
    useAsyncOperation(callSaveSuggestions);

  const handleOptionChange = (option: string) => {
    setSelectedOption((prevSelectedOption) => (prevSelectedOption === option ? null : option));
  };

  const clearSelection = () => {
    setSelectedOption(null);
  };

  const handleApplyFilter = async (filterValue: string) => {
    if (!currentTargetNodeId) return;

    const selectedElement = getElement(editor1Ref, currentTargetNodeId) as HTMLElement;
    const currentNode = getCellElement(selectedElement, editor1Ref?.current?.getBody() as Node);

    if (!currentNode) return;

    const nodeIndex = currentNode.cellIndex;
    const wrapperElement = currentNode.parentElement?.parentElement;
    const originalCellsCount = (currentNode.parentElement as HTMLTableRowElement).cells.length;

    if (!wrapperElement) return;

    const intermediateNode =
      wrapperElement.nodeName === 'THEAD'
        ? wrapperElement.nextElementSibling || wrapperElement
        : wrapperElement;

    const suggestionNodes = columnCells(
      intermediateNode as HTMLTableElement,
      nodeIndex,
      originalCellsCount,
    ).map((node) => getCurrentNodeId(node, editor1Ref, updatedAiResponse));

    if (!suggestionNodes.length || !aiResponse) return;

    const filteredAIResponse = suggestionNodes.reduce((accumulator: AIResponse, suggestionNode) => {
      const suggestions = aiResponse[suggestionNode];
      if (suggestions) {
        const filteredSuggestions = suggestions.filter(
          (suggestion) => Object.values(suggestion)[0]?.groupingVariables.includes(filterValue),
        );
        if (filteredSuggestions?.length) {
          const [_sourceNodeId, attributes] = Object.entries(filteredSuggestions[0])[0];
          setTargetElement(editor1Ref, attributes.value, suggestionNode);
          accumulator = {
            ...accumulator,
            [suggestionNode]: [filteredSuggestions[0]],
          };
        }
      }
      return accumulator;
    }, {} as AIResponse);

    if (!Object.entries(filteredAIResponse).length) {
      notifyError('No suggestions found for the selected filter');
      return;
    }

    const suggestions = convertAIResponseToSuggestions(
      filteredAIResponse,
      editor1Ref,
      sessionId,
      targetNodeIdSuggestionMap,
      true,
    );

    const matchingSuggestions = suggestions.filter(
      (suggestion) =>
        acceptedSuggestions?.some(
          (accepted) =>
            suggestion.targetNodeId === accepted.targetNodeId &&
            (!accepted.selected ||
              (accepted.selected &&
                (accepted.acceptanceMethod === 'auto fill' ||
                  accepted.acceptanceMethod === 'quick fill'))),
        ),
    );

    const newSuggestions = await handleAcceptSuggestions(
      matchingSuggestions,
      AcceptanceMethodEnum.QUICK_FILL,
      undefined,
    );
    if (newSuggestions.data) {
      addQuickFilledSuggestionsToAIResponse(newSuggestions.data);
    }
  };

  const addQuickFilledSuggestionsToAIResponse = (newSuggestions: GeneralizationSuggestion[]) => {
    const newTarget = deepCopyDocument(editor1Ref.current.getDoc());
    if (newTarget) setInitialTarget(newTarget);

    if (newSuggestions.length) {
      const processedSuggestions = processAISuggestions(newSuggestions);
      const sanitizedAIResponse = sanitizeAiResponse(processedSuggestions);
      setAiResponse((prev) => mergeDynamicSuggestions(prev, sanitizedAIResponse));
      setAiResponseCopy((prev) => mergeDynamicSuggestions(prev, sanitizedAIResponse));
    }
  };

  const closeQuickFillModal = () => {
    setSelectedOption(null);
    closeModal();
  };

  useEffect(() => {
    setIsAutoSave(isSavingFilledSuggestions);
  }, [isSavingFilledSuggestions]);

  return {
    selectedOption,
    handleOptionChange,
    clearSelection,
    handleApplyFilter,
    isQuickFillModalOpen,
    closeQuickFillModal,
    openQuickFillModal,
    isSavingFilledSuggestions,
  };
};
