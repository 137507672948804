import axios from 'axios';
import {
  REACT_APP_AWS_S3_BUCKET_NAME,
  REACT_APP_DP_URL,
  REACT_APP_PDF2HTML_URL,
} from '../constants';

export const pdf2html = async (pdfFile: File) => {
  const formData = new FormData();
  formData.append('file', pdfFile);
  return await axios.post(REACT_APP_PDF2HTML_URL + '/convert', formData);
};

export const autowrite = async (key: string, filename: string) => {
  const data = {
    key,
    filename,
    documentId: key.split('/')[1],
    bucketName: REACT_APP_AWS_S3_BUCKET_NAME,
  };
  return await axios.post(REACT_APP_DP_URL + '/autowrite', data);
};
