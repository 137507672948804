export const initialValues = {
  id: '',
  title: '',
  version: 1,
  substanceProdCat: '',
  site: '',
  program: '',
  documentType: 'Target',
  documentDrugCat: '',
  company: '',
  documentName: '',
  documentCat: '',
  documentNum: '',
  documentSubCat: '',
  documentSource: '',
  methodOrScale: '',
};
