interface params {
  basePath: string;
  filters: Record<string, string | string[]>;
  activeTab?: string;
  type?: string;
}

export const updateParams = ({ basePath, filters, activeTab, type }: params) => {
  const url = `/${basePath}?`;
  const params = new URLSearchParams();

  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key])) {
      (filters[key] as string[]).forEach((value) => {
        params.append(key, value);
      });
    } else {
      filters[key] && params.append(key, filters[key] as string);
    }
  });

  activeTab && params.append('activeTab', activeTab);
  type && params.append('type', type);

  window.history.pushState(null, '', url + params.toString());
};
