import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeUser, editUser, addUserToDb } from './user';
import { notifyError, notifySuccess } from '../../../helpers/utils';

export const useUser = () => {
  const queryClient = useQueryClient();

  const deleteUser = useMutation({
    mutationFn: removeUser,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['Users'],
      });
      notifySuccess(data.message);
    },
    onError: (err) => {
      notifyError(err.message);
    },
  });

  const UpdateUser = useMutation({
    mutationFn: editUser,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['Users'],
      });
      notifySuccess(data.message || 'User updated successfully');
    },
    onError: () => {
      notifyError('Unable to update user');
    },
  });

  const createUser = useMutation({
    mutationFn: addUserToDb,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['Users'],
      });
      notifySuccess(data.message);
    },
    onError: (err) => {
      console.error(err);
      notifyError(err.message || 'An error occurred');
    },
  });

  return {
    UpdateUser,
    deleteUser,
    createUser,
  };
};
