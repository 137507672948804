// React-related imports
import React from 'react';
import ReactLoading from 'react-loading';

// Types
import { ButtonTitleProps } from '../../types';

const ButtonTitle: React.FC<ButtonTitleProps> = ({ title, loadingText, isLoading }) => (
  <div className="flex flex-row">
    {isLoading ? loadingText : title}
    {isLoading && (
      <span className="ml-3 mt-[-2px]">
        <ReactLoading type={'spin'} className="mx-auto" color="white" height={22} width={22} />
      </span>
    )}
  </div>
);

export default ButtonTitle;
