import React, { useState } from 'react';
import Modal from 'react-modal';

import Button from '../elements/Button';
import { finishAutoMapping } from '../../services/apiCalls';
import ModalInputFields from './modalInputFields';
import ModalHeader from './ModalHeader';
import { notifyError, notifySuccess } from '../../helpers/utils';
import { validateForm } from '../../contexts/Validations';

const FileNameModal = ({ mappingSessionId, isOpen, closeModal, documentTitle }) => {
  const initialFormValues = { documentTitle: documentTitle || '' };
  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const inputList = [
    {
      label: 'Automated Document Title',
      type: 'text',
      name: 'documentTitle',
      value: formData.title,
      placeholder: 'Enter file name',
      inputClassName: 'w-full',
    },
  ];

  const fileNameModalCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '35%',
      minHeight: 'fit-content',
    },
    overlay: {
      background: 'rgba(71,84,93,0.8)',
      zIndex: 1,
    },
  };

  const submitData = async (e) => {
    e.preventDefault();
    const result = validateForm(formData);
    if (Object.keys(result).length > 0) {
      setErrors(result);
      return;
    }
    setErrors({});
    setIsLoading(true);
    finishAutoMapping(formData, mappingSessionId)
      .then((res) => {
        if (res.status === 404) {
          notifyError(res.data.message);
        } else {
          notifySuccess(res.data.message);
        }
        closeModalHandler();
      })
      .catch((err) => notifyError(err.response.data.message))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const closeModalHandler = () => {
    setErrors({});
    setFormData(initialFormValues);
    closeModal();
    setIsLoading(false);
  };

  const formDataHandler = (e) => {
    setFormData((prevValue) => ({ ...prevValue, [e.target.name]: e.target.value }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModalHandler}
      style={fileNameModalCustomStyles}
      ariaHideApp={false}>
      <div className="space-y-4 sm:p-8">
        <ModalHeader title={'Rename Target File'} closeModal={closeModalHandler} />
        <form className="space-y-4" onSubmit={submitData}>
          <ModalInputFields
            inputList={inputList}
            errors={errors}
            onChangeHandler={formDataHandler}
          />
          <div className="flex justify-between">
            <Button
              type="submit"
              onClick={closeModalHandler}
              title="Cancel"
              className="text-white bg-gray-500 hover:bg-gray-600 cursor-pointer font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            />
            <Button
              type="submit"
              onClick={submitData}
              title="Submit"
              className={`text-white
              ${
                isLoading
                  ? ' bg-gray-500 hover:bg-gray-500 pointer-events-none bg-[#C9C9C9]” : “bg-[#438FFE] '
                  : ' bg-primary_bg_color '
              }
              font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:bg-secondary_bg_color`}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FileNameModal;
