import { Navigate, Route, Routes } from 'react-router-dom';

import Login from '../pages/auth/Login';
import ResetPassword from '../pages/auth/ResetPassword';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword forReset={false} />} />
      <Route path="/password" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default PublicRoutes;
