/* eslint-disable @typescript-eslint/no-empty-function */

import { useNavigate } from 'react-router-dom';
import Layout from '../../../../components/layout';
import { Header, Button } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { useRef } from 'react';
import CreateProjectForm from '../CreateNewProjectForm/CreateNewProjectForm';
import { ProjectDefaultValues } from '../CreateNewProjectForm/utils';
import { CreateProjectFormValues } from '../CreateNewProjectForm/CreateNewProjectForm.types';
import { useProject } from '../../../../services/api/projectSettings/useProjectAPI';

const CreateProject = () => {
  const navigate = useNavigate();
  const formRef = useRef<{ submitForm: () => void }>(null);
  const { createProject } = useProject();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const createNewProject = async (data: CreateProjectFormValues) => {
    const projectData = {
      name: data.name,
      groupIds: data.groupIds || [],
      subGroupIds: data.subGroupIds || [],
    };
    await createProject.mutateAsync(projectData).then(() => navigate('/project-settings'));
  };

  return (
    <Layout>
      <Layout.Header>
        <Header>
          <Header.Heading>Create new Project</Header.Heading>
        </Header>
      </Layout.Header>
      <Layout.Body>
        <div>
          <p className="text-primary-200 font-bold text-[1.25rem] leading-[1.875rem] tracking-[0.0015rem]">
            Project Details
          </p>
          <p className="text-neutral-400 font-normal text-[1rem] leading-[1.875rem] tracking-[0.0015rem]">
            All fields are required
          </p>
        </div>
        <CreateProjectForm
          handleSubmitData={createNewProject}
          defaultValues={ProjectDefaultValues}
          ref={formRef}
          updateFormValidity={() => {}}
        />
        <div className="flex gap-6 justify-end w-full">
          <Button variant="primary" onClick={handleSubmit} disabled={false}>
            Create
          </Button>
          <Button variant="secondary" onClick={() => navigate('/project-settings')}>
            Cancel
          </Button>
        </div>
      </Layout.Body>
    </Layout>
  );
};

export default CreateProject;
