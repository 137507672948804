import { IGeneralizedDocument } from '../types';

export const generalizedDocumentsTableHeaders: IGeneralizedDocument = {
  id: ['ID', 'id'],
  title: ['Document Name', 'title'],
  catalogIds: ['Source Document ID', ''],
  totalSuggestionsCount: ['No of Suggestions', 'totalSuggestionsCount'],
  status: ['Authoring Status', 'status'],
  autowriteStatus: ['Autowrite Status', 'autowriteStatus'],
  createdAt: ['Created On', 'createdAt'],
  createdBy: ['Authored By', 'createdBy'],
  Actions: ['Actions', ''],
};
