import { forwardRef, useContext, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { MappingContext } from '../contexts/MappingContext';
import { Editor as TinyMCEEditor } from 'tinymce';

import {
  DOCUMENT_MARGINS,
  DOCUMENT_STYLES,
  MSO_DEFINITIONS,
  REACT_APP_TINYMCE_API_KEY,
} from '../constants';
import Slider from './elements/Slider';
import { EditorRef, EditorType, EditorTypeEnum } from '../types';

export interface ITinyEditor {
  disabled: boolean;
  editorType: EditorType;
  ref: EditorRef;
  editorOptions?: (editor: any) => void;
  initialize?: (evt: any, editor: any) => void;
  onClickHandler?: (event: MouseEvent, editor: TinyMCEEditor) => void;
  content?: string;
  scroll?: () => void;
  handleActivate?: (editor: any) => void;
  height?: string;
  menuBar?: string | boolean;
  toolBar?: string | boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

const TinyEditor = forwardRef<EditorRef, ITinyEditor>((props, ref) => {
  const [fontSize, setFontSize] = useState(100);

  const handleFontSizeChange = (value: number) => {
    setFontSize(value);
    if (editorRef.current) {
      const currentZoom = parseInt(editorRef.current.getBody().style.zoom);
      const currentWidth =
        parseInt(editorRef.current.getBody().style.width) ||
        parseInt(window.getComputedStyle(editorRef.current.getBody()).width);

      const width = (currentWidth * 100) / currentZoom;
      editorRef.current.getBody().style.width = `${width * (value / 100)}px`;
      editorRef.current.getBody().style.zoom = `${fontSize}%`;
    }
  };

  const {
    editorType = EditorTypeEnum.Target,
    disabled,
    initialize = (_evt: any, _editor: any) => {},
    editorOptions = (_editor: any) => {},
    onClickHandler = () => {},
    handleActivate = () => {},
    content,
    scroll,
    height = '60vh',
    toolBar,
    menuBar,
    onBlur,
    onFocus,
  } = props;

  const { sourceFileDoc, editor1Content, editor1Ref, editor2Ref, setEditor1Content } =
    useContext(MappingContext);

  const basicToolbar = 'backcolor | tablecellbackgroundcolor | searchreplace | table';
  const expandedToolbar =
    'bold italic underline strikethrough backcolor tablecellbackgroundcolor | searchreplace | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor removeformat | table | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | tableofcontents typography formatpainter footnotes permanentpen';
  const basicPlugins = 'table searchreplace';
  const expandedPlugins =
    'powerpaste preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount charmap emoticons tableofcontents typography autocorrect advtable formatpainter footnotes permanentpen';

  const plugins = editorType === EditorTypeEnum.Target ? expandedPlugins : basicPlugins;
  const toolbar =
    toolBar ?? (editorType === EditorTypeEnum.Target ? expandedToolbar : basicToolbar);
  const menubar =
    menuBar ?? editorType === EditorTypeEnum.Target
      ? 'file edit view insert format tools table help'
      : false;

  const editorRef = ref || (editorType === EditorTypeEnum.Target ? editor1Ref : editor2Ref);
  const editorContent = editorType === EditorTypeEnum.Target ? editor1Content : sourceFileDoc;

  useEffect(() => {
    setEditor1Content({ html: '' });

    return () => {
      setEditor1Content({ html: '' });
    };
  }, []);

  return (
    <div className="flex flex-col h-full">
      <Editor
        apiKey={REACT_APP_TINYMCE_API_KEY}
        disabled={disabled}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={content ?? editorContent.html}
        init={{
          powerpaste_allow_local_images: true,
          paste_data_images: true,
          paste_as_text: false,
          powerpaste_word_import: 'merge',
          height: height,
          skin: 'fluent',
          icons: 'material',
          elementpath: false,
          content_css: 'fluent',
          branding: false,
          contextmenu: 'acceptValue selectValue',
          contextmenu_avoid_overlap: '.mce-spelling-word',
          menubar: menubar,
          plugins: plugins,
          table_toolbar: '',
          toolbar: toolbar,
          visual: false,
          content_style: DOCUMENT_STYLES + DOCUMENT_MARGINS + MSO_DEFINITIONS,
          deprecation_warnings: false,
          setup: editorOptions,
          spellchecker_language: 'en',
          typography_default_lang: 'en-US',
        }}
        onSetContent={(evt, editor) => {
          initialize(evt, editor);
          scroll && scroll();
        }}
        onActivate={handleActivate}
        onClick={onClickHandler}
        onBeforeAddUndo={() => {
          return false;
        }}
        onUndo={() => {}}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <Slider value={fontSize} changeHandler={handleFontSizeChange} min={80} max={130} step={2} />
    </div>
  );
});

TinyEditor.displayName = 'TinyEditor';
export default TinyEditor;
