import { useMutation, useQueryClient } from '@tanstack/react-query';
import { archiveCatalog, getCatalogColumn, getCatalogData, updateCatalog } from './catalog';
import { addCatalog } from './catalog';
import { convertBlobToBase64 } from '../../../helpers/convertFileType';
import { notifyError, notifySuccess } from '../../../helpers/utils';

export const useCatalog = () => {
  const queryClient = useQueryClient();

  const deleteCatalog = useMutation({
    mutationFn: archiveCatalog,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['Catalog'],
      });
      notifySuccess(data.message);
    },
    onError: (err) => {
      notifyError(err.message);
    },
  });

  const addToCatalog = useMutation({
    mutationFn: addCatalog,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Catalog'],
      });
    },
  });

  const convertBlobToBase64Query = useMutation({
    mutationFn: convertBlobToBase64,
  });

  const getCatalogFileData = useMutation({
    mutationFn: getCatalogData,
    onError: () => {
      notifyError('Something went wrong');
    },
  });

  const getColumn = useMutation({
    mutationFn: getCatalogColumn,
  });

  const editCatalog = useMutation({
    mutationFn: updateCatalog,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Catalog'],
      });
    },
  });

  return {
    deleteCatalog,
    addToCatalog,
    convertBlobToBase64Query,
    getCatalogFileData,
    getColumn,
    editCatalog,
  };
};
