import { twMerge } from 'tailwind-merge';

interface ISlider {
  value: number;
  changeHandler: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
}

const Slider = ({ value, changeHandler, min = 1, max = 100, step = 1, className }: ISlider) => (
  <div className="flex">
    <h5>
      <button
        className="cursor-pointer"
        onClick={() => {
          changeHandler(value - step >= min ? value - step : value);
        }}>
        -
      </button>
    </h5>
    <input
      className={twMerge(`mt-1 ml-2 mr-2`, className)}
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      data-testid="zoom-slider"
      onChange={(e) => changeHandler(+e.target.value)}
    />
    <h5>
      <button
        className="cursor-pointer"
        onClick={() => {
          changeHandler(value + step <= max ? value + step : value);
        }}>
        +
      </button>
    </h5>
  </div>
);

export default Slider;
