import { ImageTagFormValues } from '../../types';

export const initialImageTagValues: ImageTagFormValues = {
  sourceDocumentData: '',
  sourceTableNumber: 0,
  sourceTableColumn: 0,
  sourceTableRow: 0,
  targetDocumentData: '',
  targetTableNumber: 0,
  targetTableColumn: 0,
  targetTableRow: 0,
  note: '',
};
