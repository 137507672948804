import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  FC,
  RefObject,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';

import { getSessionMappings, fetchSourceFile } from '../services/apiCalls';
import { UserInfoContext } from '../pages/Container';
import { IMapComment, IMappingContext, TargetMapping } from '../types';

export const defaultMappingContext: IMappingContext = {
  sourceFileDoc: {},
  setSourceFileDoc: () => {},
  currentMapping: undefined,
  setCurrentMapping: () => {},
  finishedScoring: false,
  setFinishedScoring: () => {},
  sessionMappings: [],
  setSessionMappings: () => {},
  mappingSession: undefined,
  setMappingSession: () => {},
  showLoader: false,
  setShowLoader: () => {},
  editor1Content: { html: '' },
  setEditor1Content: () => {},
  mappingComments: [],
  setMappingComments: () => {},
  isCommentDisplayed: false,
  commentNumber: 1,
  setCommentNumber: () => {},
  editor1Ref: {},
  editor2Ref: {},
  setDocStates: () => {},
  markMappingDone: () => {},
  fetchTargetFile: () => {},
  resetStates: () => {},
  toggleComments: () => {},
  handleSuperscript: () => {},
  isPreviousAvailable: () => false,
  isNextAvailable: () => false,
  handleNavigate: (_type: string) => undefined,
  setIsCommentDisplayed: () => {},
  targetId: 0,
  setTargetId: () => {},
  getElements: () => {},
  addCommentBubble: () => {},
};

export const MappingContext = createContext<IMappingContext>(defaultMappingContext);

interface Props {
  children: ReactNode;
}

export const MappingContextContainer: FC<Props> = ({ children }) => {
  const { firstName, lastName } = useContext(UserInfoContext);

  const location = useLocation();

  const [sourceFileDoc, setSourceFileDoc] = useState(defaultMappingContext.sourceFileDoc);
  const [currentMapping, setCurrentMapping] = useState<TargetMapping | undefined>(
    defaultMappingContext.currentMapping,
  );
  const [finishedScoring, setFinishedScoring] = useState(defaultMappingContext.finishedScoring);
  const [sessionMappings, setSessionMappings] = useState<TargetMapping[] | undefined>(
    defaultMappingContext.sessionMappings,
  );
  const [mappingSession, setMappingSession] = useState(defaultMappingContext.mappingSession);
  const [showLoader, setShowLoader] = useState(defaultMappingContext.showLoader);
  const [editor1Content, setEditor1Content] = useState(defaultMappingContext.editor1Content);
  const [mappingComments, setMappingComments] = useState<IMapComment[]>(
    defaultMappingContext.mappingComments,
  );
  const [isCommentDisplayed, setIsCommentDisplayed] = useState(
    defaultMappingContext.isCommentDisplayed,
  );
  const [commentNumber, setCommentNumber] = useState(defaultMappingContext.commentNumber);
  const [targetId, setTargetId] = useState(defaultMappingContext.targetId);
  const editor1Ref = useRef(null);
  const editor2Ref = useRef(null);

  const markMappingDone = (key: string, value = 'done') => {
    setSessionMappings(
      (prevSessionMappings) =>
        prevSessionMappings?.map((mapping) => {
          if (mapping.mappingId === currentMapping?.mappingId) {
            const updateMapping = { ...mapping };
            if (key === 'status') {
              updateMapping['statusupdatername'] = `${firstName} ${lastName}`;
            }
            return { ...updateMapping, [key]: value };
          } else {
            return mapping;
          }
        }),
    );
  };

  const handleNavigate = (direction: string) => {
    const index = (currentMapping?.mappingNumber || 0) - 1;
    return sessionMappings && sessionMappings[direction === 'next' ? index + 1 : index - 1];
  };

  const getElements = (editor: RefObject<HTMLIFrameElement>, query: string) =>
    editor?.current?.contentWindow?.document.querySelectorAll(query);

  const setDocStates = () => {
    getSessionMappings(location.state?.id)
      .then(async (res) => {
        setSessionMappings(res.data.mappings?.map((o: TargetMapping) => ({ ...o })));
        setTargetId(res.data.catalogId);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const isPreviousAvailable = () => {
    return currentMapping?.mappingNumber === 1 ? false : true;
  };

  const isNextAvailable = () => {
    return currentMapping?.mappingNumber === sessionMappings?.length ? false : true;
  };

  const resetStates = () => {
    setSessionMappings(undefined);
    setCurrentMapping(undefined);
    setSourceFileDoc({});
    setEditor1Content({ html: '' });
    setMappingSession(undefined);
    setShowLoader(false);
  };

  const fetchTargetFile = async () => {
    const res = await fetchSourceFile(location.state.doc.key || location.state.id);
    setEditor1Content({ html: res.data.html });
  };

  const toggleComments = async () => {
    setIsCommentDisplayed((previous) => !previous);
  };

  const handleSuperscript = (
    name = firstName,
    number = commentNumber,
    mappingNumber = currentMapping?.mappingNumber,
    targetNodeId = currentMapping?.mappingId,
  ) => {
    const query = `[mapping="${mappingNumber}"], [data-nodeid='${targetNodeId}']`;
    const dataPoints = (
      editor1Ref?.current as HTMLIFrameElement | null
    )?.contentWindow?.document.querySelectorAll(query) as NodeListOf<HTMLElement>;
    Array.from(dataPoints).forEach((el) => {
      if (isCommentDisplayed) {
        const sup = document.createElement('sup');
        sup.textContent = `${name.slice(0, 1)}${number}`;
        el.appendChild(sup);
      } else {
        const sup = el.querySelector('sup');
        sup && el.removeChild(sup);
      }
    });
  };

  const addCommentBubble = (
    mappingNumber = currentMapping?.mappingNumber,
    targetNodeId = currentMapping?.mappingId,
  ) => {
    const query = `[mapping="${mappingNumber}"], [data-nodeid='${targetNodeId}']`;
    const dataPoints = (
      editor1Ref?.current as HTMLIFrameElement | null
    )?.contentWindow?.document.querySelectorAll(query) as NodeListOf<HTMLElement>;
    dataPoints &&
      Array.from(dataPoints).forEach((el) => {
        if (!el.querySelector("[data-comment='true']")) {
          el.innerHTML += `<div contenteditable="false" style="cursor: pointer; background-color: deepskyblue;width: 10px;height: 10px;border-radius: 50%; position: absolute; top: 5px; right: 5px;" data-comment='true'></div>`;
          el.style.position = 'relative';
        }
      });
  };

  useEffect(() => {
    setIsCommentDisplayed(false);
  }, [editor1Content]);

  return (
    <MappingContext.Provider
      value={{
        setDocStates,
        markMappingDone,
        sourceFileDoc,
        setSourceFileDoc,
        mappingSession,
        setMappingSession,
        currentMapping,
        setCurrentMapping,
        finishedScoring,
        setFinishedScoring,
        sessionMappings,
        setSessionMappings,
        showLoader,
        setShowLoader,
        editor1Content,
        setEditor1Content,
        fetchTargetFile,
        resetStates,
        mappingComments,
        setMappingComments,
        toggleComments,
        isCommentDisplayed,
        setIsCommentDisplayed,
        editor1Ref,
        editor2Ref,
        commentNumber,
        setCommentNumber,
        handleSuperscript,
        isPreviousAvailable,
        isNextAvailable,
        handleNavigate,
        targetId,
        setTargetId,
        getElements,
        addCommentBubble,
      }}>
      {children}
    </MappingContext.Provider>
  );
};

export default MappingContextContainer;
