import { useMutation } from '@tanstack/react-query';
import { notifyError } from '../../../helpers/utils';
import { addNote } from './suggestion';

export const useSuggestion = () => {
  const addRejectionNote = useMutation({
    mutationFn: addNote,
    onError: (err) => {
      console.error(err);
      notifyError('An error occurred');
    },
  });

  return {
    addRejectionNote,
  };
};
