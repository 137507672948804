import { useState } from 'react';

const useAsyncOperation = <T, P extends any[]>(callback: (...args: P) => Promise<T>) => {
  const [isLoading, setIsLoading] = useState(false);

  const executeCallback = async (...args: P) => {
    try {
      setIsLoading(true);
      const data = await callback(...args);
      return { success: true as const, data };
    } catch (error) {
      return { success: false as const, error };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    executeCallback,
  };
};

export default useAsyncOperation;
