// React-related imports
import { FC, useMemo } from 'react';
import { BsPencil } from 'react-icons/bs';

// Types
import { IAvatar } from '../../types';

const Avatar: FC<IAvatar> = ({
  handleEdit,
  handleImageUpload,
  user,
  isExpanded = false,
  avatarStyling = {},
  imageUrl,
}) => {
  const { noIconStyling = '', userIconStyling = '', buttonStyling = '' } = avatarStyling;
  const { firstName, lastName, avatar } = user;
  const userName = `${firstName} ${lastName}`;

  const userInitials = useMemo(() => {
    const lastNameParts = lastName?.split(/[ -]/).filter((e) => e);
    const lastPart = lastNameParts?.at(-1) ?? '';
    return (firstName?.charAt(0) || '') + (lastPart?.charAt(0) || '');
  }, [firstName, lastName]);

  const noIcon: JSX.Element = (
    <div
      className={`w-100 bg-slate-100 align-middle font-normal text-slate-300 ${noIconStyling}`}
      data-testid="user-initials">
      {userInitials}
    </div>
  );

  const userIcon: JSX.Element = (
    <img
      className={`h-full w-full object-cover ${userIconStyling}`}
      src={imageUrl || avatar}
      alt="Avatar"
    />
  );

  const userAvatar = () => {
    switch (true) {
      case !!imageUrl || !!avatar:
        return userIcon;
      default:
        return noIcon;
    }
  };

  const editImage = handleImageUpload ? (
    <>
      <BsPencil
        className="p-1 absolute bottom-0 ml-12 rounded-full bg-gray-300 hover:cursor-pointer hover:bg-primary_bg_color hover:text-white"
        size={24}
      />
      <input
        type="file"
        data-testid="user-image-input"
        style={{ display: 'none' }}
        id="img"
        name="img"
        accept="image/*"
        onChange={handleImageUpload}
      />
    </>
  ) : null;

  const settingClickHandler = () => {
    if (handleEdit) {
      handleEdit();
    }
  };

  return (
    <div className={`flex items-center  ${isExpanded && 'ml-4 width-transition'}`}>
      <div
        data-testid="avatarIcon"
        className="flex items-center cursor-pointer"
        onClick={() => settingClickHandler()}>
        <button
          role="button"
          className={`border-solid border border-slate-300 rounded-full bg-center overflow-clip ${buttonStyling}`}>
          <div className="m-auto w-full bg-contain ">
            {userAvatar()}
            {<label htmlFor="img"> {editImage} </label>}
          </div>
        </button>
        {isExpanded && (
          <div>
            <p className="text-black text-xs items-center ml-2 font-bold">{userName}</p>
            <p className="text-black text-xs items-center ml-2 font-normal">Vew profile</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Avatar;
