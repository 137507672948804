import { MappingTypeEnum } from '../../../types';
import { ActionOptions, OptionType } from '../types';

export const getGroupedOptions = (options: ActionOptions, mappingType: MappingTypeEnum) =>
  Object.values(options).reduce((acc: { value: OptionType; disabled?: boolean }[], action) => {
    if (action.target.includes(mappingType)) {
      acc.push({ value: action.value, disabled: action.disabled });
    }
    return acc;
  }, []);
