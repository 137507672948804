import { useContext, useEffect } from 'react';
import { GeneralizationContext } from '../contexts/GeneralizationContext';

const useFullscreen = () => {
  const { setIsFullScreen } = useContext(GeneralizationContext);

  const handleEscapeKey = (event: { key: string }) => {
    if (event.key === 'Escape') {
      setIsFullScreen(false);
    }
  };
  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };
  useEffect(() => {
    document.addEventListener('fullscreenchange', toggleFullScreen);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('fullscreenchange', toggleFullScreen);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return {
    toggleFullScreen,
  };
};

export default useFullscreen;
