import { Link } from 'react-router-dom';

const NoPage = () => {
  return (
    <p className="text-center mt-20 text-lg font-bold">
      Sorry, the page is not found.
      <br />
      <span>
        <Link to="/" className="text-blue-600 underline">
          Return to home page
        </Link>
      </span>
    </p>
  );
};

export default NoPage;
