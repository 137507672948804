import { RefObject } from 'react';
import { EditorRef, MarkerAttributes } from '../types';
import { getElement, scrollIntoViewIfNeeded } from './generalizationHelpers';
import { BLUE_PIN_SHADE, RED_PIN_SHADE } from '../constants';

export const removeActivePin = (doc: Document) => {
  const existingSvgElement = doc?.querySelector('svg[data-marker="active"]');
  if (existingSvgElement) {
    existingSvgElement.remove();
  }
};

export const getSvgPinById = (doc: Document, pinId: string): HTMLElement | null => {
  return doc?.querySelector<HTMLElement>(`svg[data-pinid="${pinId}"]`);
};

export const getActiveSvgPin = (doc: Document): HTMLElement | null => {
  return doc?.querySelector<HTMLElement>('svg[data-marker="active"]');
};

export const generateCustomSVG = (
  x: number,
  y: number,
  pinId: string,
  status = 'saved',
  fillColor1 = '#C0392B',
  fillColor2 = '#BDC3C7',
  fillColor3 = '#7F8C8D',
  fillColor4 = '#E74C3C',
): SVGSVGElement => {
  const svgPin = `<svg width="25" height="25" viewBox="0 0 24 24" data-marker=${status}  xmlns="http://www.w3.org/2000/svg" 
                    data-pinid=${pinId}
                    style="
                    position: absolute;
                    left: ${x}%;
                    top: ${y}%;
                    transform: translate(-1%, -99%);
                  ">
                    <path d="m18.266 4.286-9.192 9.192 5.237 5.237c.357-1.199.483-2.486.278-3.681l5.657-5.657c.860.011 1.79-.190 2.638-.473l-4.618-4.618z" fill="${fillColor1}"/>
                    <path d="m9.074 13.478-5.657 5.657-.707 2.121 7.071-7.070-.707-.708z" fill="${fillColor2}"/>
                    <path d="M9.781 14.185 2.71 21.256l2.121-.707 5.657-5.657-.707-.707z" fill="${fillColor3}"/>
                    <path d="M15.062 1.082c-.282.848-.484 1.778-.473 2.638L8.932 9.377c-1.195-.205-2.482-.079-3.68.278l4.529 4.53 9.192-9.192-3.91-3.911z" fill="${fillColor4}"/>
                  </svg>`;
  const doc = new DOMParser().parseFromString(svgPin, 'text/html');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return doc.querySelector('svg')!;
};

export const applySVGToElement = (
  svgAttributes: MarkerAttributes,
  key: string,
  editorRef: RefObject<EditorRef>,
  status = 'saved',
) => {
  const { positionX, positionY, targetId } = svgAttributes;
  if (positionX && positionY) {
    const svgElement = generateCustomSVG(positionX, positionY, targetId, status);
    const sourceElement = getElement(editorRef, key);

    if (sourceElement && sourceElement.style && svgElement) {
      sourceElement.style.position = 'relative';
      sourceElement.appendChild(svgElement);
    }
  }
};

export const removeAllSvgPins = (element: HTMLElement | Document, makeClone = false): Element => {
  const clonedElement = makeClone ? (element.cloneNode(true) as HTMLElement) : element;

  clonedElement.querySelectorAll('svg[data-pinid]').forEach((svg) => svg.remove());
  return clonedElement as Element;
};

export const applyCustomSVGs = (
  newValues: Record<string, MarkerAttributes[]>,
  editorRef: RefObject<EditorRef>,
) => {
  const doc = editorRef?.current?.getDoc();
  if (doc) {
    removeAllSvgPins(doc);
  }
  Object.entries(newValues).forEach(([nodeId, svgAttributes]) => {
    svgAttributes.forEach((svgAttribute) => applySVGToElement(svgAttribute, nodeId, editorRef));
  });
};

export const colorSVGPaths = (
  pinElement: HTMLElement | SVGSVGElement,
  shades: string[],
  isRandom = true,
) => {
  if (pinElement) {
    const pathElements = pinElement.querySelectorAll('path');
    pathElements.forEach((path, index) => {
      const shade = isRandom ? shades[index % shades.length] : shades[index];
      path.setAttribute('fill', shade);
    });
  }
};

export const handleSvgPinActivation = (
  editorRef: React.MutableRefObject<any>,
  pinId: string | undefined,
) => {
  if (!pinId) return;

  const pinElement = getSvgPinById(editorRef.current.getDoc(), pinId);
  const activePinElement = getActiveSvgPin(editorRef.current.getDoc());

  if (activePinElement) {
    const defaultShades = RED_PIN_SHADE;
    activePinElement.setAttribute('data-marker', 'saved');
    colorSVGPaths(activePinElement, defaultShades, false);
  }

  if (pinElement) {
    colorSVGPaths(pinElement, BLUE_PIN_SHADE);
    pinElement.setAttribute('data-marker', 'active');
    scrollIntoViewIfNeeded(pinElement);
  }
};
