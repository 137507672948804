import { ReactNode, createContext, useContext, useState, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';

type CSVLinkProp<T extends keyof CSVLink['props']> = CSVLink['props'][T];

export type ExportCsvProps = {
  data: CSVLinkProp<'data'>;
  headers: CSVLinkProp<'headers'>;
  filename: CSVLinkProp<'filename'>;
};

export interface ExportCsvContext {
  exportCSV: (csvLinkProps: ExportCsvProps) => void;
}

export const ExportCsvContext = createContext<ExportCsvContext | undefined>(undefined);

export const ExportCsvContextProvider = ({ children }: { children?: ReactNode }) => {
  const [data, setData] = useState<CSVLinkProp<'data'>>([]);
  const [headers, setHeaders] = useState<CSVLinkProp<'headers'>>([]);
  const [filename, setFilename] = useState<CSVLinkProp<'filename'>>('');
  const [readyToExportCSV, setReadyToExportCSV] = useState(false);

  const downloadCVSButton = useRef<any>(null);

  const exportCSV = (csvLinkProps: ExportCsvProps) => {
    setFilename(csvLinkProps.filename);
    setHeaders(csvLinkProps.headers);
    setData(csvLinkProps.data);
    setReadyToExportCSV(true);
  };

  const resetStates = () => {
    setData([]);
    setHeaders([]);
    setFilename('');
    setReadyToExportCSV(false);
  };

  useEffect(() => {
    if (readyToExportCSV && downloadCVSButton.current) {
      downloadCVSButton.current.link.click();

      resetStates();
    }
  }, [readyToExportCSV]);

  return (
    <ExportCsvContext.Provider value={{ exportCSV }}>
      {children}
      <CSVLink
        ref={downloadCVSButton}
        data={data}
        headers={headers}
        filename={filename}
        hidden={true}
      />
    </ExportCsvContext.Provider>
  );
};

export const useExportCsvContext = (): ExportCsvContext => {
  const context = useContext(ExportCsvContext);

  if (context === undefined) {
    throw new Error('useExportCsvContext must be used within a ExportCsvContextProvider');
  }

  return context;
};
