import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  ReactNode,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getUserPermissions,
  updateLoginStatus,
  updateNewCognitoUsersPassword,
} from '../services/apiCalls';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { notifyError, notifySuccess } from '../helpers/utils';
import {
  getUserGroupsFromLocalStorage,
  storeUserGroupsInLocalStorage,
  getAuthorizedPagesFromLocalStorage,
  storeAuthorizedPagesInLocalStorage,
} from '../helpers/localStorage';

import { setAuthHeader } from '../helpers/AxiosConfig';
import { getCurrentUser, signInUser, signOutUser } from '../helpers/amplify';
import { AuthState, IUseAuth } from '../types';

export const VALID_PERMISSIONS = ['/', 'rolePermission', '/logout', '*'];

export type AuthAction =
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_AUTHENTICATED'; payload: boolean }
  | { type: 'SET_PERMISSIONS'; payload: string[] }
  | { type: 'SET_AUTHORIZED_PAGES'; payload: string[] }
  | { type: 'SET_VALID_SESSION'; payload: boolean };

const initialState: AuthState = {
  isLoading: true,
  isAuthenticated: false,
  permissions: getUserGroupsFromLocalStorage() || [],
  authorizedPages: getAuthorizedPagesFromLocalStorage() || [],
  validSession: false,
};

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_AUTHENTICATED':
      return { ...state, isAuthenticated: action.payload };
    case 'SET_PERMISSIONS':
      return { ...state, permissions: action.payload };
    case 'SET_AUTHORIZED_PAGES':
      return { ...state, authorizedPages: action.payload };
    case 'SET_VALID_SESSION':
      return { ...state, validSession: action.payload };
    default:
      return state;
  }
}

export const AuthContext = createContext<IUseAuth>({} as IUseAuth);

export const ProvideAuth: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [challengeResp, setChallengeResp] = useLocalStorage('challengeResp', {});
  const [isSignInSuccess, setIsSignInSuccess] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', payload: true });
    getCurrentUser().then((res) => {
      dispatch({
        type: 'SET_AUTHENTICATED',
        payload: res.success,
      });
      dispatch({ type: 'SET_LOADING', payload: false });
      if (res.success) {
        fetchPermissions();
      }
    });
  }, [isSignInSuccess]);

  const logOutHandler = async () => {
    await signOut();
    navigate('/login');
  };

  const fetchPermissions = async () => {
    try {
      const res = await getUserPermissions();
      const pages = res.data.permissions;
      const roles = res.data.roles;
      const permissions = pages.map((permission: { name: string }) => permission.name);
      if (roles.length === 0) {
        notifyError(`Please contact Admin For Permissions.`);
        signOut();
        return;
      }
      setLoginVars({ permissions, roles });
    } catch (error) {
      console.error(error);
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await signInUser(username, password);
      if (response.success) {
        if (response.data.challengeName) {
          setChallengeResp(response.data);
          return { success: true, challengeName: response.data.challengeName };
        } else {
          updateLoginStatus(true);
          await fetchPermissions();
          setIsSignInSuccess(true);
          return { success: true };
        }
      } else {
        console.error(response.error);
        notifyError(response.message);
        return { success: false, message: response.message };
      }
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const signOut = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      updateLoginStatus(false);
      await signOutUser();
      dispatch({ type: 'SET_AUTHENTICATED', payload: false });
      localStorage.clear();
      return { success: true };
    } catch (error: any) {
      console.error(error);
      return { success: false, error: error.message };
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const createNewPassword = async (newPassword: string) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await updateNewCognitoUsersPassword({
        userName: challengeResp?.username,
        password: newPassword,
        challengeSession: challengeResp?.Session,
      });

      if (response.data?.AuthenticationResult) {
        setAuthHeader(response.data.AuthenticationResult.AccessToken);
        setIsSignInSuccess(true);

        notifySuccess('Password Updated Successfully!');
        return { success: true };
      }
    } catch (error: any) {
      console.error(error);
      return { success: false, error: error.message };
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const setLoginVars = async ({
    roles,
    permissions,
  }: {
    roles: string[];
    permissions: string[];
  }) => {
    storeUserGroupsInLocalStorage(roles);
    storeAuthorizedPagesInLocalStorage(permissions);
    dispatch({
      type: 'SET_AUTHORIZED_PAGES',
      payload: permissions,
    });
    dispatch({ type: 'SET_PERMISSIONS', payload: roles });
    dispatch({ type: 'SET_AUTHENTICATED', payload: true });
    dispatch({ type: 'SET_VALID_SESSION', payload: true });
  };

  const setPermissions = (permissions: string[]) => {
    dispatch({ type: 'SET_PERMISSIONS', payload: permissions });
    storeUserGroupsInLocalStorage(permissions);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading: state.isLoading,
        isAuthenticated: state.isAuthenticated,
        permissions: state.permissions,
        authorizedPages: state.authorizedPages,
        signOut,
        signIn,
        createNewPassword,
        setPermissions,
        logOutHandler,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
