import { UserFormProps } from '../EditUsers.types';

export const userInputList: {
  label: string;
  name: keyof UserFormProps;
  type: string;
  disabled: boolean;
  placeholder?: number;
}[] = [
  { label: 'First Name', name: 'firstName', type: 'string', disabled: false },
  { label: 'Last Name', name: 'lastName', type: 'string', disabled: false },
  { label: 'Email', name: 'email', type: 'string', disabled: true },
];
