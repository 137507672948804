import { CSSProperties } from 'react';

export const DEFAULT_COLORS = {
  primary: {
    _50: '#2AACE2',
    _100: '#1C99CE',
    _200: '#177BA6',
    _300: '#115873',
    _400: '#0A3A4C',
  },
  system: {
    _50: '#FFFFFF',
    _100: '#000000',
  },
  neutral: {
    _50: '#F9FAFB',
    _100: '#E5E7EB',
    _200: '#9CA3AF',
    _300: '#4B5563',
    _400: '#414141',
  },
  positive: {
    _50: '#34D399',
    _100: '#059669',
    _200: '#065F46',
  },
  negative: {
    _50: '#F87171',
    _100: '#EF4444',
    _200: '#7F1D1D',
  },
  warning: {
    _50: '#FCD34D',
    _100: '#FAAE1A',
    _200: '#C24700',
  },
} as const;

export const TAILWIND_COLOR_VARIABLES = {
  PRIMARY: {
    _50: '--primary-50',
    _100: '--primary-100',
    _200: '--primary-200',
    _300: '--primary-300',
    _400: '--primary-400',
  },
  SYSTEM: {
    _50: '--system-50',
    _100: '--system-100',
  },
  NEUTRAL: {
    _50: '--neutral-50',
    _100: '--neutral-100',
    _200: '--neutral-200',
    _300: '--neutral-300',
    _400: '--neutral-400',
  },
  POSITIVE: {
    _50: '--positive-50',
    _100: '--positive-100',
    _200: '--positive-200',
  },
  NEGATIVE: {
    _50: '--negative-50',
    _100: '--negative-100',
    _200: '--negative-200',
  },
  WARNING: {
    _50: '--warning-50',
    _100: '--warning-100',
    _200: '--warning-200',
  },
} as const;

type Flatten<T> = T extends object ? { [K in keyof T]: Flatten<T[K]> }[keyof T] : T;

type ColorValue = Flatten<typeof TAILWIND_COLOR_VARIABLES>;

export type ColorProperties = CSSProperties & Record<ColorValue, string>;
