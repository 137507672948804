import { z } from 'zod';

export const imageTagFormSchema = z.object({
  sourceDocumentData: z.string().min(1, { message: 'Source Document Data is required' }),
  sourceTableNumber: z.coerce.number().optional().nullable(),
  sourceTableColumn: z.coerce.number().optional().nullable(),
  sourceTableRow: z.coerce.number().optional().nullable(),
  targetDocumentData: z.string().min(1, { message: 'Target Document Data is required' }),
  targetTableNumber: z.coerce.number().optional().nullable(),
  targetTableColumn: z.coerce.number().optional().nullable(),
  targetTableRow: z.coerce.number().optional().nullable(),
  note: z.string().optional(),
});

export type FormValues = z.infer<typeof imageTagFormSchema>;
