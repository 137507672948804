// React-related imports
import { FC, useMemo } from 'react';

// Components
import { PaginatedTable } from '../../../../components/PaginatedTable';

// API services
import { getUsers } from '../../../../services/api/user/user';

// Types
import { SettingTableProps } from './SettingTable.types';

// Utility functions
import { generateColumnsByTab } from './utils/SettingColumns';
import { SettingsTabs } from '../../types';
import { getCatalogItems } from '../../../../services/api/catalogItems';

const SettingsTable: FC<SettingTableProps> = ({
  activeTab,
  paginatedFilteredData,
  handleColumnSort,
  handlePageChange,
  handlePerPageChange,
}) => {
  const sortedColumn = useMemo(
    () => ({
      column: (paginatedFilteredData.sort?.column as string) || '',
      order: paginatedFilteredData.sort?.type || 'DESC',
    }),
    [paginatedFilteredData.sort?.column, paginatedFilteredData.sort?.type],
  );

  const activeTabColumns = generateColumnsByTab(activeTab, handleColumnSort, sortedColumn);

  const getFilteredPaginatedUsers = () => getUsers(paginatedFilteredData);
  const getFilteredPaginatedCatalogItems = () => getCatalogItems(paginatedFilteredData);

  const apiCalls: Partial<Record<SettingsTabs, any>> = {
    [SettingsTabs.USER]: getFilteredPaginatedUsers,
    [SettingsTabs.DROPDOWN_LISTS]: getFilteredPaginatedCatalogItems,
  };

  return (
    <PaginatedTable
      additionalColumns={activeTabColumns}
      paginatedFilteredData={paginatedFilteredData}
      getData={apiCalls[activeTab]}
      handlePageChange={handlePageChange}
      handlePerPageChange={handlePerPageChange}
      queryKey={[activeTab]}
      isCheckboxRequired={false}
      label={activeTab === SettingsTabs.USER ? 'users' : 'items'}
    />
  );
};

export default SettingsTable;
