import { useContext, useEffect, useState } from 'react';
import { Search } from '../components/Search';
import { fetchAutomattedDocument } from '../services/apiCalls';
import { AutoMappedDocuments } from '../components/AutoMappedDocuments';
import { AutomappedDocumentsFilterType, IAutoMappedDocument } from '../types';
import { useFilterStates } from '../hooks/useFilterStates';
import { CatalogContext } from '../contexts/CatalogContext';
import Dropdown from '../components/elements/dropdown';
import { updateParams } from '../helpers/paramsHelpers';
import BreadCrumbs from '../components/elements/BreadCrumbs';

const AutoMappedPage = () => {
  const [autoMappingData, setAutoMappingData] = useState<IAutoMappedDocument[]>([]);

  const { filters, setFilters } = useFilterStates<AutomappedDocumentsFilterType>({
    columnSort: '',
    createdById: '',
    text: '',
    sortType: '',
  });
  const { dropdownUsersData, refreshDistinctValues } = useContext(CatalogContext) as any;

  const getAllAutomappedDocs = async () => {
    const objQuery = {
      type: 'auto-mapping',
      ...filters,
    };
    await fetchAutomattedDocument(objQuery).then((res) => {
      const { data } = res.data;
      setAutoMappingData(data);
      refreshDistinctValues();
    });
  };

  const createdByChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters('createdById', e.target.value);
  };

  useEffect(() => {
    updateParams({ basePath: 'auto-mapped', filters, type: 'auto-mapping' });
    getAllAutomappedDocs();
  }, [filters]);

  return (
    <>
      <BreadCrumbs
        className="text-start mt-8 mb-10 identifiers"
        data={[
          { link: '/', text: 'Home' },
          { text: 'Mapped Documents', clickable: false },
        ]}
      />
      <div className="mt-20">
        <h1 className="text-center font-bold mb-8">Mapped Documents</h1>
        <div className="flex mt-4 w-full mb-4">
          <span className="w-1/2 mb-1 ml-2">
            <Search onChangeCallback={(term) => setFilters('text', term)} />
          </span>
        </div>
        <div className="absolute right-0 -mt-20 mr-2 flex space-x-2">
          <div className="mt-8 w-full">
            <Dropdown
              options={dropdownUsersData}
              placeholder="Mapped by"
              changeHandler={createdByChangeHandler}
              selectedValue={filters.createdById}
            />
          </div>
        </div>
        <div className="bg-white p-4 rounded-md">
          <AutoMappedDocuments
            autoMappingData={autoMappingData}
            getAllAutomappedDocs={getAllAutomappedDocs}
            setFilters={setFilters}
          />
        </div>
      </div>
    </>
  );
};

export default AutoMappedPage;
