import { useMutation } from '@tanstack/react-query';
import { notifyError, notifySuccess } from '../../../helpers/utils';
import { createPlaceholders, updatePlaceholders } from './placeholder';

export const usePlaceholder = () => {
  const addPlaceholders = useMutation({
    mutationFn: createPlaceholders,
    onSuccess: ({ data }) => {
      notifySuccess(data.message);
    },
    onError: (err: { data: { message: string } }) => {
      notifyError(err.data.message || 'An error occurred');
    },
  });

  const updatePlaceholder = useMutation({
    mutationFn: updatePlaceholders,
    onSuccess: ({ data }) => {
      notifySuccess(data.message);
    },
    onError: (err: { data: { message: string } }) => {
      notifyError(err.data.message || 'An error occurred');
    },
  });

  return {
    addPlaceholders,
    updatePlaceholder,
  };
};
