import { z } from 'zod';

export const createCatalogItemsSchema = (fields: string[]): z.ZodObject<z.ZodRawShape> => {
  const shape = fields.reduce(
    (acc, field) => {
      acc[field] = z.string().min(1, { message: 'Item name is required' });
      return acc;
    },
    {} as Record<string, z.ZodString>,
  );

  return z.object({
    ...shape,
  });
};
