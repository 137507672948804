import axios from 'axios';
import { notifyError } from './utils';
import { getAPIUrl } from './clientHelpers';
import { getAccessJwtToken } from './amplify';
import { deleteDataFromLocalStorage } from './localStorage';
const instance = axios.create({
  baseURL: getAPIUrl(),
});
instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const setAuthHeader = (token: string) => {
  if (!token) return;
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const getAuthHeader = () => {
  return instance.defaults.headers.common['Authorization'];
};

const setInterceptors = () => {
  instance.interceptors.request.use(async (request) => {
    (request as any).headers['Authorization'] = `Bearer ${await getAccessJwtToken()}`;
    return request;
  });
  instance.interceptors.response.use(
    (response: any): any => {
      return { success: true, status: response.status, data: response.data };
    },
    async (error) => {
      const originalRequest = error.config;
      const resp = error.response || error;
      const data = resp.data || resp;
      if (resp.status >= 400 && resp.status < 500) {
        if (resp.status === 401) {
          if (resp.data?.message === 'Token Expired') {
            const newToken = await getAccessJwtToken();
            if (newToken) {
              const newRequest = {
                ...originalRequest,
                headers: {
                  ...originalRequest.headers,
                  Authorization: `Bearer ${newToken}`,
                },
              };
              return instance(newRequest);
            }
          } else {
            deleteDataFromLocalStorage();
            window.location.href = '/';
          }
        }
        notifyError(data);
      }
      const { status = 500 } = resp;
      return Promise.reject({ success: false, status, data });
    },
  );
};

(instance as any).origin = axios;
export { setAuthHeader, setInterceptors, getAuthHeader };
export default instance;
