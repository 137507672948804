/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createColumnHelper } from '@tanstack/react-table';
import { DBUser } from '../../../../../types';
import { getDateTime } from '../../../../../helpers/catalogHelpers';
import { TableHeader } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { SettingsTabs } from '../../../types';
import { CatalogItems, SortOrderType, UserSortParams } from '../../../../../services/api/types';
import { capitalizeFirstLetter, getUserFullName } from '../../../../../helpers/utils';
import { UserActions } from '../../UserActions';
import { ItemActions } from '../../ItemActions';

export const generateColumnsByTab = (
  activeTab: SettingsTabs,
  handleColumnSort: (sort: SortOrderType, column: keyof UserSortParams) => void,
  sortedColumn: { column: string; order: SortOrderType },
) => {
  const userColumnHelper = createColumnHelper<DBUser>();
  const dropDownListColumnHelper = createColumnHelper<CatalogItems>();

  const createTableHeader = (
    title: string,
    accessor = '',
    activeColumn = { column: '', order: 'ASC' as SortOrderType },
  ) => {
    return () => (
      <TableHeader
        ColumnName={accessor}
        Title={title}
        handleColumnSort={handleColumnSort as (sort: SortOrderType, column: string) => void}
        activeColumn={activeColumn}
      />
    );
  };

  const columnsByTab: Record<SettingsTabs, any[]> = {
    [SettingsTabs.USER]: [
      userColumnHelper.accessor('firstName', {
        header: createTableHeader('First Name', 'firstName', sortedColumn),
        cell: (info) => info.getValue(),
        size: 380,
      }),
      userColumnHelper.accessor('lastName', {
        header: createTableHeader('Last Name', 'lastName', sortedColumn),
        cell: (info) => info.getValue(),
        size: 380,
      }),
      userColumnHelper.accessor('roles', {
        header: createTableHeader('Role'),
        cell: (info) => {
          const roles = info.row.original.roles;
          return roles.map((role: { id?: number; name: string }, index) => (
            <span key={role.id ?? index}>
              {capitalizeFirstLetter(role.name)}
              {index < roles.length - 1 && ', '}
            </span>
          ));
        },
        size: 213,
      }),
      userColumnHelper.accessor('lastLogin', {
        header: createTableHeader('Last Accessed', 'lastLogin', sortedColumn),
        cell: (info) => {
          const lastLogin = info.row.original.lastLogin;
          return lastLogin && getDateTime(lastLogin);
        },
        size: 213,
      }),
      userColumnHelper.accessor('email', {
        header: createTableHeader('Email', 'email', sortedColumn),
        cell: (info) => info.getValue(),
        size: 380,
      }),
      userColumnHelper.accessor('id', {
        header: createTableHeader('Actions'),
        cell: (info) => {
          return (
            <UserActions
              userId={info?.row?.original?.id}
              activeTab={activeTab}
              row={info?.row?.original}
            />
          );
        },
        size: 380,
      }),
    ],
    [SettingsTabs.DROPDOWN_LISTS]: [
      dropDownListColumnHelper.accessor('name', {
        header: createTableHeader('Item name', 'name', sortedColumn),
        cell: (info) => info.getValue(),
        size: 400,
      }),
      dropDownListColumnHelper.accessor('type', {
        header: createTableHeader('Type', 'type', sortedColumn),
        cell: (info) => info.getValue(),
        size: 400,
      }),
      dropDownListColumnHelper.accessor('createdBy', {
        header: createTableHeader('Created By', 'createdBy', sortedColumn),
        cell: (info) => getUserFullName(info.row.original.createdBy),
        size: 70,
      }),
      dropDownListColumnHelper.accessor('id', {
        header: createTableHeader('Actions'),
        cell: (info) => {
          return <ItemActions row={info?.row?.original} />;
        },
        size: 380,
      }),
    ],
  };

  return columnsByTab[activeTab];
};
