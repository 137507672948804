import { getCellElement } from './generalizationHelpers';

export const getMappingNotes = (rejectionNotes = [], suggestionId: number) =>
  rejectionNotes.find((note: any) => note.mappingId === suggestionId) || {};

export const getTableTitle = (node: Element | null): string | undefined => {
  const table = node?.closest('table');
  if (!table) {
    return undefined;
  }
  const findTitle = (element: HTMLElement | null): string | undefined => {
    return element?.innerText?.includes('Table') ? element.innerText : undefined;
  };
  let currentElement: HTMLElement | null = table;
  while (currentElement && currentElement !== document.body) {
    const title = findTitle(currentElement.previousElementSibling as HTMLElement);
    if (title) {
      return title;
    }
    currentElement = currentElement.parentElement;
  }
  return undefined;
};

export const findTableNumberByTdNodeId = (tdNodeId: string, doc: Document): number => {
  const fileElement = doc.querySelector(`[data-nodeId='${tdNodeId}']`);
  const tableTitle = getTableTitle(fileElement);
  const regex = /^Table\s+(\d+):/;
  if (tableTitle) {
    const match = tableTitle.match(regex);
    if (match && match[1]) {
      return parseInt(match[1]);
    }
  }
  return 0;
};

export const constructSuggestionLocation = (
  doc: Document,
  nodeId: string,
): { tableNumber: number; tableColumn: number; tableRowNumber: number } => {
  const fileElement = doc.querySelector(`[data-nodeId='${nodeId}']`);
  let targetCell: HTMLTableCellElement | null = null;
  if (fileElement) targetCell = getCellElement(fileElement, doc.body);
  const tableNumber = findTableNumberByTdNodeId(nodeId, doc);

  const tableRow: HTMLElement | null = targetCell ? targetCell.parentElement : null;
  const tableColumn: number = targetCell ? targetCell.cellIndex + 1 : 0;
  let tableRowNumber = 0;

  if (tableRow && (tableRow as HTMLTableRowElement)?.rowIndex >= 0) {
    tableRowNumber = (tableRow as HTMLTableRowElement).rowIndex + 1;
  }

  const data = {
    tableNumber,
    tableColumn,
    tableRowNumber,
  };

  return data;
};
