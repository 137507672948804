import { CsvHeaderType } from '../../../types';

export const notesHeaders: CsvHeaderType = [
  { label: 'Table Number', key: 'tableNumber' },
  { label: 'Column', key: 'tableColumn' },
  { label: 'Row', key: 'tableRow' },
  { label: 'Value', key: 'value' },
  { label: 'DV', key: 'dvStatus' },
  { label: 'SuggestedChange', key: 'suggestedChange' },
  { label: 'Source Doc Number', key: 'sourceDocNumber' },
  { label: 'sourceTitle', key: 'sourceTitle' },
  { label: 'Source Version', key: 'sourceVersion' },
  { label: 'Notes', key: 'notes' },
];

export const headerEnum: Record<any, any> = {
  suggestedChange: 'Suggested Change',
  sourceTitle: 'Source Doc Title',
  sourceDocNumber: 'Source Doc Number',
  sourceVersion: 'Source Doc Version',
  value: 'Target Value',
};

export const mappingsHeaders: CsvHeaderType = [
  { label: 'Target Document No', key: 'targetNumber' },
  { label: 'Target Document Name', key: 'templateName' },
  { label: 'Target Value', key: 'targetValue' },
  { label: 'Authored By', key: 'creatorname' },
  { label: 'Target Value Location', key: 'targetLocation' },
  { label: 'Suggested Change ', key: 'suggestedChange' },
  { label: 'Source Doc Number', key: 'sourceDocNumber' },
  { label: 'Source Document', key: 'originalName' },
  { label: 'Source Doc Version', key: 'sourceDocVersion' },
  { label: 'Source Value Location', key: 'sourceLocation' },
  { label: 'Rejection Reason', key: 'notes' },
  { label: 'Reviewed By', key: 'statusUpdaterName' },
  { label: 'DV Status', key: 'status' },
];

export const dvFormHeaders: CsvHeaderType = [
  { label: 'Veeva Source Document', key: 'originalName' },
  { label: 'Version', key: 'version' },
  { label: 'Source Content (Table/Section/Figure)', key: 'sourceLocation' },
  { label: 'Target Content (Table/Section/Figure)', key: 'targetLocation' },
];

export const accuracyReportHeaders: CsvHeaderType = [
  { label: 'Target Node Id', key: 'targetNodeId' },
  { label: 'Target Node Value', key: 'targetValue' },
  { label: 'Mapper Type', key: 'mapperType' },
  { label: 'Suggestions Selected', key: 'selected' },
  { label: 'Summary', key: 'summary' },
  { label: 'Value', key: 'value' },
];

export const dataKeyEnum: Record<any, any> = {
  sourceVersion: 'sourceVersion',
  sourceTitle: 'sourceTitle',
  sourceDocNumber: 'sourceDocNumber',
};
