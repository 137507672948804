import { z } from 'zod';

export const authoringSchema = z.object({
  documentName: z.string().min(1),
  section: z.string({ message: 'Invalid or empty section' }).nullable().optional(),
  version: z.string().superRefine((value, ctx) => {
    // Should allow whole number versions
    if (parseFloat(value) % 1 === 0) {
      return;
    }
    // Validate the version format to not allow more than 3 digits before and 1 digit after the decimal point
    const regex = /^\d{1,3}\.\d$/;
    if (!regex.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Version should have up to 3 digits before and up to 1 digit after the decimal point (e.g., 1.0)',
      });
    }
  }),
});
