import { AIResponse } from '../../../types';

export const mergeDynamicSuggestions = (
  oldSuggestions: AIResponse | undefined,
  newSuggestions: AIResponse | undefined,
): AIResponse => {
  if (!oldSuggestions && !newSuggestions) return {};
  if (!oldSuggestions) return { ...newSuggestions };
  if (!newSuggestions) return { ...oldSuggestions };

  const mergedState: AIResponse = { ...oldSuggestions };

  Object.keys(newSuggestions).forEach((key) => {
    if (oldSuggestions[key]) {
      mergedState[key] = [...mergedState[key], ...newSuggestions[key]];
    } else {
      mergedState[key] = newSuggestions[key];
    }
  });

  return mergedState;
};
