// React-related imports
import { useContext } from 'react';

// Contexts and Hooks
import { Context } from '../../../routes/PrivateRoutes';
import { useCatalog, useMappingSessionAPI } from '../../../services/api';

// Types
import { AutowriteRequestData } from '../../../types';
import { IStartAuthoringProps } from './types';

// Others
import { cloneDeep } from 'lodash';
import { notifyError } from '../../../helpers/utils';
import { FieldValues } from 'react-hook-form';

export const useStartAuthoring = ({
  selectedRecords,
  sessionId,
  openNonInteractiveDocumentModal,
  templateType,
  closeAuthoringModal,
  nonInteractiveDocuments,
}: IStartAuthoringProps) => {
  const { autowriteMappingSession, manuallyAuthorMappingSession } = useMappingSessionAPI();
  const { mutateAsync: autowrite, isPending: isAutowritePending } = autowriteMappingSession;
  const { mutateAsync: manualAuthor, isPending: isManualAuthorPending } =
    manuallyAuthorMappingSession;

  const { getCatalogFileData } = useCatalog();
  const { mutateAsync: getCatalogData, isPending: isCatalogDataPending } = getCatalogFileData;

  const { setMultipleFile, setTemplateFile, setTemplateFileDisplay } = useContext(Context) as any;

  const isPending = isAutowritePending || isCatalogDataPending || isManualAuthorPending;

  const executeAutowriteRequest = (
    data: Omit<AutowriteRequestData, 'automatedDocId' | 'sourceIds'>,
  ) => {
    if (sessionId && selectedRecords.length) {
      autowrite({
        automatedDocId: sessionId,
        sourceIds: selectedRecords,
        ...data,
      });
      if (nonInteractiveDocuments.length) openNonInteractiveDocumentModal();
    } else {
      notifyError('Select required files');
    }
  };

  const executeManualAuthoringRequest = (
    data: Omit<AutowriteRequestData, 'automatedDocId' | 'sourceIds'>,
  ) => {
    if (selectedRecords.length && sessionId) {
      const catalogFileParams = {
        sourceFileIds: selectedRecords,
        targetFileId: sessionId,
      };
      getCatalogData(catalogFileParams).then((res) => {
        setTemplateFile(cloneDeep(res.data.targetFile));
        setTemplateFileDisplay(cloneDeep(res.data.targetFile));
        setMultipleFile(cloneDeep(res.data.sourceFile));
        manualAuthor({ ...catalogFileParams, ...data });
      });
    } else {
      notifyError('Please select valid files');
    }
  };

  const handleStartAuthoring = (data: FieldValues) => {
    const authoringData = {
      title: data.documentName,
      contentSpecifics: '',
      ctdSection: data.section,
      program: '',
      version: data.version,
      detailedNotes: '',
    };

    templateType === 'Automated'
      ? executeAutowriteRequest(authoringData)
      : executeManualAuthoringRequest(authoringData);

    closeAuthoringModal();
  };

  return {
    handleStartAuthoring,
    isPending,
  };
};
