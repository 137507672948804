import { useState } from 'react';
import { fetchAutowrittenDocuments } from '../services/apiCalls';

export const useCompletedDocuments = (props: { mode: string }) => {
  const [documentList, setDocumentList] = useState([]);
  const [isSessionLoaded, setIsSessionLoaded] = useState(false);

  const getAllUploadedDocs = async () => {
    const result = await fetchAutowrittenDocuments(props.mode, {
      page: 1,
      perPage: 10,
    });
    setIsSessionLoaded(true);
    result?.data && setDocumentList(result.data?.responseArray);
  };

  return {
    documentList,
    isSessionLoaded,
    setDocumentList,
    setIsSessionLoaded,
    getAllUploadedDocs,
  };
};
