import { FilterOptions } from '@gloabal-regulatory-writing-consulting/gxt-components/dist/components/slideOverFilters/SlideOverFilters';
import { capitalizeFirstLetter } from '../../../../../helpers/utils';
import { GroupedSubgroups, Subgroups } from '../../../../../services/api/types';

export const mapSubgroupsToOptions = (
  groupedSubgroups: {
    groupedSubgroups: { groupId: number; subgroups: { id: number; name: string }[] }[];
  },
  groups: { id: number; name: string }[],
  selectedIds: number[],
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>,
): FilterOptions[] => {
  const subgroupsArray = groupedSubgroups?.groupedSubgroups;

  if (!Array.isArray(subgroupsArray)) {
    return [];
  }

  return subgroupsArray.map((groupedSubgroup) => {
    const group = groups.find((g) => g.id === groupedSubgroup.groupId);
    const header = group ? group.name : `Group ${groupedSubgroup.groupId}`;

    const checkboxOptions = groupedSubgroup.subgroups.map((subgroup) => {
      const isChecked = selectedIds.includes(subgroup.id);
      return {
        label: subgroup.name,
        value: subgroup.id,
        name: header,
        checked: isChecked,
        onChange: () => {
          setSelectedIds((prevSelectedIds) => {
            const isCurrentlyChecked = prevSelectedIds.includes(subgroup.id);
            const newSelectedIds = isCurrentlyChecked
              ? prevSelectedIds.filter((id) => id !== subgroup.id)
              : [...prevSelectedIds, subgroup.id];
            return newSelectedIds;
          });
        },
      };
    });

    return {
      header,
      isAccordionOpen: true,
      isSearchAble: true,
      onChangeCallback: () => {},
      checkboxOptions,
    };
  });
};

export const mapSelectedGroupsToOptions = (
  selectedGroups: number[],
  groups: { id: number; name: string }[],
): { value: string; label: string }[] => {
  return selectedGroups.reduce(
    (acc, id) => {
      const group = groups.find((group) => group.id === id);
      if (group) {
        acc.push({ value: group.name, label: capitalizeFirstLetter(group.name) });
      }
      return acc;
    },
    [] as { value: string; label: string }[],
  );
};

export const mapSelectedSubGroupsToOptions = (
  subGroups: { groupedSubgroups: GroupedSubgroups[] },
  selectedSubGroups: number[],
): { value: string; label: string }[] => {
  const groupedSubgroups = subGroups?.groupedSubgroups;

  if (groupedSubgroups && Array.isArray(groupedSubgroups)) {
    const allSubgroups = groupedSubgroups.flatMap((groupedSubgroup) =>
      groupedSubgroup.subgroups.map((subgroup: Subgroups) => ({
        id: subgroup.id,
        name: subgroup.name,
      })),
    );
    return selectedSubGroups.reduce(
      (acc, id) => {
        const subgroup = allSubgroups.find((subgroup) => subgroup.id === id);
        if (subgroup) {
          acc.push({
            value: subgroup.name,
            label: capitalizeFirstLetter(subgroup.name),
          });
        }
        return acc;
      },
      [] as { value: string; label: string }[],
    );
  }
  return [] as { value: string; label: string }[];
};
