// React-related imports
import { useMemo, useState, useEffect } from 'react';

// React Table library
import { createColumnHelper } from '@tanstack/react-table';

// External libraries
import { useQuery } from '@tanstack/react-query';

// Types
import { PaginatedTableProps } from './PaginatedTable.types';

// Components
import { Table } from '../table/Table';
import SvgIcon from '../elements/SvgIcon';
import {
  Pagination,
  CheckboxInput,
  Dropdown,
} from '@gloabal-regulatory-writing-consulting/gxt-components';
import '@gloabal-regulatory-writing-consulting/gxt-components/dist/style.css';

// Utility library
import { twMerge } from 'tailwind-merge';
import { getDropdownOptions } from '../../helpers/utils';

const PaginatedTable = <T,>(props: PaginatedTableProps<T>) => {
  const {
    additionalColumns,
    paginatedFilteredData,
    getData,
    menuItems = [],
    handleSelectAll,
    onSelect,
    selectedData = [],
    handlePageChange = () => {},
    handlePerPageChange = () => {},
    queryKey = [],
    actionsColumn = false,
    clientSidePagination = false, // Default to false for server-side pagination
    clientSideData = [], // Default empty array for client-side data
    isCheckboxRequired = true,
    renderDropdown,
    label,
  } = props;

  const [totalItems, setTotalItems] = useState(0);

  const currentPage = paginatedFilteredData.pagination?.page || 1;
  const itemsPerPage = paginatedFilteredData.pagination?.perPage || 10;

  useEffect(() => {
    if (clientSidePagination && clientSideData) {
      setTotalItems(clientSideData.length);
    }
  }, [clientSidePagination, clientSideData]);

  const fetchData = async () => {
    try {
      const { data } = await getData();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return { data: [], total: 0 };
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: [...queryKey, currentPage, itemsPerPage, paginatedFilteredData],
    queryFn: fetchData,
    enabled: !clientSidePagination, // Disable query if clientSidePagination is true
  });

  useEffect(() => {
    if (!isLoading && data) {
      setTotalItems(data.total);
    }
  }, [isLoading, data]);

  const paginatedData = useMemo(() => {
    if (clientSidePagination) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      return clientSideData.slice(startIndex, startIndex + itemsPerPage);
    }
    return data?.data || [];
  }, [clientSideData, currentPage, itemsPerPage, clientSidePagination, data]);

  const records = useMemo(() => paginatedData, [paginatedData]);

  const allDocsChecked = useMemo(() => {
    if (records.length === 0) return false;
    return records.length === selectedData.length;
  }, [records, selectedData]);

  const columnHelper = createColumnHelper<T & Record<'id', string>>();

  const renderDropDownElement = ({ row }: { row: any }) => (
    <div className="space-x-2">
      <Dropdown
        options={getDropdownOptions(menuItems)}
        type="button"
        placeholder={<SvgIcon iconType="button-dots" />}
        position="center"
        renderOption={(option) => {
          if (!option) return null;

          const { title, onClick, className } = option.value;

          return (
            <span
              className={twMerge(`py-2 px-4 ${className}`)}
              onClick={() => onClick(row.original.id, row)}>
              {title}
            </span>
          );
        }}
        customStyles={{
          placeholder: {
            padding: '0.125rem 0.75rem',
          },
          item: {
            display: 'flex',
            justifyContent: 'center',
            padding: '0',
          },
        }}
      />
    </div>
  );

  const columns = useMemo(() => {
    const baseColumns: any = [];

    if (isCheckboxRequired) {
      baseColumns.push(
        columnHelper.accessor('id' as any, {
          header: () => (
            <CheckboxInput
              $inputSize="xs"
              className="bg-system-50"
              checked={allDocsChecked}
              indeterminate={selectedData.length > 0 && !allDocsChecked}
              onChange={() => handleSelectAll && handleSelectAll(allDocsChecked, records)}
            />
          ),
          cell: ({ row }) => (
            <CheckboxInput
              $inputSize="xs"
              value={row.original.id}
              checked={selectedData.includes(String(row.original.id))}
              onChange={(event) => onSelect && onSelect(event, row.original)}
            />
          ),
        }),
      );
    }

    baseColumns.push(...additionalColumns);

    if (actionsColumn) {
      baseColumns.push(
        columnHelper.accessor('id' as any, {
          header: () => 'Actions',
          cell: renderDropdown || renderDropDownElement,
          size: 58,
          maxSize: 58,
        }),
      );
    }

    return baseColumns;
  }, [
    columnHelper,
    additionalColumns,
    actionsColumn,
    allDocsChecked,
    handleSelectAll,
    records,
    selectedData,
    onSelect,
    menuItems,
    isCheckboxRequired,
  ]);

  return (
    <div className="flex flex-col items-start flex-grow self-stretch">
      <div className="w-full border rounded-t flex-grow">
        <Table
          data={records}
          columns={columns}
          isDataLoading={isLoading && !clientSidePagination}
        />
      </div>
      {totalItems > 0 && (
        <Pagination
          handlePerPageChange={(newPerPage: number) => {
            handlePerPageChange(newPerPage);
            handlePageChange(1);
          }}
          currentPage={currentPage - 1}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          onPageChange={(page: number) => handlePageChange(page + 1)}
          label={label}
        />
      )}
    </div>
  );
};

export default PaginatedTable;
