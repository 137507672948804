// React-related imports
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

// Component imports
import { Input, TextArea } from '@gloabal-regulatory-writing-consulting/gxt-components';

// Util imports
import { zodResolver } from '@hookform/resolvers/zod';
import { rejectionFormSchema } from '../../utils/validationSchema';

// Types
import { RejectionFormValues } from '../../../../types';
import { RejectionFormProps } from './RejectionForm.types';
import { InputError } from '../../../../../../components/inputError';

const RejectionForm = forwardRef(({ handleSubmitData, defaultValues }: RejectionFormProps, ref) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RejectionFormValues>({
    defaultValues,
    resolver: zodResolver(rejectionFormSchema),
  });

  const onSubmit: SubmitHandler<RejectionFormValues> = (data: RejectionFormValues) => {
    handleSubmitData(data);
  };

  const resetForm = () => {
    reset(defaultValues);
  };

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit),
    resetForm: resetForm,
  }));

  useEffect(() => {
    resetForm();
  }, [defaultValues, reset]);

  return (
    <form className="flex flex-col gap-4 self-stretch">
      <div className="flex flex-col items-start gap-8 self-stretch">
        <div className="flex flex-col items-start gap-5 self-stretch">
          <div className="flex flex-col items-start gap-6 self-stretch">
            <p className="text-[20px] text-primary-200">Target</p>

            <div className="flex items-start gap-5 self-stretch">
              <Controller
                name={'targetTableNumber'}
                control={control}
                render={({ field }) => (
                  <div className="w-full">
                    <Input
                      {...field}
                      label="Table Number"
                      id={`targetTableNumber`}
                      placeholder="Table Number"
                      inputType="number"
                      isFilled={!!field.value}
                      customStyles={{
                        input: { height: '2.625rem' },
                      }}
                      error={!!errors['targetTableNumber']?.message}
                      helpText={<InputError errors={errors} field={`targetTableNumber`} />}
                    />
                  </div>
                )}
              />
              <Controller
                name={'targetTableColumn'}
                control={control}
                render={({ field }) => (
                  <div className="w-full">
                    <Input
                      {...field}
                      id={'targetTableColumn'}
                      label="Table Column"
                      placeholder="Table Column"
                      inputType="number"
                      isFilled={!!field.value}
                      customStyles={{
                        input: { height: '2.625rem' },
                      }}
                      error={!!errors['targetTableColumn']?.message}
                      helpText={<InputError errors={errors} field={'targetTableColumn'} />}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <Controller
            name={'targetTableRow'}
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Input
                  {...field}
                  id={'targetTableRow'}
                  label="Table Row"
                  placeholder="Table Row"
                  inputType="number"
                  isFilled={!!field.value}
                  customStyles={{
                    input: { height: '2.625rem' },
                  }}
                  error={!!errors['targetTableRow']?.message}
                  helpText={<InputError errors={errors} field={'targetTableRow'} />}
                />
              </div>
            )}
          />
          <Controller
            name={'targetValue'}
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Input
                  {...field}
                  id={'targetValue'}
                  label="Value*"
                  placeholder="Value"
                  inputType="text"
                  isFilled={!!field.value}
                  customStyles={{
                    input: { height: '2.625rem' },
                  }}
                  error={!!errors['targetValue']?.message}
                  helpText={<InputError errors={errors} field={'targetValue'} />}
                />
              </div>
            )}
          />
        </div>
        <div className="flex flex-col items-start gap-4 self-stretch">
          <p className="text-[20px] text-primary-200">Source</p>

          <Controller
            name={'suggestedChange'}
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Input
                  {...field}
                  id={'suggestedChange'}
                  label="Suggested Change*"
                  placeholder="Target value here"
                  inputType="text"
                  isFilled={!!field.value}
                  customStyles={{
                    input: { height: '2.625rem' },
                  }}
                  error={!!errors['suggestedChange']?.message}
                  helpText={<InputError errors={errors} field={'suggestedChange'} />}
                />
              </div>
            )}
          />

          <div className="flex items-start gap-4 self-stretch">
            <Controller
              name={'documentNumber'}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <Input
                    {...field}
                    label="Document Number"
                    id={`documentNumber`}
                    placeholder="Document Number"
                    inputType="number"
                    isFilled={!!field.value}
                    customStyles={{
                      input: { height: '2.625rem' },
                    }}
                    error={!!errors['documentNumber']?.message}
                    helpText={<InputError errors={errors} field={`documentNumber`} />}
                  />
                </div>
              )}
            />
            <Controller
              name={'documentVersion'}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <Input
                    {...field}
                    id={'documentVersion'}
                    label="Document Version"
                    placeholder="Document Version"
                    inputType="number"
                    isFilled={!!field.value}
                    customStyles={{
                      input: { height: '2.625rem' },
                    }}
                    error={!!errors['documentVersion']?.message}
                    helpText={<InputError errors={errors} field={'documentVersion'} />}
                  />
                </div>
              )}
            />
          </div>

          <Controller
            name={'documentTitle'}
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Input
                  {...field}
                  id={'documentTitle'}
                  label="Document Title"
                  placeholder="Value"
                  inputType="text"
                  isFilled={!!field.value}
                  customStyles={{
                    input: { height: '2.625rem', width: '100%' },
                  }}
                  error={!!errors['documentTitle']?.message}
                  helpText={<InputError errors={errors} field={'documentTitle'} />}
                />
              </div>
            )}
          />

          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <TextArea
                  {...field}
                  id="note"
                  heading="Notes"
                  placeholder="Add a note"
                  className="!w-full"
                  customStyles={{
                    input: { width: '100%' },
                  }}
                />
              </div>
            )}
          />
        </div>
        <p className="text-primary-200 text-[12px] font-[400]">
          Items Marked with a * are required
        </p>
      </div>
    </form>
  );
});

RejectionForm.displayName = 'RejectionForm';

export default RejectionForm;
