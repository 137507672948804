// React-related imports
import { FC, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { AiOutlineSearch } from 'react-icons/ai';

// Types
import { ICategory, IQuickFillModal } from '../../types';

// Components
import Button from '../elements/Button';
import ExpandableSection from '../ExpandableSection';
import { Search } from '../Search';

// Other dependencies
import { twMerge } from 'tailwind-merge';
import { getBackgroundColorClass, QuickFillModalCustomStyles } from '../../constants';
import ButtonTitle from '../elements/ButtonTitle';

const QuickFillModal: FC<IQuickFillModal> = ({
  closeQuickFillModal,
  quickFillModalOpen,
  gvCategories,
  selectedOption,
  clearSelection,
  handleOptionChange,
  handleApplyFilter,
  isLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCategories = useMemo(
    () =>
      searchTerm
        ? gvCategories.reduce((accCategories, category) => {
            const filteredCategoryOptions = category.options.filter((option) =>
              option.toLowerCase().includes(searchTerm.toLowerCase()),
            );
            if (filteredCategoryOptions.length) {
              accCategories.push({
                ...category,
                options: filteredCategoryOptions,
              });
            }
            return accCategories;
          }, [] as ICategory[])
        : gvCategories,
    [searchTerm, gvCategories],
  );

  return (
    <Modal
      isOpen={quickFillModalOpen}
      onRequestClose={closeQuickFillModal}
      style={QuickFillModalCustomStyles}
      ariaHideApp={false}>
      <div className="flex flex-col h-full justify-between">
        <div className="pl-4 py-6 w-full bg-title_color">
          <h1 className="font-medium text-xl text-white">Quick Fill</h1>
        </div>
        <div className="flex mt-3 bg-gray-50 mx-2 border border-gray-300 rounded-lg">
          <div className="py-2.5 pl-2">
            <AiOutlineSearch className="text-xl text-gray-500"></AiOutlineSearch>
          </div>
          <Search
            id="gsearch"
            name="gsearch"
            onChangeCallback={setSearchTerm}
            className="border-none focus:outline-none"
          />
        </div>
        <div className="flex-grow overflow-y-auto">
          {filteredCategories.map((category, index) => (
            <ExpandableSection
              key={index}
              title={category.title}
              options={category.options}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              defaultOpen={!!searchTerm}
            />
          ))}
        </div>
        <div>
          <hr />
          <div className="flex justify-between px-4 py-3 text-title_color">
            <Button
              onClick={clearSelection}
              data-testid="quick-fill-modal-clear-button"
              title="Clear Selection"
              disabled={isLoading}
              className={twMerge(
                'text-white border font-bold border-gray-300 px-4 py-2 rounded-md hover:bg-gray-100',
                getBackgroundColorClass(!isLoading),
              )}
            />
            <div>
              <Button
                onClick={() => {
                  selectedOption && handleApplyFilter(selectedOption);
                }}
                title={<ButtonTitle isLoading={isLoading} loadingText="Saving..." title="Fill" />}
                data-testid="quick-fill-modal-fill-button"
                className={twMerge(
                  'text-white border font-bold border-gray-300 px-4 py-2 rounded-md hover:bg-gray-200 mr-2',
                  getBackgroundColorClass(!!selectedOption && !isLoading),
                )}
                disabled={!selectedOption || isLoading}
              />
              <Button
                onClick={closeQuickFillModal}
                title="Close"
                data-testid="quick-fill-modal-close-button"
                disabled={isLoading}
                className={twMerge(
                  'text-white border font-bold border-gray-300 px-4 py-2 rounded-md hover:bg-gray-100',
                  getBackgroundColorClass(!isLoading),
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuickFillModal;
