import { useEffect, useState } from 'react';
import { formatDate } from '../helpers/utils';

interface ILastSaved {
  timestamp: Date;
}

const LastSaved = ({ timestamp }: ILastSaved) => {
  const [color, setColor] = useState('text-green-500');

  useEffect(() => {
    setColor('text-green-500');
    const timer = setTimeout(() => {
      setColor('text-neutral-400');
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [timestamp]);

  return (
    <p className="font-bold text-neutral-400">
      Last Saved: <span className={`font-normal ${color}`}>{formatDate(timestamp)}</span>
    </p>
  );
};

export default LastSaved;
