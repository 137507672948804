import { useState } from 'react';
import { SearchResult } from '../types';

export default function useSearchResult() {
  const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);

  return {
    searchResult,
    isSearched,
    setSearchResult,
    setIsSearched,
  };
}
