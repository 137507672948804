import { CreateUserFormValues } from '../CreateUserForm.types';

type inputListType = {
  label: string;
  type: string;
  name: keyof CreateUserFormValues;
  placeholder?: string;
};

export const inputList: inputListType[] = [
  {
    label: 'First Name',
    type: 'text',
    name: 'firstName',
    placeholder: 'First Name',
  },
  {
    label: 'Last Name',
    type: 'text',
    name: 'lastName',
    placeholder: 'Last Name',
  },
  {
    label: 'Email',
    type: 'email',
    name: 'email',
    placeholder: 'Email',
  },
];

export const UserDefaultValues: CreateUserFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
};
