// React related imports
import { useContext, useMemo } from 'react';

// Context and hooks
import { GeneralizationContext } from '../../../../contexts/GeneralizationContext';

// Components
import SearchableDropdown from '../../../../components/elements/SearchableDropdown';
import Badges from '../../../../components/Badges';
import SvgIcon from '../../../../components/elements/SvgIcon';

// Others
import { Button, Switch } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { AuthoringActionsProps, BadgeType } from './types';
import { TargetSaveState } from '../../../authoring/hooks/types';

const DataVerificationActions = ({
  suggestionOptions,
  searchOptions,
  isFullScreen,
  handleFullScreen,
  handleSearchTerm,
  customTextOnSelect,
  handleAiDropDownChange,
  targetChangesStatus,
}: AuthoringActionsProps) => {
  const { setSelectionSearch, selectionSearch } = useContext(GeneralizationContext);

  const badgesProps = useMemo(() => {
    const badges: Record<TargetSaveState, BadgeType> = {
      unchanged: {
        value: 'Nothing to save',
        className: 'bg-neutral-100 text-neutral-300',
        badgeIconType: 'check-mark',
      },
      unsaved: {
        value: 'Unsaved Changes',
        className: 'bg-warning-50 text-warning-200',
        badgeIconType: null,
      },
      saved: {
        value: 'Saved',
        className: 'bg-positive-50 text-positive-200',
        badgeIconType: 'check-mark',
      },
      autoSaved: {
        value: 'Autosaved',
        className: 'bg-positive-50 text-positive-200',
        badgeIconType: 'check-mark',
      },
    };
    return badges[targetChangesStatus];
  }, [targetChangesStatus]);

  return (
    <div className="mb-5 flex justify-between items-center space-x-6">
      <SearchableDropdown
        options={
          suggestionOptions?.length > 0 ? [...suggestionOptions, ...searchOptions] : searchOptions
        }
        onOptionSelected={handleAiDropDownChange}
        placeholder={suggestionOptions?.length ? 'Suggestions Available' : 'Search Suggestions...'}
        className="flex-1"
        customTextOnSelect={customTextOnSelect}
        onSearch={handleSearchTerm}
      />
      <div className="flex items-center space-x-6">
        <div
          data-testid="global-search-container"
          className="flex items-center space-x-3 content-box-sizing">
          <Switch
            checked={selectionSearch}
            onChange={() => setSelectionSearch((prev: boolean) => !prev)}
            offColor="var(--system-50, #fff)"
            onColor="var(--primary-200, #177ba6)"
          />
          <h5 className="text-neutral-400 font-normal" data-testid="global-search-text">
            Global <br /> Search
          </h5>
        </div>
        <div className="flex items-center space-x-3">
          <Badges
            values={[badgesProps.value]}
            className={`${badgesProps.className} font-normal`}
            badgeIcon={
              badgesProps.badgeIconType && <SvgIcon iconType={badgesProps.badgeIconType} />
            }
          />
          <Button variant="primary" circular={true} onClick={handleFullScreen}>
            <SvgIcon data-testid="zoom-in-icon" iconType={isFullScreen ? 'zoom-out' : 'zoom-in'} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DataVerificationActions;
